import {Modal, ModalBody} from "reactstrap";
import cross_icon from "../../assets/images/cross-icon.png";
import React, {useEffect, useState} from "react";
import mobile_icon from '../../assets/images/mobile-icon.svg';
import mail_icon from '../../assets/images/mail-icon.svg';
import user_icon from '../../assets/images/user-icon.svg';
import {mainPostServices} from "../../shared/service";
import {GET_CAREGIVERS_DATA} from "../../shared/constant";
import {formatPhoneNumber, generateAvatar} from "../../utils";
import Loader from "../Loader";

const CaregiversList = ({caregiverData,caregiversModal,setCaregiversModal}) =>{
    const [loader,setLoader]=useState(false)
    const [caregiver,setCaregiver]=useState(false)
    useEffect(()=>{
        if(caregiversModal){
            getCaregiverData()
        }
    },[caregiverData,caregiversModal])

    const getCaregiverData = async ()=>{
        setLoader(true)
        const { data, success } = await mainPostServices(GET_CAREGIVERS_DATA, {id:caregiverData?.id});
        if (success) {
            setLoader(false)
            setCaregiver(data)
        }else {
            setLoader(false)
        }
    }

    return(
        <div>
            <Modal isOpen={caregiversModal} toggle={() => setCaregiversModal(false)} className='main_comman_modal caregiversPop'>
                <div className='close_modal'>
                    <img src={cross_icon} alt='cross'  onClick={()=>setCaregiversModal(false)}/>
                </div>
                <ModalBody>
                    <div className="caregivers-main_block">
                        <h5>Caregivers of {caregiverData?.PatientName}</h5>
                        {
                            loader ?
                                <div className="caregivers-sub_block">
                                    <Loader className="data_load" />
                                </div>
                                :
                                <div className="caregivers-sub_block">
                                    {caregiver?.length > 0 ?
                                        caregiver?.map((car,i)=>{
                                            return(
                                                car?.isPrimary ?
                                                    <div className={`caregivers-detail_block selected_caregiver`}>
                                                        <div className="profile_pic"><img src={car?.profile ? car?.profile : generateAvatar(car?.first_name + " " + car?.last_name)} alt=''/></div>
                                                        <div className="caregivers_detail">
                                                            <h6>{car?.first_name + " " + car?.last_name}</h6>
                                                            {
                                                                car?.phone &&
                                                                <span className="mobile_num comman_detail">
                                                                    <img src={mobile_icon} alt={'mobile_icon'}/>{formatPhoneNumber(car?.phone)}
                                                                </span>
                                                            }
                                                            <span className="mail comman_detail"><img src={mail_icon} alt={'mail_icon'}/>{car?.email}</span>
                                                            <span className="user_detail comman_detail"><img src={user_icon} alt={'user_icon'}/>{car?.relationship}</span>
                                                        </div>
                                                        <div className="checkbox_block">
                                                            <span className="checkbox_clone"/>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className={`caregivers-detail_block`}>
                                                        <div className="profile_pic"><img src={car?.profile ? car?.profile : generateAvatar(car?.first_name + " " + car?.last_name)} alt=''/></div>
                                                        <div className="caregivers_detail">
                                                            <h6 className={'text_capitalize'}>{car?.first_name + " " + car?.last_name}</h6>
                                                            {
                                                                car?.phone &&
                                                                <span className="mobile_num comman_detail">
                                                                    <img src={mobile_icon} alt={'mobile_icon'}/>{formatPhoneNumber(car?.phone)}
                                                                </span>
                                                            }
                                                            <span className="mail comman_detail">
                                                                <img src={mail_icon} alt={'mail_icon'}/>{car?.email}
                                                            </span>
                                                            <span className="user_detail comman_detail">
                                                                <img src={user_icon} alt={'user_icon'}/>{car?.relationship}
                                                            </span>
                                                        </div>
                                                        <div className="checkbox_block">
                                                            <span className="checkbox_clone"/>
                                                        </div>
                                                    </div>
                                            )
                                        })
                                        :
                                        <div className={'text_center'}>No Data Found</div>
                                    }
                                </div>
                        }
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default CaregiversList

// <div className={`caregivers-detail_block selected_caregiver`}>
//                             <div className="profile_pic"><img src={profile_pic} alt='' /></div>
//                             <div className="caregivers_detail">
//                                 <h6>Linda Davenport</h6>
//                                 <span className="mobile_num comman_detail"><img src={mobile_icon} />(312) 213-5433</span>
//                                 <span className="mail comman_detail"><img src={mail_icon} />linda.davenport@gmail.com</span>
//                                 <span className="user_detail comman_detail"><img src={user_icon} />Daughter</span>
//                             </div>
//                             <div className="checkbox_block">
//                                 <span class="checkbox_clone"></span>
//                             </div>
//                         </div>
//                         <div className={`caregivers-detail_block`}>
//                             <div className="profile_pic"><img src={profile_pic} alt='' /></div>
//                             <div className="caregivers_detail">
//                                 <h6>Linda Davenport</h6>
//                                 <span className="mobile_num comman_detail"><img src={mobile_icon} />(312) 213-5433</span>
//                                 <span className="mail comman_detail"><img src={mail_icon} />linda.davenport@gmail.com</span>
//                                 <span className="user_detail comman_detail"><img src={user_icon} />Daughter</span>
//                             </div>
//                             <div className="checkbox_block">
//                                 <span class="checkbox_clone"></span>
//                             </div>
//                         </div>
//                         <div className={`caregivers-detail_block selected_caregiver`}>
//                             <div className="profile_pic"><img src={profile_pic} alt='' /></div>
//                             <div className="caregivers_detail">
//                                 <h6>Linda Davenport</h6>
//                                 <span className="mobile_num comman_detail"><img src={mobile_icon} />(312) 213-5433</span>
//                                 <span className="mail comman_detail"><img src={mail_icon} />linda.davenport@gmail.com</span>
//                                 <span className="user_detail comman_detail"><img src={user_icon} />Daughter</span>
//                             </div>
//                             <div className="checkbox_block">
//                                 <span class="checkbox_clone"></span>
//                             </div>
//                         </div>
//                         <div className={`caregivers-detail_block selected_caregiver`}>
//                             <div className="profile_pic"><img src={profile_pic} alt=''/></div>
//                             <div className="caregivers_detail">
//                                 <h6>Linda Davenport</h6>
//                                 <span className="mobile_num comman_detail"><img src={mobile_icon}/>(312) 213-5433</span>
//                                 <span className="mail comman_detail"><img src={mail_icon}/>linda.davenport@gmail.com</span>
//                                 <span className="user_detail comman_detail"><img src={user_icon}/>Daughter</span>
//                             </div>
//                             <div className="checkbox_block">
//                                 <span className="checkbox_clone"></span>
//                             </div>
//                         </div>