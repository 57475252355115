import React, { useRef, useState } from 'react';
import avatar from "../../assets/images/avatar.png";
import icon_plus from "../../assets/images/icon_plus.svg";
import { Modal, ModalBody } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import cross_icon from "../../assets/images/cross-icon.png";
import { formatPhoneNumber } from '../../shared/function';
import { mainPostServices } from '../../shared/service';
import { ADD_PATIENT_URL, GET_ALL_PATIENTS_URL, GET_PATIENTS_URL } from '../../shared/constant';
import Sweetalert from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { GET_PATIENTS } from '../../store/action';
import Loader from '../Loader';

const AddPatient = ({ patientModal, handlePatientsModal }) => {

    let url;
    const dispatch = useDispatch()
    const simpleValidator = useRef(new SimpleReactValidator())
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const allPatients = useSelector(state => state.patientsReducer.allPatients)
    const filters = useSelector(state => state.patientsReducer.filters)
    url = filters.status === 'all' ? GET_ALL_PATIENTS_URL : GET_PATIENTS_URL
    const [phoneTypeError,setPhoneTypeError]=useState(false)


    const [patientState, setPatientState] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        phone_type: ''
    })
    const [image, setImage] = useState('')
    const [formDataImage, setFormDataImage] = useState('')
    const [btnLoading, setbtnLoading] = useState(false)


    const getAllPatients = async () => {
        const req = {
            page: allPatients.page,
            limit: allPatients.limit
        }
        const { data, success } = await mainPostServices(url, req);
        if (success) {
            dispatch({ type: GET_PATIENTS, payload: data })
        }
    }


    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
                setImage(fileReader.result)
            };
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    };

    const handleProfileChange = (e) => {
        setFormDataImage(e.target.files[0])
        convertBase64(e.target.files[0])
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        if (name === 'phone') {
            setPatientState({
                ...patientState,
                [name]: formatPhoneNumber(value)
            })
        }
        else {
            setPatientState({
                ...patientState,
                [name]: name === 'email' ? value.trim() : value
            })
        }
        forceUpdate()
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        if(patientState.phone && !patientState?.phone_type){
            setPhoneTypeError(true)
        }else{
            setPhoneTypeError(false)
            if (simpleValidator.current.allValid()) {
                savePatient(patientState)
            } else {
                simpleValidator.current.showMessages();
                forceUpdate()
            }
        }

    }

    const savePatient = async (state) => {
            setbtnLoading(true)
            // document.getElementById("saveBtn").classList.add('disabled')
            if (state.phone) Object.assign(state, { phone: state?.phone?.replace(/[ ()-]/g, "") })
            if (state.phone_type) Object.assign(state, { phone_type: state?.phone_type })
            let formData = new FormData();
            Object.keys(state).forEach(key => {
                formData.append(`patient[${key}]`, state[key])
            })
            if (formDataImage) {
                formData.append("image", formDataImage)
            }
            const { message, success } = await mainPostServices(ADD_PATIENT_URL, formData);
            if (success) {
                setbtnLoading(false)
                document.getElementById("saveBtn").classList.remove('disabled')
                resetForm()
                getAllPatients()
                Sweetalert.fire({ title: 'Success', text: message })
            } else {
                setbtnLoading(false)
                Sweetalert.fire({title: 'Info', text: message })
            }

    }

    const resetForm = () => {
        handlePatientsModal(false)
        setPatientState({
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            phone_type: ''
        })
        setImage('')
        simpleValidator.current.hideMessages()
        // getAllPatients()
    }

    const clearFormState = () => {
        setPatientState({
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            phone_type: ''
        })
        setImage('')
        simpleValidator.current.hideMessages()
    }

    return (
        <Modal isOpen={patientModal} toggle={() => handlePatientsModal(false)} className='main_comman_modal addPatientModal'>
            <div className='close_modal' onClick={resetForm}>
                <img src={cross_icon} alt='cross' />
            </div>
            <ModalBody>
                <div className="custom_modal">
                    <h3 className="form_heading text_center">{"Add a Patient"}</h3>
                    <form>
                        <div className="form authorized_form">
                            <div className="form_group authorized_profile">
                                <div className="user_profile">
                                    <div className="user_profile_pic">
                                        <img src={image ? image : avatar} alt="" />
                                        <span className='addnew'>
                                            <img src={icon_plus} alt="" />
                                            <input type="file" name="image" onChange={handleProfileChange} />
                                        </span>
                                    </div>
                                    <label htmlFor="">Profile Photo</label>
                                </div>
                            </div>
                            <div className='authorized_form_model'>
                                <div className="form_row">
                                    <div className="form_group col-6">
                                        <label htmlFor="">First Name</label>
                                        <span className={`fill ${simpleValidator.current.check(patientState.first_name, 'required|alpha') ? "valid" : "invalid"}`}>
                                            <input
                                                type="text"
                                                name="first_name"
                                                className="form_control"
                                                onChange={handleInputChange}
                                                value={patientState.first_name}
                                            />
                                        </span>
                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                            {simpleValidator.current.message('first_name', patientState.first_name, 'required|alpha')}
                                        </div>
                                    </div>
                                    <div className="form_group col-6">
                                        <label htmlFor="">Last Name</label>
                                        <span className={`fill ${simpleValidator.current.check(patientState.last_name, 'required|alpha') ? "valid" : "invalid"}`}>
                                            <input
                                                type="text"
                                                className="form_control"
                                                name="last_name"
                                                onChange={handleInputChange}
                                                value={patientState.last_name}
                                            />
                                        </span>
                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                            {simpleValidator.current.message('last_name', patientState.last_name, 'required|alpha')}
                                        </div>
                                    </div>
                                </div>
                                <div className="form_group">
                                    <label htmlFor="">Email</label>
                                    <span className={`fill ${simpleValidator.current.check(patientState.email, 'required|email') ? "valid" : "invalid"}`}>
                                        <input
                                            type="text"
                                            name="email" id=""
                                            className="form_control"
                                            onChange={handleInputChange}
                                            value={patientState.email}
                                        />
                                    </span>
                                    <div className="invalid-feedback pl-4 d-block errorMsg">
                                        {simpleValidator.current.message('email', patientState.email, 'required|email')}
                                    </div>
                                </div>
                                <div className="form_row">
                                    <div className="form_group col-6">
                                        <label htmlFor="">Contact Phone</label>
                                        <input
                                            type="tel"
                                            inputMode="numeric"
                                            maxLength={10}
                                            className={`form_control`}
                                            placeholder='(222) 222-2222'
                                            name="phone"
                                            onChange={handleInputChange}
                                            value={formatPhoneNumber(patientState?.phone)}
                                        />
                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                            {simpleValidator.current.message('phone', patientState.phone, 'min:10',)}
                                        </div>
                                    </div>

                                    <div className="form_group col-6">
                                        <label htmlFor="">Phone Type</label>
                                        <select
                                            name="phone_type"
                                            className={`form_control`}
                                            onChange={handleInputChange}
                                            value={patientState.phone_type}
                                        >
                                            <option value={''}  >Select from below</option>
                                            <option value={'Mobile'}  >Mobile</option>
                                            <option value={'Work'}  >Work</option>
                                        </select>
                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                            {
                                                phoneTypeError&&
                                                    <p>Phone Type is Required</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="form_group d_flex justify_content_center form_action mt_5">
                                    <button type={'reset'} className='btn btn_default' onClick={clearFormState}>Clear</button>
                                    <button type={'button'} id="saveBtn" className='btn btn_primary ml_2' onClick={handleSubmit}>
                                        {btnLoading ?
                                            <Loader color="primary" size="sm" />
                                            :
                                            "Submit"
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default AddPatient;
