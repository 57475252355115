import React, { useRef, useState } from 'react';
import cross_icon from "../../assets/images/cross-icon.png";
import SimpleReactValidator from 'simple-react-validator';
import { mainPostServices } from '../../shared/service';
import { FORGET_PASSWORD_URL } from '../../shared/constant';
import Sweetalert from "sweetalert2";
import Loader from '../Loader';

const ForgetPasswordComponent = ({ isForget, setIsForget }) => {

  const simpleValidator = useRef(new SimpleReactValidator())
  const [, updateState] = useState();
  const [btnLoading, setBtnLoading] = useState(false)
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [email, setEmail] = useState('')

  const handleInputChange = (e) => {
    const { value } = e.target
    setEmail(value.trim())
  }

  const doForget = async (req) => {
    const { message, success } = await mainPostServices(FORGET_PASSWORD_URL, req);
    if (success) {
      setIsForget(!isForget)
      Sweetalert.fire({
        title: 'Success',
        text: message
      })
      setBtnLoading(false)
    } else {
      Sweetalert.fire({
        title: 'Error',
        text: message
      })
      setBtnLoading(false)
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      doForget({ email })
      setBtnLoading(true)
    } else {
      simpleValidator.current.showMessages();
      forceUpdate()
    }
  }

  const clearForm = (e) => {
    e.preventDefault();
    setEmail('')
    simpleValidator.current.hideMessages();
    forceUpdate()
  }

  const wantsToCancel = () => {
    setIsForget(!isForget)
    setEmail('')
    simpleValidator.current.hideMessages();
    forceUpdate()
  }

  return (
    isForget &&
    <div className='modal'>
      <div className=' forgot_psw_modal main_comman_modal'>
        <div className='close_modal'>
          <img src={cross_icon} alt='cross' onClick={wantsToCancel} />
        </div>
        <div class="main_delete_modal ">
          <h4>Forgot Password</h4>
          <form onSubmit={handleSubmit} onReset={clearForm}>
            <div className="form_group">
              <div><label>Email</label></div>
              <input
                type="text"
                placeholder="Email"
                className="form_control"
                name="email"
                autoComplete="off"
                onChange={handleInputChange}
                value={email}
              />
              <div className="invalid-feedback pl-4 d-block errorMsg">
                {simpleValidator.current.message('email', email, 'required|email')}
              </div>
            </div>
            <div className="d_flex_center mt_4 mb_4 form_action delete_modal_btn">
              <button type="reset" className="btn btn_default">Clear</button>
              <button className="btn btn_primary ml_2" type="submit">
                {btnLoading ?
                  <Loader color="primary" size="sm" />
                  :
                  "Submit"
                }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    //   <div className='modal'>
    // <div className='main_delete_modal forgot_psw_modal main_comman_modal'>
    // <h4>Forgot Password</h4>
    // <div className='delete_content'>
    // <form onSubmit={handleSubmit} onReset={clearForm}>
    //             <div className="form_group">
    //               <div><label>Email</label></div>
    //               <input
    //                 type="text"
    //                 placeholder="Email"
    //                 className="form_control"
    //                 name="email"
    //                 autoComplete="off"
    //                 onChange={handleInputChange}
    //                 value={email}
    //               />
    //               <div className="invalid-feedback pl-4 d-block errorMsg">
    //                 {simpleValidator.current.message('email', email, 'required|email')}
    //               </div>
    //             </div>
    //             <div className="d_flex_center mt_4 mb_4 form_action delete_modal_btn">
    //               <button type="reset" className="btn btn_default">Clear</button>
    //               {/* <button type="submit" className="btn btn_primary ml_2" >Submit</button> */}
    //               <button className="btn_primary ml_2" type="submit">
    //                         {btnLoading ?
    //                                 <Loader color="primary" size="sm" />
    //                                 :
    //                                 "Submit"
    //                         }
    //               </button>
    //             </div>
    // </form>
    // </div>

    // </div>
    // </div>

  )

}

export default ForgetPasswordComponent;
