import React, { Fragment, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../../../assets/images/new_hp_logo.svg";
import icone_hide_pass from "../../../assets/images/eye_hide.svg";
import icon_open_password from "../../../assets/images/eye_show.svg";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "../../common/Footer";
import SimpleReactValidator from "simple-react-validator";
import { mainPostServices } from "../../../shared/service";
import { SIGNUP_API_URL } from "../../../shared/constant";
import Sweetalert from "sweetalert2";
import AuthenticatePop from "../AuthenticatePop";
import Loader from "../../Loader";
import { useDispatch } from "react-redux";
let CryptoJS = require("crypto-js");

const WebSignup = () => {
    const { search } = useLocation();
    const history = useHistory();
    const [registrationCode,setRegistrationCode]=useState(false)
    const dispatch = useDispatch()
    const simpleValidator = useRef(new SimpleReactValidator({
        validators: {
            password: {
                message: "Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit.",
                // eslint-disable-next-line
                rule: (val, params, validator) => validator.helpers.testRegex(val.trim(), /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/), required: true, // optional
            }
        },
    }))
    const [, updateState] = useState();
    const [btnLoading, setBtnLoading] = useState(false)
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [viewPassWord, setViewPassWord] = useState(false)
    const [ViewConPassWord, setViewConPassWord] = useState(false)
    const [authVerifyModal, setAuthVerifyModal] = useState(false);
    const [counterTime, setCounterTime] = useState(0)

    let decryptedData;
    let decrypted = CryptoJS.AES.decrypt(search?.substring(1)?.toString(), process.env.REACT_APP_SECRET_KEY);
    if (search) {
        decryptedData = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    } else {
        history.push("/")
    }
    const [registrationState, setRegistrationState] = useState({
        institution_registration_code: '',
        email: decryptedData?.email,
        password: '',
        confirmPassword: ""
    })

    const togglePassWordHideShow = (pwdShow) => {
        setViewPassWord(pwdShow)
    }
    const handleLoginInputChange = (e) => {
        setRegistrationCode(false)
        const { name, value } = e.target
        setRegistrationState({
            ...registrationState,
            [name]: value
        })
    }

    const toggleConPassWordHideShow = (pwdShow) => {
        setViewConPassWord(pwdShow)
    }
    const signUpHandler = (e) => {
        e.preventDefault()
        if (simpleValidator.current.allValid()) {
            SignUpApiHandler(registrationState)
            setBtnLoading(true)
        } else {
            simpleValidator.current.showMessages();
            forceUpdate()
        }
    }

    const SignUpApiHandler = async (req) => {
        const { message, success, data } = await mainPostServices(SIGNUP_API_URL, req);

        if (success) {
            setCounterTime(30)
            setAuthVerifyModal(true)
            setBtnLoading(false)
        } else {
            Sweetalert.fire({
                title: 'Info',
                text: "We were unable to locate this registration code. Please reach out to your care center support for a valid registration code OR try again."
            })
            
            setBtnLoading(false)
        }
    }

    const clearFormData = () => {
        setRegistrationState({
            institution_registration_code: '',
            email: decryptedData?.email,
            password: '',
            confirmPassword: ""
        })
        simpleValidator.current.hideMessages();
        forceUpdate()
    }

    return (
        <Fragment>
            <Helmet>
                <title>{"Haploscope MD"}</title>
            </Helmet>
            <div className="signin">
                <div className="container">
                    <div className="row header_logo">
                        <div className="logo"><img src={logo} alt="logo_img" /></div>
                    </div>
                </div>
                <section className="sigin_banner signup_banner_block">
                    <div className="container">
                        <div className="sign_box signup_block">
                            <div className="account_system">
                                <h4>patient Management System</h4>
                            </div>
                            <div className="admin_sigin">
                                <h2>Sign Up</h2>
                                <form onSubmit={signUpHandler} onReset={clearFormData}>
                                    <div className="form_group">
                                        <div><label>Physician Registration Code</label></div>
                                        <input
                                            type="text"
                                            placeholder="Physician Registration Code"
                                            className="form_control"
                                            name="institution_registration_code"
                                            value={registrationState?.institution_registration_code}
                                            autoComplete="off"
                                            onChange={handleLoginInputChange}
                                        />
                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                        
                                    {simpleValidator.current.message('institution_registration_code', registrationState.institution_registration_code, 'required')}
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <div><label>Email</label></div>
                                        <input
                                            type="text"
                                            placeholder="Email"
                                            className="form_control"
                                            name="email"
                                            autoComplete="off"
                                            value={registrationState?.email}
                                            disabled={true}
                                        />
                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                            {simpleValidator.current.message('email', registrationState.email, 'required|email')}
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <div><label>Password</label></div>
                                        <input
                                            type={viewPassWord ? "text" : "password"}
                                            placeholder="Password"
                                            className="form_control pr_4"
                                            name="password"
                                            autoComplete="off"
                                            value={registrationState?.password}
                                            onChange={handleLoginInputChange}
                                        />
                                        <span className={`password_icon ${viewPassWord ? 'icon_show' : 'icon_hide'}`}><img src={viewPassWord ? icon_open_password : icone_hide_pass} alt="icone_hide_password" onClick={() => togglePassWordHideShow(!viewPassWord)} /></span>
                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                            {simpleValidator.current.message('password', registrationState.password, `required|password`)}
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <div><label>Confirm Password</label></div>
                                        <input
                                                type={ViewConPassWord ? "text" : "password"}
                                            placeholder="Confirm Password"
                                            className="form_control pr_4"
                                            name="confirmPassword"
                                            autoComplete="off"
                                            value={registrationState?.confirmPassword}
                                            onChange={handleLoginInputChange}
                                        />
                                        <span className={`password_icon ${ViewConPassWord ? 'icon_show' : 'icon_hide'}`}><img src={ViewConPassWord ? icon_open_password : icone_hide_pass} alt="icone_hide_password" onClick={() => toggleConPassWordHideShow(!ViewConPassWord)} /></span>
                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                            {simpleValidator.current.message('confirmPassword', registrationState.confirmPassword, `required|in:${registrationState.password}`, { messages: { in: 'Confirm Password & Password Does not Match!!' } })}
                                        </div>
                                    </div>
                                    <div className="d_flex_center mt_4 mb_4 form_buttons">
                                        <button type="reset" className="btn_default">Clear</button>
                                        <button className="btn_primary ml_2" type="submit">
                                            {btnLoading ?
                                                <Loader color="primary" size="sm" />
                                                :
                                                "Submit"
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
            {
                authVerifyModal &&
                <AuthenticatePop authVerifyModal={authVerifyModal} setAuthVerifyModal={setAuthVerifyModal} email={registrationState?.email} counterTime={counterTime} />
            }

        </Fragment>
    )
}
export default WebSignup