import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { GET_SCHEDULE_URL } from '../../shared/constant'
import { mainPostServices, socketSendRequest } from '../../shared/service'
import calendar_icon from '../../assets/images/cal-icon.png'
import { EDIT_APPOINMENT, GET_SCHEDULE, SET_FILTER_SCHEDULE } from '../../store/action'
import { useHistory } from "react-router-dom";
import Tooltip from "react-simple-tooltip";

const ScheduleHeader = ({ handleAppoinmentModal, openView }) => {


    const [currentStatus, setCurrentStatus] = useState('accepted');
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.userDetailsReducer.currentUser)
    const filters = useSelector(state => state.scheduleReducer.filters)
    const { archiveScheduleCount, confirmScheduleCount, scheduleCount } = useSelector(state => state.scheduleReducer.scheduleCount)
    const history = useHistory()
    useEffect(() => {
        dispatch({
            type: SET_FILTER_SCHEDULE, payload: {
                search: '',
                status: 'accepted'
            }
        })
    }, [currentUser])
    const handleSearchName = async (e) => {
        if (!e.target.value) {
            history.push("/schedule")
        }
        dispatch({
            type: SET_FILTER_SCHEDULE, payload: {
                search: e.target.value,
                status: filters.status
            }
        })
        if (filters.search) {
            const req = {
                name: e.target.value,
                status: filters.status,
                page: 1,
                limit: 5
            }
            const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_URL, req);
            if (success) {
                dispatch({ type: GET_SCHEDULE, payload: data })
            }
        } else {
            const req = {
                status: filters.status,
                name: filters.search,
                page: 1,
                limit: 5
            }
            const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_URL, req);
            if (success) {
                dispatch({ type: GET_SCHEDULE, payload: data })

            }
        }

    }

    const handleFilterStatusOne = async (e) => {
        setCurrentStatus(e.target.checked ? "pending" : "accepted")
        dispatch({
            type: SET_FILTER_SCHEDULE, payload: {
                search: '',
                status: e.target.checked ? "pending" : "accepted"
            }
        })
        const req = {
            name: '',
            status: e.target.checked ? 'pending' : "accepted",
            page: 1,
            limit: 5
        }
        const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_URL, req);
        if (success) {
            dispatch({ type: GET_SCHEDULE, payload: data })
        }
    }

    const handleFilterStatusTwo = async (e) => {
        dispatch({
            type: SET_FILTER_SCHEDULE, payload: {
                search: '',
                status: e.target.checked ? "archived" : currentStatus
            }
        })
        const req = {
            name: '',
            status: e.target.checked ? "archived" : currentStatus,
            page: 1,
            limit: 5
        }
        const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_URL, req);
        if (success) {
            dispatch({ type: GET_SCHEDULE, payload: data })
        }
    }

    const wanstToAddAppoinMent = (e) => {
        e.preventDefault()
        handleAppoinmentModal(true)
        dispatch({ type: EDIT_APPOINMENT, payload: null })
    }
    const open = (e) => {
        e.preventDefault();
        openView(true);
    }
    return (
        <div className="schedulepatient_nav">
            <div className="searchbar sp_nav_comman">
                <input type="text" id="search" className='form_control' placeholder="Search" value={filters.search} onChange={handleSearchName} />
            </div>
            <div className={`nav_text switch_box sp_nav_comman ${filters.status === 'archived' ? '' : filters.status === 'pending' ? 'pending' : 'confirmed'}`}>
                <p className="off">Confirmed{confirmScheduleCount > 0 && `(${confirmScheduleCount})`}</p>
                <label className="switch">
                    <input type="checkbox" onChange={handleFilterStatusOne} disabled={filters.status === 'archived'} />
                    <span className="slider" />
                </label>
                <p className="on">Pending{scheduleCount > 0 && `(${scheduleCount})`}</p>
            </div>
            <div className={`nav_text switch_box sp_nav_comman ${filters.status === 'archived' ? 'archived' : 'upcoming'}`}>
                <p className="off">Upcoming</p>
                <label className="switch">
                    <input type="checkbox" defaultChecked={filters.status === 'archived'} onChange={handleFilterStatusTwo} />
                    <span className="slider" />
                </label>
                <p className="on">Archived{archiveScheduleCount > 0 && `(${archiveScheduleCount})`}</p>

            </div>
            <div className='header-clinicName_block'>
                <div className="nav_text clinicName psd_link_block sp_nav_comman">
                    <p className={'clinic_name-block'}>{currentUser?.clinic_name}</p>
                </div>
                <div className="nav_text add_appoinment sp_nav_comman">
                    <button className="btn add_appoinment_btn" onClick={(e) => wanstToAddAppoinMent(e)}>Add Appointment </button>
                </div>
            </div>
            <div className="nav_text psd_link_block sp_nav_comman calander_view">
                {/* <Tooltip content="Calender View" placement="bottom"> */}
                <img src={calendar_icon} onClick={(e) => open(e)} alt={''} />
                <div className='tooltip_block'>
                    Calendar View
                </div>
                {/* </Tooltip> */}
            </div>
        </div>
    )
}

export default ScheduleHeader
