import {Modal, ModalBody} from "reactstrap";
import React from "react";

const StartMeeting = ({videoStartModal,startMeetingVideo})=>{
    return(
        <Modal isOpen={videoStartModal} className='video-pop main_comman_modal'>
            <ModalBody>
                <div className='custom_modal text_center'>
                    <h3 className='mb_5 title'>START is not available yet</h3>
                    <p className='mt_3 content'>START button will be available 5 min before scheduled time.</p>
                    <p className='mt_2 sub_heading'><strong> Please refresh page to get the button when you are 5 minutes or less away from the time.</strong></p>
                    <div className="form_group d_flex justify_content_center form_action delete_modal_btn">
                        <button className="btn btn_default" onClick={() => startMeetingVideo(false)}>OK</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default StartMeeting