import React, {useEffect, useState} from "react";
import download from '../../assets/images/download-icon.png';
import logo from "../../assets/images/md_logo.png";
import {mainPostServices} from "../../shared/service";
import {GET_PHYSICIAN_DETAILS} from "../../shared/constant";
import { useLocation,useHistory } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Loader from "../Loader";
import {generateAvatar} from "../../utils";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var CryptoJS = require("crypto-js");
toast.configure();


const PhysicianDetails = () => {
    const { search } = useLocation();
   const history = useHistory();
    const [loader,setLoader] = useState(false)
    const [phy_data,setPhyData] = useState('')
    let decryptedData ;
    let decrypted = CryptoJS.AES.decrypt(search?.substring(1)?.toString(), process.env.REACT_APP_SECRET_KEY);
    if (search) {
        decryptedData = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    } else {
        history.push("/")
    }

    useEffect(()=>{
        getPhysicianDetails()
    },[])
    const getPhysicianDetails = async ()=>{
        setLoader(true)
        const {data, message, success } = await mainPostServices(GET_PHYSICIAN_DETAILS, decryptedData);
        if(success){
            setLoader(false)
            setPhyData(data)
        }else {
            setLoader(false)
        }
    }
    const copyUrlHandler = ()=>{
        toast.success('Url Copied Successfully.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return (
        <>
            <header>
                {
                    <div className="container_fluid">
                        <div className="row main_herader_row">
                            <div className="header px_auto">
                                <div className="logo"><img src={ logo} alt="logo_img" /></div>
                            </div>
                        </div>
                    </div>
                }

            </header>
            {
                loader?
                    <div className='spin_loder'>
                        <Loader className="data_load" />
                    </div>
                    :
                    <div className="ps-details_main_block">
                        <div className="ps-details_container">
                            {/* header */}
                            <div className="ps-details_header_main">
                                <div className="ps-details_header">
                                    <div className="ps-details_title">
                                        <h2>physician details</h2>
                                    </div>
                                    <div className="ps-details_profile">
                                        <div className="ps-details_name">
                                            <h4>{`Dr. ${phy_data?.first_name + ' ' + phy_data?.last_name} ${phy_data?.abbreviated_degrees}`}</h4>
                                            <span>{phy_data?.hospital_name}</span>
                                        </div>
                                        <div className="ps-details_picture">
                                            <img src={phy_data?.profile? phy_data?.profile : generateAvatar(phy_data?.first_name + ' ' + phy_data?.last_name)} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* bio main */}
                            <div className="ps-details_biomain">
                                <div className="ps-detail_bioheader">
                                    <h4>Bio</h4>
                                    <CopyToClipboard text={window?.location?.href}  onCopy={() => copyUrlHandler()}>
                                        <button className="download-btn"><img src={download} alt="" /></button>
                                    </CopyToClipboard>
                                </div>
                                {/* bio details */}
                                <div className="ps-details_biodetail">
                                    <p>{phy_data?.bio}</p>

                                </div>
                                <span className="year_experiance">Years of Experience: {phy_data?.years_of_experiance}</span>
                                {/* education */}
                                <div className="ps-details_educationmain">
                                    <h4>Education</h4>
                                    <div className="comman-ps_ulBlock ps-details_list">
                                        <ul>{
                                            phy_data?.education?.map((ed,i)=>{
                                                return <li key={i}>{ed}</li>
                                            })
                                        }

                                        </ul>
                                    </div>
                                </div>
                                {/* Center Affiliations */}
                                <div className="ps-details_educationmain">
                                    <h4>Center Affiliations</h4>
                                    <div className="comman-ps_ulBlock ps-details_list">
                                        <ul>
                                            {
                                                phy_data?.center_affiliations?.map((ed,i)=>{
                                                    return <li key={i}>{ed}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                {/* Awards & Activities */}
                                <div className="ps-details_educationmain">
                                    <h4>Awards & Activities</h4>
                                    <div className="comman-ps_ulBlock ps-details_list">
                                        <ul>
                                            {
                                                phy_data?.awards_activities?.map((ed,i)=>{
                                                    return <li key={i}>{ed}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            </>
    );
}

export default PhysicianDetails;