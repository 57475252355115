import React, { useEffect, useState } from 'react';


const NetWorkDetector = ({setReconnectingState}) => {

    const [networkGone, setNetworkGone] = useState(false)
    useEffect(() => {
            window.addEventListener('online', handleConnectionChange);
            window.addEventListener('offline', handleConnectionChange);
            return () => {
                window.addEventListener('online', handleConnectionChange);
                window.addEventListener('offline', handleConnectionChange);
            };
    }, [])

    const handleConnectionChange = () => {
        if (!navigator.onLine) {
            setNetworkGone(true)
            setReconnectingState(true)
        }
    };

    const closePopUp = () => {
        setNetworkGone(false)
    }

    return (
        networkGone &&
        <div className='modal'>
            <div className='main_delete_modal main_comman_modal update-patient_modal'>
                <h4>No Internet</h4>
                <div className='delete_content'>
                    <p>You do not have any active network connection.<br/>
                       Please check your internet connection
                    </p>
                </div>
                <form className="form_group d_flex justify_content_center form_action delete_modal_btn">
                    <button id="saveBtn" className='btn btn_default' type="button" onClick={closePopUp}>Ok</button>
                </form>
            </div>
        </div>
    )
}

export default NetWorkDetector