import React, { Fragment, useEffect, useState } from 'react';
import { useRoom } from 'use-twilio-video'
import { useHistory } from 'react-router-dom';
import { mainPostServices } from '../../shared/service';
import { DISCONNECT_ROOM_URL } from '../../shared/constant';
import Participant from './Participents';
import hp_icon from '../../assets/images/hp-icon.svg';
import audio_icon from '../../assets/images/unmute_audio.png';
import mute_icon from '../../assets/images/mute_audio.png';
import no_show_video from '../../assets/images/no_show_video.png';
import show_video from '../../assets/images/show_video.png';
import SweetAlert from 'sweetalert2';
import LeaveCallPopupComponent from './LeaveCallPopup';
import NetWorkDetector from './NetWorkDetector';
import moment from 'moment';
// import ReamainigCallTimePopup from './ReamainigCallTimePopup';

const CallScreenComponent = () => {
        const history = useHistory();
        const [scheduleId, setScheduleId] = useState(null)
        const token = localStorage.getItem('video_token');
        const roomData = JSON.parse(localStorage.getItem('room'));
        const [isActive, setIsActive] = useState(false);
        const [length, setLength] = useState('1');
        const [leaveCallModal, setLeaveCallModal] = useState(false)
        const [reconnectingState, setReconnectingState] = useState(false)
        const [reamainingTimeModal, setReamainingTimeModal] = useState(false)
        const [reamainingMinutesTime, setReamainingMinutesTime] = useState()



        

        console.log("roomData",roomData, moment(roomData?.dateCreated).format(),moment().format(),moment(roomData?.dateCreated).add(45,'minutes').format("HH:mm:ss"));

        // const roomDurationRemainingTime = roomData?.maxParticipantDuration === 1800 ?  moment(roomData?.dateCreated).add(30,'minutes').format() :  moment(roomData?.dateCreated).add(45,'minutes').format()
        // const currentTime = new Date();
        // const endEndTime = new Date(roomDurationRemainingTime);
        // const minutes = parseInt(Math.abs(currentTime.getTime() - endEndTime.getTime()) / (1000 * 60) % 60);
        // console.log("roomDurationRemainingTime",roomDurationRemainingTime,minutes);

        console.log("videoToken", token);
        console.log("reconnectingState",reconnectingState);

        const {
                room,
                error,
                connectRoom,
                disconnectRoom,
                localParticipant,
                remoteParticipants,
                dominantSpeaker,
                isCameraOn,
                toggleCamera,
                isMicrophoneOn,
                toggleMicrophone
        } = useRoom()

        console.log("localParticipant", localParticipant, "remoteParticipants", remoteParticipants, "dominantSpeaker", dominantSpeaker);
        console.log(length, "length------")
        console.log("room", room);
        console.log("video error", error?.message)

        console.log("roomState",room?.state);

        useEffect(() => {
                if (!room && token && roomData?.uniqueName) {
                        connectRoom({ token, options: { name: roomData?.uniqueName, dominantSpeaker: true,  
                                networkQuality: {
                                        local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
                                        remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
                                }} 
                        })
                        localStorage.setItem("isActive", JSON.stringify(true));
                        setIsActive(JSON.parse(localStorage.getItem("isActive")));
                        setScheduleId(roomData?.uniqueName)
                        return () => disconnectRoom()
                }

        }, [connectRoom, token, disconnectRoom, room, roomData?.uniqueName])

        // useEffect(() => {
        //         let intervalId;
        //         intervalId = setInterval(() => {
        //                 if (roomData) {
        //                         const roomDurationRemainingTime = roomData?.maxParticipantDuration === 1800 ?  moment(roomData?.dateCreated).add(30,'minutes').format() :  moment(roomData?.dateCreated).add(45,'minutes').format()
        //                         const currentTime = new Date();
        //                         const endEndTime = new Date(roomDurationRemainingTime);
        //                         const remainingMinutes = parseInt(Math.abs(currentTime.getTime() - endEndTime.getTime()) / (1000 * 60) % 60);
        //                         setReamainingMinutesTime(remainingMinutes)
        //                 }
        //         },1000)
        //         return () => {
        //                 clearInterval(intervalId)
        //         };
        // },[roomData])

        useEffect(() => {
                if(room){
                        room.on('disconnected', (room, error) => {
                                console.log("disconnect event >>>>>>>>",room,error?.code);
                                if(error.code === 53216 || error.code === 53118){
                                        doDisconnect()
                                }
                                if (error.code === 20104) {
                                  console.log('Signaling reconnection failed due to expired AccessToken!');
                                } else if (error.code === 53000) {
                                  console.log('Signaling reconnection attempts exhausted!');
                                } else if (error.code === 53002) {
                                  console.log('Signaling reconnection took too long!');
                                }
                        });

                        room.on('reconnecting', error => {
                                if (error.code === 53001) {
                                  setReconnectingState(true)
                                  console.log('Reconnecting your signaling connection!', error.message);
                                } else if (error.code === 53405) {
                                  console.log('Reconnecting your media connection!', error.message);
                                }
                        });

                        room.on('reconnected', () => {
                                setReconnectingState(false)
                                console.log('Reconnected your signaling and media connections!');
                        });    
                        
                }      
        },[room])

        useEffect(() => {
                if (error?.message) {
                        let timerInterval
                        SweetAlert.fire({
                                html: error?.message,
                                timer: 2000,
                                timerProgressBar: false,
                                icon: 'info',
                                showConfirmButton: false,
                                didOpen: () => {
                                        timerInterval = setInterval(() => {
                                                SweetAlert.getTimerLeft()
                                        }, 100)
                                },
                                willClose: () => {
                                        clearInterval(timerInterval)
                                        doDisconnect()
                                }
                        })
                }
        }, [error?.message])


        // useEffect(() => {
        //         let intervalId;
        //         intervalId = setInterval(() => {
        //                 if (isActive) {
        //                         setIsActive(JSON.parse(localStorage.getItem("isActive")));
        //                         const minuteCounter = Math.floor(
        //                                 localStorage.getItem("secondCounter") / 60
        //                         );
        //                         let computedMinute =
        //                                 String(minuteCounter).length === 1
        //                                         ? `0${minuteCounter}`
        //                                         : minuteCounter;

        //                         const secondCounters = Number(localStorage.getItem("secondCounter"));
        //                         localStorage.setItem("secondCounter", secondCounters + 1);
        //                         localStorage.setItem(
        //                                 "timerInfo",
        //                                 JSON.stringify({
        //                                         min: computedMinute,
        //                                 })
        //                         );
        //                 }
        //         }, 1000);

        //         return () => {
        //                 clearInterval(intervalId)
        //                 // disconnectRoom()
        //         };
        // }, [isActive]);


        useEffect(() => {
                let getClass = document.getElementsByClassName('sub-videoscreen_block').length;
                setLength(getClass)
        }, [remoteParticipants]);

        const doDisconnect = async () => {
                if(scheduleId){
                        const { data, success } = await mainPostServices(DISCONNECT_ROOM_URL, { schedule_id: scheduleId });
                        if (success) {

                                localStorage.removeItem('video_token')
                                localStorage.removeItem("room")
                                localStorage.removeItem('timerInfo')
                                localStorage.removeItem('isActive')
                                localStorage.removeItem('secondCounter')
                                setIsActive(false)
                                history.push('/schedule')
                        }
                }
               
        }

        const wantsToLeaceCall = (sid) => {
                setScheduleId(sid)
                setLeaveCallModal(true)
        }

        const [allParticipent, setAllParticipent] = useState([])

        useEffect(() => {
                if (localParticipant && !remoteParticipants?.includes(localParticipant)) {
                        remoteParticipants?.push(localParticipant)
                }
                const patient = remoteParticipants?.find(p => JSON.parse(p?.identity)?.identity === 'patient')
                const doctor = remoteParticipants?.find(p => JSON.parse(p?.identity)?.identity === 'doctor')
                const caregiver = remoteParticipants?.find(p => JSON.parse(p?.identity)?.identity === 'caregiver')
                if (remoteParticipants?.length === 3 && patient && caregiver && doctor) {
                        var arr = []
                        arr[0] = patient;
                        arr[1] = doctor;
                        arr[2] = caregiver;
                        setAllParticipent(arr)
                }
                if (remoteParticipants?.length === 2 && patient) {
                        var arr = []
                        arr[0] = patient;
                        arr[1] = doctor;
                        setAllParticipent(arr)
                }
                if (remoteParticipants?.length === 2 && caregiver) {
                        var arr = []
                        arr[0] = caregiver;
                        arr[1] = doctor;
                        setAllParticipent(arr)
                }
                if (remoteParticipants?.length === 1 && doctor) {
                        var arr = []
                        arr[0] = doctor;
                        setAllParticipent(arr)
                }
        }, [length, localParticipant, remoteParticipants])


        return (
                <Fragment>
                        <header />
                        <section className=" inner_wrapper">
                                <header className='videoscreen_header'>
                                        <div className="container_fluid">
                                                <div className="row main_herader_row">
                                                        <div className="header px_auto">
                                                                <div className="logo"><img src={hp_icon} alt="logo_img" /></div>
                                                        </div>
                                                </div>
                                        </div>
                                </header>
                        </section>
                        <div className='videoscreen_container'>
                                {room && room?.state === 'connected' && !reconnectingState ?
                                        <>
                                        <div className='main-videoscreen_block'>
                                                {allParticipent?.map((p, i) => {
                                                        return (
                                                                allParticipent?.length === 3 ?
                                                                        <div className={
                                                                                JSON.parse(p?.identity)?.identity === 'patient' ? "sub-videoscreen_block full" :
                                                                                        JSON.parse(p?.identity)?.identity === 'doctor' ? "sub-videoscreen_block half" :
                                                                                                JSON.parse(p?.identity)?.identity === 'caregiver' ? "sub-videoscreen_block half" :
                                                                                                        "sub-videoscreen_block"}>

                                                                                <Participant participant={p} />
                                                                        </div> :
                                                                allParticipent?.length === 2 ?
                                                                        <div className={
                                                                                JSON.parse(p?.identity)?.identity === 'patient' ? "sub-videoscreen_block full" :
                                                                                        JSON.parse(p?.identity)?.identity === 'caregiver' ? "sub-videoscreen_block full" :
                                                                                                JSON.parse(p?.identity)?.identity === 'doctor' ? "sub-videoscreen_block full" :
                                                                                                        "sub-videoscreen_block"}>
                                                                                <Participant participant={p} />
                                                                        </div> :
                                                                allParticipent?.length === 1 ?
                                                                        <div className={
                                                                                JSON.parse(p?.identity)?.identity === 'doctor' ? "sub-videoscreen_block" :
                                                                                        "sub-videoscreen_block"}>
                                                                                <Participant participant={p} />
                                                                        </div> : null
                                                        )
                                                }
                                                )}

                                        </div>

                                        <div className='videoscreen_btn'>
                                                <div className='main_vsbutton-block'>
                                                        <div className='vs-button_block'>
                                                                <button onClick={() => toggleCamera()}>
                                                                        {isCameraOn ?
                                                                                <div className='audio_icon'><img src={show_video} alt='icon' />
                                                                                </div> :
                                                                                <div className='audio_icon'><img src={no_show_video} alt='icon' />
                                                                                </div>}
                                                                </button>
                                                                <button onClick={() => toggleMicrophone()}>
                                                                        {isMicrophoneOn ?
                                                                                <div className='audio_icon'><img src={audio_icon} alt='audio off' />
                                                                                </div> :
                                                                                <div className='audio_icon'><img src={mute_icon} alt='mute icon' />
                                                                                </div>}
                                                                </button>
                                                        </div>
                                                        <div className='leave-btn'>
                                                                <button onClick={() => wantsToLeaceCall(roomData?.uniqueName)}>
                                                                        LEAVE
                                                                </button>
                                                        </div>
                                                </div>
                                        </div>
                                        
                                        </>
                                        :
                                        <div className='main-videoscreen_block'>
                                                <div className={"sub-videoscreen_block"}>
                                                        <p className='pc_name'>{reconnectingState ? 'reconnecting...' : 'Connecting...'}</p>
                                                </div>
                                                <div className='videoscreen_btn vd_screen_footer' />
                                        </div>

                                }
                        </div>
                        <LeaveCallPopupComponent
                                leaveCallModal={leaveCallModal}
                                setLeaveCallModal={setLeaveCallModal}
                                doDisconnect={doDisconnect}
                        />
                        <NetWorkDetector setReconnectingState={setReconnectingState}/>
                        {/* <ReamainigCallTimePopup reamainingTimeModal={reamainingTimeModal} setReamainingTimeModal={setReamainingTimeModal}/> */}

                </Fragment>
        )
}

export default CallScreenComponent