import React, { useRef, useState } from 'react';
import icone_hide_pass from "../../assets/images/eye_hide.svg";
import icon_open_password from "../../assets/images/eye_show.svg";
import SimpleReactValidator from 'simple-react-validator';
import { mainPostServices } from '../../shared/service';
import Sweetalert from "sweetalert2";
import { CHANGE_PASSWORD_URL } from '../../shared/constant';
import Loader from '../Loader';

const SettingComponent = () => {

    const simpleValidator = useRef(new SimpleReactValidator({
        validators: {
            password: {
                message: "Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit.",
                // eslint-disable-next-line
                rule: (val, params, validator) => validator.helpers.testRegex(val.trim(), /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/), required: true, // optional
            }
        }
    }))
    const [, updateState] = useState();
    const [btnLoading, setBtnLoading] = useState(false)
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [viewPassword, setviewPassword] = useState({
        currentPasswordShow: false,
        newPasswordShow: false,
        verifyPasswordShow: false
    })

    const [passwordState, setPasswordState] = useState({
        currentPassword: '',
        newPassword: '',
        verifyPassword: ''
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setPasswordState({
            ...passwordState, [name]: value.trim()
        })
    }

    const togglePassWordHideShow = (name, value) => {
        setviewPassword({
            ...viewPassword, [name]: value
        })
    }

    const doChangePassword = async (req) => {
        const { message, success } = await mainPostServices(CHANGE_PASSWORD_URL, req);
        if (success) {
            simpleValidator.current.hideMessages();
            forceUpdate()
            setPasswordState({
                ...passwordState,
                currentPassword: '',
                newPassword: '',
                verifyPassword: ''
            })
            setviewPassword({
                ...viewPassword,
                currentPasswordShow: false,
                newPasswordShow: false,
                verifyPasswordShow: false
            })
            Sweetalert.fire({
                title: 'Success',
                text: message
            })
            setBtnLoading(false)
        } else {
            Sweetalert.fire({
                title: 'Error',
                text: message
            })
            setBtnLoading(false)
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            doChangePassword(passwordState)
            setBtnLoading(true)
        } else {
            simpleValidator.current.showMessages();
            forceUpdate()
        }
    }

    const clearForm = () => {
        setPasswordState({
            ...passwordState,
            currentPassword: '',
            newPassword: '',
            verifyPassword: ''
        })
        setviewPassword({
            ...viewPassword,
            currentPasswordShow: false,
            newPasswordShow: false,
            verifyPasswordShow: false
        })
        simpleValidator.current.hideMessages();
        forceUpdate()
    }


    return (
        <div className="clinics patients_list_main">
            <div className="heading_content">
                <div className='sub_header_row'>
                    <div className="inner_header clinic_header">
                        <span className='breadcrumb text_uppercase montserrat_bold'>Settings</span>
                    </div>
                </div>

            </div>
            <div className={'admin_sigin main_setting'}>

                <form
                    className="setting_form"
                    onSubmit={handleSubmit}
                    onReset={clearForm}
                >
                    <div className="form_group">
                        <div><label>Current Password</label></div>
                        <input
                            type={viewPassword.currentPasswordShow ? "text" : "password"}
                            placeholder="Current Password"
                            className="form_control"
                            name="currentPassword"
                            onChange={handleInputChange}
                            value={passwordState.currentPassword}
                            autoComplete="new-password"
                        />
                        <span className={`password_icon ${viewPassword.currentPasswordShow ? 'icon_show' : 'icon_hide'}`}><img src={viewPassword.currentPasswordShow ? icon_open_password : icone_hide_pass} alt="icone_hide_password" onClick={() => togglePassWordHideShow('currentPasswordShow', !viewPassword.currentPasswordShow)} /></span>
                        <div className="invalid-feedback pl-4 d-block errorMsg">
                            {simpleValidator.current.message('currentPassword ', passwordState.currentPassword, 'required')}
                        </div>
                    </div>
                    <div className="form_group">
                        <div><label>New Password</label></div>
                        <input
                            type={viewPassword.newPasswordShow ? "text" : "password"}
                            placeholder="New Password"
                            className="form_control"
                            name="newPassword"
                            autoComplete="off"
                            onChange={handleInputChange}
                            value={passwordState.newPassword}
                        />
                        <span className={`password_icon ${viewPassword.newPasswordShow ? 'icon_show' : 'icon_hide'}`}><img src={viewPassword.newPasswordShow ? icon_open_password : icone_hide_pass} alt="icone_hide_password" onClick={() => togglePassWordHideShow('newPasswordShow', !viewPassword.newPasswordShow)} /></span>
                        <div className="invalid-feedback pl-4 d-block errorMsg">
                            {simpleValidator.current.message('newPassword', passwordState.newPassword, `required|password|in:${passwordState.verifyPassword}`, { messages: { in: 'New Password & Confirm Password Does not Match!!' } })}
                        </div>
                    </div>
                    <div className="form_group">
                        <div><label>Confirm Password</label></div>
                        <input
                            type={viewPassword.verifyPasswordShow ? "text" : "password"}
                            placeholder="Confirm Password"
                            className="form_control pr_4"
                            name="verifyPassword"
                            autoComplete="off"
                            onChange={handleInputChange}
                            value={passwordState.verifyPassword}
                        />
                        <span className={`password_icon ${viewPassword.verifyPasswordShow ? 'icon_show' : 'icon_hide'}`}><img src={viewPassword.verifyPasswordShow ? icon_open_password : icone_hide_pass} alt="icone_hide_password" onClick={() => togglePassWordHideShow('verifyPasswordShow', !viewPassword.verifyPasswordShow)} /></span>
                        <div className="invalid-feedback pl-4 d-block errorMsg">
                            {simpleValidator.current.message('verifyPassword', passwordState.verifyPassword, `required|in:${passwordState.newPassword}`, { messages: { in: 'Confirm Password & New Password Does not Match!!' } })}
                        </div>
                    </div>
                    <div className="d_flex_center mt_4 mb_4 form_buttons">
                        <button type="reset" className="btn btn_default">Clear</button>
                        <button className="btn_primary ml_2" type="submit">
                            {btnLoading ?
                                <Loader color="primary" size="sm" />
                                :
                                "Submit"
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SettingComponent;
