export const SET_USER_PROFILE =  "SET_USER_PROFILE"
export const GET_USER_PROFILE =  "GET_USER_PROFILE"

// SCHEDULE 

export const GET_SCHEDULE =  "GET_SCHEDULE"
export const GET_SCHEDULE_BYDATE =  "GET_SCHEDULE_BYDATE"
export const GET_SCHEDULE_STATUS = "GET_SCHEDULE_STATUS"
export const SET_FILTER_SCHEDULE = "SET_FILTER_SCHEDULE"
export const EDIT_APPOINMENT = "EDIT_APPOINMENT"
export const DELETE_APPOINMENT = "DELETE_APPOINMENT"
export const CHANGE_SCHEDULE_DATE = "CHANGE_SCHEDULE_DATE"
export const APPOINMENT_SCHEDULE_PATIENTS =  "APPOINMENT_SCHEDULE_PATIENTS"
export const GET_AVAILABLE_MIUTES =  "GET_AVAILABLE_MIUTES"
export const REQUEST_SCHEDULE_MINUTE =  "REQUEST_SCHEDULE_MINUTE"

// Patients
export const GET_PATIENTS =  "GET_PATIENTS"
export const SET_FILTER_PATIENTS = "SET_FILTER_PATIENTS"
export const EDIT_PATIENT_STATE = "EDIT_PATIENT_STATE"
export const DELETE_PATIENT_STATE = "DELETE_PATIENT_STATE"
export const UPDATE_PATIENT_STATUS = "UPDATE_PATIENT_STATUS"
export const DELETE_PATIENT_DATA = "DELETE_PATIENT_DATA";
export const UPDATE_PATIENTS_DATA = "UPDATE_PATIENTS_DATA";

// Mesaage 
export const SOCKET_CONNECTION = "SOCKET_CONNECTION";
export const PHYSICIANS_MESSAGE_LIST = "PHYSICIANS_MESSAGE_LIST";
export const SELECT_RECEIVER_DETAILS = "SELECT_RECEIVER_DETAILS"
export const CHAT_MESSAGE_LIST = "CHAT_MESSAGE_LIST"
export const SEND_MESSAGE = "SEND_MESSAGE"
export const SCROLL_TOP = "SCROLL_TOP"
export const PHYSICIAN_NOTIFICATION_LIST = "PHYSICIAN_NOTIFICATION_LIST"
export const PHYSICIAN_MESSAGE_COUNT = "PHYSICIAN_MESSAGE_COUNT"
export const SEARCH_MESSAGE = "SEARCH_MESSAGE"
export const DATA_WAITING_LOADER = "DATA_WAITING_LOADER";


// password

export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"

export const PHYSICIAN_SCHEDULE_COUNT = "PHYSICIAN_SCHEDULE_COUNT";