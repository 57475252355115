import { combineReducers } from 'redux';
import userDetailsReducer from './userDetails';
import scheduleReducer from './schedule';
import patientsReducer from './patients';
import chatReducer from './chat';

export default combineReducers({
    userDetailsReducer,
    scheduleReducer,
    patientsReducer,
    chatReducer
})