import React from 'react'
import mobile_md_logo from "../../../../assets/images/mobile_md_logo.svg";
import googlePlay from "../../../../assets/images/googlePlay.png";
import appStore from "../../../../assets/images/appStore.png";
import {Link} from "react-router-dom";

export default function SignupSuccessScreen() {
        return (
                <section className="hp_registration-sec hp_reg-successScreen">
                        <div className="container">
                                {/* logo */}
                                <div className="hp_reg-logo">
                                        <a href="#">
                                                <img src={mobile_md_logo} alt='logo' />
                                        </a>
                                </div>
                                <div className='hp_successScreen_content'>
                                        <p>Congratulations! You have successfully signed up. </p>
                                        <a href={"/login"} target={"_blank"}>Click here to log in to the physician portal.</a>
                                        <p className='download_txt'>Download the mobile app from the links below and start using the platform by logging with your credentials.</p>
                                        <div className='link_btns'>
                                                <a href='https://apps.apple.com/in/app/haploscope-md/id1612401234' className='appStore' target={"_blank"}>
                                                        <img src={appStore}  alt={'appStore'}/>
                                                </a>
                                                <a href='https://play.google.com/store/apps/details?id=com.haploscope.md' target={"_blank"} className='googlePlay'>
                                                        <img src={googlePlay}  alt={'googlePlay'}/>
                                                </a>
                                        </div>
                                </div>
                        </div>
                </section>
        )
}
