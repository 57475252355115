import { APPOINMENT_SCHEDULE_PATIENTS, CHANGE_SCHEDULE_DATE, DELETE_APPOINMENT, EDIT_APPOINMENT, GET_AVAILABLE_MIUTES, GET_SCHEDULE, GET_SCHEDULE_BYDATE,
    GET_SCHEDULE_STATUS, SET_FILTER_SCHEDULE ,PHYSICIAN_SCHEDULE_COUNT} from "../action";
const initState = {
    allSchedule: {
        scheduleList: [],
        limit: 5,
        page: 1,
        totalPages: 0,
        totalResults: 0
    },
    dateSchedule: {
        dateWiseScheduleList: [],
        limit: 5,
        page: 1,
        totalPages: 0,
        totalResults: 0
    },
    calendarScheduleStatus: [],
    filters: {
        search: '',
        status: 'accepted'
    },
    editAppoinment: null,
    schedule_id: null,
    selectedScheduleDate: new Date(),

    patients: {
        list: [],
        limit: 10,
        page: 1,
        totalPages: 0,
        totalResults: 0
    },
    reqMinutes: {
        availableMinutes: 0,
        isUnlimited: false
    },
    scheduleCount:{}
}

export default function scheduleReducer(state = initState, action) {
    switch (action.type) {

        case GET_SCHEDULE:
            return {
                ...state,
                allSchedule: {
                    scheduleList: action.payload.docs,
                    limit: action.payload.perPage,
                    page: action.payload.currentPage,
                    totalPages: action.payload.totalPages,
                    totalResults: action.payload.total
                }
            };

        case GET_SCHEDULE_BYDATE:
            return {
                ...state,
                dateSchedule: {
                    dateWiseScheduleList: action.payload.docs,
                    limit: action.payload.limit,
                    page: action.payload.page,
                    totalPages: action.payload.pages,
                    totalResults: action.payload.total
                }
            };

        case APPOINMENT_SCHEDULE_PATIENTS:
            return {
                ...state,
                patients: {
                    list: action.payload.docs,
                    limit: action.payload.perPage,
                    page: action.payload.currentPage,
                    totalPages: action.payload.totalPages,
                    totalResults: action.payload.total
                }
            };

        case GET_AVAILABLE_MIUTES:
            if(action.payload.callLength){
                return {
                    ...state,
                    reqMinutes: {
                        availableMinutes: (state.reqMinutes.availableMinutes - Number(action.payload.callLength)),
                        billing_period_meeting_minutes: state.reqMinutes.billing_period_meeting_minutes,
                        isUnlimited:state.reqMinutes.isUnlimited
                    }
                };
            }else{
                return {
                    ...state,
                    reqMinutes: {
                        availableMinutes: action.payload.availableScheduleMinute,
                        billing_period_meeting_minutes: action.payload.billing_period_meeting_minutes,
                        isUnlimited: action.payload.isUnlimited
                    }
                };
            }


        case GET_SCHEDULE_STATUS:
            return {
                ...state,
                calendarScheduleStatus: action.payload
            }

        case SET_FILTER_SCHEDULE:
            return {
                ...state,
                filters: {
                    search: action.payload.search,
                    status: action.payload.status
                }
            }

        case CHANGE_SCHEDULE_DATE:
            return {
                ...state,
                selectedScheduleDate: action.payload
            }

        case EDIT_APPOINMENT:
            return {
                ...state,
                editAppoinment: action.payload
            }

        case DELETE_APPOINMENT:
            return {
                ...state,
                schedule_id: action.payload
            }
        case PHYSICIAN_SCHEDULE_COUNT:{
           return {
               ...state,
               scheduleCount: action.payload
           }
        }
        default:
            return state;
    }
}