import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const LeaveCallPopupComponent = ({ leaveCallModal, setLeaveCallModal, doDisconnect }) => {
  return (
    <Modal isOpen={leaveCallModal} className='deleteModal main_comman_modal leave_chat-modal'>
      <ModalBody>
        <div className='custom_modal text_center'>
          <h3 className='mb_5 title'>Leave Chat</h3>
          <p className='mt_3 content'>You are about to leave your current chat.This will stop your cuurent conversation. You can go back to your schedule to join again if your physician still has this chat open.</p>
          <p className='mt_2 sub_heading'><strong>Do you want to proceed?</strong></p>
          <div className="form_group d_flex justify_content_center form_action delete_modal_btn">
            <button className="btn btn_default mr_2" onClick={() => doDisconnect()}>Yes</button>
            <button className="btn btn_primary" onClick={() => setLeaveCallModal(false)}>No</button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default LeaveCallPopupComponent