import {useEffect, useState} from "react";
import Header from "./components/common/Header";
import Sidebar from "./components/common/Sidebar";
import {Encryption} from "./shared/function";
import { useDispatch, useSelector } from "react-redux";
import {PHYSICIAN_SCHEDULE_COUNT, SET_USER_PROFILE, SOCKET_CONNECTION} from "./store/action";
import {authSocketRequest, mainGetServices, setToken, socket, socketConnect, socketSendRequest} from "./shared/service";
import {GET_PROFILE_DATA} from "./shared/constant";
import Cookies from "js-cookie";


const App = ({children}) => {
  const dispatch = useDispatch() 
  const currentUser = useSelector(state => state.userDetailsReducer.currentUser)
  const [isMenuOpenMenu, setIsMenuOpenMenu] = useState(false)
  console.log(Cookies.get('x-access-token'),"token ----------------------")
  useEffect(() => {
      socketConnect(dispatch, Cookies.get('x-access-token'), (flag) => {
          if (flag) {
              dispatch({ type: SOCKET_CONNECTION, payload: flag })
              authSocketRequest("join", { user_id: currentUser?._id })
              socketSendRequest("getPhysicianMsgList", { page: 1, limit: 10 })
              socketSendRequest("getCounts", {})
          }
      })
      return () => {
        socket.off('response');
      };
  }, [currentUser])

    const socketListener = (res) => {
        switch (res.evt) {
            case 'getCounts':
                if (res.data) {
                    dispatch({ type: PHYSICIAN_SCHEDULE_COUNT, payload: res.data })
                }
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (currentUser) {
            authSocketRequest("join", { user_id: currentUser?._id })
            socketSendRequest("getPhysicianMsgList", { page: 1, limit: 10 })
            socketSendRequest("getCounts", {})
            socketSendRequest("getCounts", {})
        }
        socket?.on("response", socketListener)
        return () => {
            socket?.off("response", socketListener)
        };
    }, [currentUser])


  const getProfileDetails = async ()=>{
    const { data,success } = await mainGetServices(GET_PROFILE_DATA, {});
    if(success){
      localStorage.setItem("user", Encryption(data));
      localStorage.setItem("id", data._id)
      dispatch({type:SET_USER_PROFILE,payload:data})
    }
  }

 useEffect(()=>{
      if(Cookies.get('x-access-token_phy')){
        setToken(Cookies.get('x-access-token_phy'))
          Cookies.set('x-access-token',Cookies.get('x-access-token_phy'))
        localStorage.setItem("token",Cookies.get('x-access-token_phy'))
      }
     getProfileDetails()
  },[isMenuOpenMenu,Cookies.get('x-access-token')])

  return (
      <div className="layout">
        <Header/>
        <section className={"page_wrapper"}>
          <Sidebar/>
            {children}
        </section>
      </div>
  );
}

export default App;
