import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CREATE_ROOM_TOKEN, GET_SCHEDULE_BYDATE_URL, GET_SCHEDULE_STATUS_URL } from '../../shared/constant';
import { mainGetServices, mainPostServices } from '../../shared/service';
import { CHANGE_SCHEDULE_DATE, DELETE_APPOINMENT, EDIT_APPOINMENT, GET_SCHEDULE_BYDATE, GET_SCHEDULE_STATUS } from '../../store/action';
import { useHistory } from 'react-router-dom';
import Loader from '../Loader'
import enGB from "date-fns/locale/en-GB";
import { formatPhoneNumber } from '../../utils';
import Sweetalert from "sweetalert2";

const AppoinmentBlockTwo = ({ handleAppoinmentModal, handleDeleteAppoinMentModal, startMeetingVideo, className }) => {
    registerLocale('enGB', enGB)
    const dispatch = useDispatch();
    const [scrollView, setScrollView] = useState(true)
    const dateSchedule = useSelector(state => state.scheduleReducer.dateSchedule)
    const calendarScheduleStatus = useSelector(state => state.scheduleReducer.calendarScheduleStatus)
    const selectedScheduleDate = useSelector(state => state.scheduleReducer.selectedScheduleDate)
    const [waitingLoader, setWaitingLoader] = useState(true)
    const history = useHistory()
    useEffect(() => {
        getScheduleStatus()
        getAllScheduleByDate()
    }, [])

    const getAllScheduleByDate = async () => {
        const req = {
            date: moment(selectedScheduleDate).startOf('days').toISOString(),
            page: 1,
            limit: 5
        }
        const { data, success } = await mainPostServices(GET_SCHEDULE_BYDATE_URL, req);
        if (success) {
            dispatch({ type: GET_SCHEDULE_BYDATE, payload: data })
            setWaitingLoader(false)
        } else {
            setWaitingLoader(false)
        }
    }

    const getScheduleStatus = async () => {
        const { data, success } = await mainGetServices(GET_SCHEDULE_STATUS_URL, {});
        if (success) {
            dispatch({ type: GET_SCHEDULE_STATUS, payload: data.dateList })
        }
    }

    const handleDateByChangeSchedule = async (date) => {
        dispatch({ type: CHANGE_SCHEDULE_DATE, payload: moment(date).format("MM/D/YYYY") })
        const req = {
            date: moment(date).startOf('days').toISOString(),
            page: 1,
            limit: 5
        }
        const { data, success } = await mainPostServices(GET_SCHEDULE_BYDATE_URL, req);
        if (success) {
            dispatch({ type: GET_SCHEDULE_BYDATE, payload: data })
        }
    }

    const onPaginate = async (pageNumber) => {
        const req = {
            date: selectedScheduleDate,
            page: pageNumber,
            limit: dateSchedule.limit
        }
        const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_BYDATE_URL, req);
        if (success) {
            dispatch({ type: GET_SCHEDULE_BYDATE, payload: data })
        }
    }
    const handleNext = async () => {
        const req = {
            date: selectedScheduleDate,
            page: dateSchedule.page + 1,
            limit: dateSchedule.limit
        }
        const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_BYDATE_URL, req);
        if (success) {
            dispatch({ type: GET_SCHEDULE_BYDATE, payload: data })
        }
    }
    const handlePrevious = async () => {
        const req = {
            date: selectedScheduleDate,
            page: dateSchedule.page - 1,
            limit: dateSchedule.limit
        }
        const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_BYDATE_URL, req);
        if (success) {
            dispatch({ type: GET_SCHEDULE_BYDATE, payload: data })
        }
    }

    const renderCustomCalendarSchedule = (date) => {
        if (calendarScheduleStatus.length) {
            let data = calendarScheduleStatus.filter(item => moment(item?.date).format("MM/D/YYYY") === moment(date).format("MM/D/YYYY"))
                .reduce((acc, cur) => { return (cur?.status === 'all' || cur?.status === 'accepted') ? { ...acc, acc: 1 } : { ...acc, rej: 1 } }, {});

            if(moment().format("MM/D/YYYY") === moment(date).format("MM/D/YYYY")){
                return ''
            }
            if (data?.acc === 1 && data?.rej === 1) {
                if (moment().format("MM/D/YYYY") === moment(date).format("MM/D/YYYY")) {
                    return 'custom-Pending-confirmed custom_select_tag'
                } else {
                    return 'custom-Pending-confirmed'
                }
            } else if (data?.rej === 1) {
                if (moment().format("MM/D/YYYY") === moment(date).format("MM/D/YYYY")) {
                    return 'custom-pending custom_select_tag'
                } else {
                    return 'custom-pending'
                }
            } else if (data?.acc === 1) {
                if (moment().format("MM/D/YYYY") === moment(date).format("MM/D/YYYY")) {
                    return 'custom-confirmed custom_select_tag'
                } else {
                    return 'custom-confirmed'
                }
            }
        }
    }

    const wantsToChangeAppointMeant = (date) => {
        handleAppoinmentModal(true)
        dispatch({ type: EDIT_APPOINMENT, payload: date })
    }

    const wantsToCancelAppointMeant = (id) => {
        handleDeleteAppoinMentModal(true)
        dispatch({ type: DELETE_APPOINMENT, payload: id })
    }

    const makeVideoCall = async (schedule_id) => {
        const { data, success, message } = await mainPostServices(CREATE_ROOM_TOKEN, { schedule_id });
        if (success) {
            localStorage.setItem('video_token', data?.token)
            localStorage.setItem("room", JSON.stringify(data?.roomData))
            history.push('/video-call')
        } else {
            Sweetalert.fire({ text: message })
        }
    }


    const TimeDate = (date) => {
        let now = new Date();
        let end = new Date(date);
        var seconds = Math.round(now - end) / 1000
        return Math.abs(Math.round(seconds)) <= 300
    }

    const pastTime = (date) => {
        let now = new Date();
        let end = new Date(date);
        return now.getTime() > end.getTime()
    }

    const chatListScroll = async (event) => {
        let objDiv = document.querySelector(".schedule_table_block");
        if (objDiv.offsetHeight + objDiv.scrollTop >= objDiv.scrollHeight && scrollView) {
            if (dateSchedule.dateWiseScheduleList.length !== dateSchedule.totalResults) {
                setScrollView(false)
                const req = {
                    date: selectedScheduleDate,
                    page: dateSchedule.page,
                    limit: dateSchedule.limit + 5
                }
                const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_BYDATE_URL, req);
                if (success) {
                    dispatch({ type: GET_SCHEDULE_BYDATE, payload: data })
                }
            }
        }
    }

    return (
        <div className={`schedule_appoinment--block2 ${className}`}>
            <div className="calander">
                <div className='date_picker_block'>
                    <DatePicker
                        selected={new Date()}
                        onChange={(date) => handleDateByChangeSchedule(date)}
                        inline
                        dateFormat="MM d"
                        popperClassName="some-custom-class"
                        dayClassName={date => renderCustomCalendarSchedule(date)}
                        adjustDateOnChange
                        locale="enGB"
                    />
                    <p className='time'>{moment(selectedScheduleDate).format("MMM D")}</p>
                </div>
                <div className='check_appoinemt_block'>
                    <p className='pending'>Pending</p>
                    <p className='confirmed'>confirmed</p>
                </div>
            </div>
            <div className="schedule_appoinment_list_part">
                <div className='schedule_table_block' onScroll={chatListScroll}>
                    <table className="schedule_appoinment_list">
                        <thead className="schedule_appoinment_list_thead">
                            <tr className="upcoming_schedule_appoinment_list_head" >
                                <th className="upcoming_appoinment_time">Time</th>
                                <th className="upcoming_appoinment_patient">Patient</th>
                                <th className="upcoming_appoinment_email">Email/Phone</th>
                            </tr>
                        </thead>

                        <tbody className="upcoming_appoinment_databox">
                            {waitingLoader ?
                                <tr>
                                    <td className='td_loader'>
                                        <div className='spin_loder'>
                                            <Loader className="data_load" />
                                        </div>
                                    </td>
                                </tr>
                                :
                                dateSchedule.dateWiseScheduleList.length > 0 ?
                                    dateSchedule.dateWiseScheduleList.map((item, i) => {
                                        return (
                                            <Fragment key={i}>
                                                <tr className="patient_info" key={i}>
                                                    <td className="timediv">  <p className={`time ${item?.status === 'accepted' ? "blue_dot" : "red_dot"} `}> {moment.utc(item?.date).local().format("hh:mm A")} ({item?.callLength + "m"})</p>    </td>
                                                    <td className="patient_name text_capitalize">{item?.patient_id?.first_name + " " + item?.patient_id?.last_name}</td>
                                                    <td className="patient_id">

                                                        <h3 className={`schedulePatients_status ${item?.status === 'pending' && item?.changeRequest === 'pending' ? "request_chng" : item?.status === 'pending' && item?.changeRequest === 'rejected' ? "request_chng" : item?.status === 'pending' ? "pending" : ""}`}>
                                                            {item?.status === 'pending' && item?.changeRequest === 'pending' ? "Requested Change" : item?.status === 'pending' && item?.changeRequest === 'rejected' ? "Decline" : item?.status === 'pending' ? "Pending" : ""}
                                                        </h3>
                                                        <p>{item?.patient_id?.email}</p> <p>{formatPhoneNumber(item?.patient_id?.phone)}</p>
                                                    </td>
                                                </tr>
                                                <tr><td colSpan={3} className="comman_width"><span className='patient_text'>{item?.note}</span></td></tr>
                                                <tr><td colSpan={3} className="td_cancel_change">
                                                    {/* request_chng */}
                                                    <p className="await_msg comman_width">{item?.status === 'pending' && !item?.changeRequest ? "Awaiting confirmation" : ""}</p>
                                                    <div className="cancel_change">
                                                        <p className="cancel" onClick={() => wantsToCancelAppointMeant(item?._id)}>CANCEL</p>
                                                        {item?.status === 'pending' && item?.changeRequest === 'pending' && <p className="change" onClick={() => wantsToChangeAppointMeant(item)}> CHANGE</p>}
                                                        {item?.status === 'accepted' && <p className="change" onClick={() => wantsToChangeAppointMeant(item)}> CHANGE</p>}
                                                        {/*{item?.status === 'accepted' && <p className="change" onClick={() => wantsToChangeAppointMeant(item)}>change</p>}*/}
                                                    </div>
                                                </td>
                                                </tr>
                                                <tr>
                                                    {
                                                        // moment(moment.utc(item.date).local()).isSame(moment(), 'day') && (TimeDate(item?.date) >= 0 && TimeDate(item?.date) <= 5) ?
                                                        (moment.utc(item.date).local()).isSame(moment(), 'day') && TimeDate(item?.date) <= 5 ?
                                                            <div className=" tr_start_btn">
                                                                {(item?.status === 'accepted') && <button className="btn btn_primary start" onClick={() => makeVideoCall(item?._id)}>Start</button>}
                                                            </div>
                                                            : moment().isBefore(moment.utc(item.date).local()) &&
                                                            <div className=" tr_start_btn">
                                                                {(item?.status === 'accepted') && <button className="btn btn_default start" onClick={() => startMeetingVideo(true)}>Start</button>}
                                                            </div>
                                                    }
                                                </tr>
                                            </Fragment>
                                        )
                                    })
                                    :
                                    <tr><td colSpan={9}><div className="fullWidth text_center no_data_block" >No Data Found</div></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                {/* <Pagination
                    page={dateSchedule.page}
                    limit={dateSchedule.limit}
                    totalResults={dateSchedule.totalResults}
                    paginate={onPaginate}
                    next={handleNext}
                    prev={handlePrevious}
                /> */}
            </div>
        </div>
    )
}

export default AppoinmentBlockTwo
