import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const PaginationComponent = ({totalResults,page,limit,paginate,next,prev}) => {

    const dispatch =  useDispatch()
    const pageNumbers = [];
    const [pageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

    for (let i = 1; i <= Math.ceil(totalResults / limit); i++) {
        pageNumbers.push(i);
    }

    // const [page, setPage] = useState(1)


    const onPaginate = async (pageNumber) => {
        // setCurrentPage(pageNumber);
        // setPage(pageNumber)
        paginate(pageNumber)
       
    };


    const onNext = async () => {
        // setPage(page + 1);
        next()
        if (page + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
          }
    };

    const onPrevious = async () => {
        // setPage(page - 1);
        prev()
        if ((page - 1) % pageNumberLimit === 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
          }
    };

    const lastPage = pageNumbers[pageNumbers.length - 1]

    let pageIncrementBtn = null;
    if (pageNumbers.length > maxPageNumberLimit) {
      pageIncrementBtn = <li className="pagination-item dots" onClick={onNext}>&#8230;</li>
    }
  
    let pageDecrementBtn = null;
    if (minPageNumberLimit  >= 1) {
      pageDecrementBtn = <li className="pagination-item dots" onClick={onPrevious}>&#8230;</li>
    }

  return (
    pageNumbers.length > 0 &&
    <ul className={`pagination-container pagination-bar`}>
        <li
            className={`pagination-item ${page === 1 ? "disabled" : ''}`}
            onClick={onPrevious}
        >
            <div className="arrow left" />
        </li>
        {pageDecrementBtn}
        {/* <li className="pagination-item dots">&#8230;</li> */}
        {pageNumbers.map((pg,i) => {
           if(pg < maxPageNumberLimit + 1 && pg > minPageNumberLimit) {
            return(
                <li
                    key={i}
                    className={`pagination-item ${pg === page ? "selected" : ''}`}
                    onClick={() => onPaginate(pg)}
                >
                    {pg}
                </li>
            )
           }
           else {
            return null;
          }
            
        })}
        {pageIncrementBtn}
        <li
            className={`pagination-item ${page === lastPage ? 'disabled' : ''}`}
            onClick={onNext}
        >
            <div className="arrow right" />
        </li>
    </ul>
  )
}

export default PaginationComponent;
