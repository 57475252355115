import React, { Fragment, useEffect, useRef, useState } from 'react'
import search_icon from "../../assets/images/Search_icon.png";
import cross_icon from "../../assets/images/cross-icon.png";
import { Modal, ModalBody } from 'reactstrap';
import DatePicker from 'react-datepicker'
import SimpleReactValidator from 'simple-react-validator';
import {mainGetServices, mainPostServices, socket, socketSendRequest} from '../../shared/service';
import {CREATE_SCHEDULE_PATIENTS_URL,CREATE_APOPOINMENT_URL,GET_SCHEDULE_BYDATE_URL, GET_SCHEDULE_STATUS_URL,GET_SCHEDULE_URL,UPDATE_APOPOINMENT_URL, GET_AVAILABLE_MIUTES_URL, REQUEST_SCHEDULE_MINUTE_URL} from '../../shared/constant';
import { useDispatch } from 'react-redux';
import {
        APPOINMENT_SCHEDULE_PATIENTS,
        GET_AVAILABLE_MIUTES,
        GET_SCHEDULE,
        GET_SCHEDULE_BYDATE,
        GET_SCHEDULE_STATUS,
        PHYSICIAN_SCHEDULE_COUNT,
} from '../../store/action';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import Sweetalert from 'sweetalert2';
import Loader from '../Loader';
import RequestChangeModal from './RequestChangeModal';
import { currentTime, generateAvatar } from "../../utils";

const AddAppointment = ({ appoinmentModal, handleAppoinmentModal }) => {

        const dispatch = useDispatch()
        const allPatients = useSelector(state => state.scheduleReducer.patients)
        const { availableMinutes, isUnlimited } = useSelector(state => state.scheduleReducer.reqMinutes)

        const editAppoinment = useSelector(state => state.scheduleReducer.editAppoinment)
        const filters = useSelector(state => state.scheduleReducer.filters)
        const selectedScheduleDate = useSelector(state => state.scheduleReducer.selectedScheduleDate)
        const [searchPatients, setSearchPatients] = useState('')
        const [requestModal, setRequestModal] = useState(false)
        const [appendPatients, setAppendPatients] = useState(false)
        const [requestMinutesTo, setRequestMinutesTo] = useState(null)


        const [, updateState] = useState();
        const forceUpdate = React.useCallback(() => updateState({}), []);
        const simpleValidator = useRef(new SimpleReactValidator())
        const [appointmentState, setAppointmentState] = useState({
                patient_id: '',
                date: null,
                startAt: null,
                callLength: '45',
                note: '',
                caregivers: []
        })
        const [btnLoading, setbtnLoading] = useState(false)
        const [reqChangeModal, setReqChangeModal] = useState(false)

        useEffect(() => {
                if (appoinmentModal) {
                        getAllPatients()
                        getAvailableMinutes()
                }
        }, [dispatch, appoinmentModal, isUnlimited])

        useEffect(() => {
                if (!editAppoinment && appoinmentModal) {
                        setAppointmentState({
                                ...appointmentState,
                                patient_id: '',
                                date: null,
                                startAt: null,
                                callLength: '45',
                                note: '',
                                caregivers: []
                        })
                }
                if (editAppoinment && appoinmentModal) {
                        setAppointmentState({
                                ...appointmentState,
                                date:  new Date(editAppoinment?.date),
                                callLength: String(editAppoinment?.callLength),
                                note: editAppoinment?.note,
                                patient_id: editAppoinment?.patient_id?._id,
                                startAt: moment.utc(editAppoinment?.date)._d
                        })
                }
        }, [editAppoinment, appoinmentModal])

        const getAllPatients = async () => {
                const req = {
                        page: allPatients.page,
                        limit: allPatients.limit
                }
                const { data, success } = await mainPostServices(CREATE_SCHEDULE_PATIENTS_URL, req);
                if (success) {
                        dispatch({ type: APPOINMENT_SCHEDULE_PATIENTS, payload: data })
                }
        }

        const handleInputChange = (e) => {
                const { name, value } = e.target
                setAppointmentState({
                        ...appointmentState,
                        [name]: value
                })
        }

        const handleDateChange = (date) => {
                if(new Date(date).getDate() === new Date().getDate()){
                        setAppointmentState({
                                ...appointmentState,
                                startAt:null,
                                date
                        })
                }else{
                        setAppointmentState({
                                ...appointmentState,
                                date
                        })
                }
        }

        const handleTimeChange = (date) => {
                setAppointmentState({
                        ...appointmentState,
                        startAt: date
                })
        }

        const handleSubmit = e => {
                e.preventDefault();
                if (simpleValidator.current.allValid()) {
                        saveAppointMent(appointmentState)
                } else {
                        simpleValidator.current.showMessages();
                        forceUpdate()
                }
        }

        const handleSearchPatients = async (e) => {
                setSearchPatients(e.target.value)
                const req = {
                        name: e.target.value,
                        page: 1,
                        limit: 5
                }
                const { data, success } = await mainPostServices(CREATE_SCHEDULE_PATIENTS_URL, req);
                if (success) {
                        dispatch({ type: APPOINMENT_SCHEDULE_PATIENTS, payload: data })
                }
        }

        const handlePatientsSelect = (e, item) => {
                if (e.target.checked) {
                        setAppointmentState({
                                ...appointmentState,
                                patient_id: item._id
                        })
                } else {
                        setAppointmentState({
                                ...appointmentState,
                                patient_id: ''
                        })
                }
        }

        const getAllSchedule = async () => {
                const req = {
                        status: filters.status,
                        page: 1,
                        limit: 5
                }
                Object.assign(req,{name: filters.search})
                const { data, success } = await mainPostServices(GET_SCHEDULE_URL, req);
                if (success) {
                        dispatch({ type: GET_SCHEDULE, payload: data })
                }
        }

        const getAvailableMinutes = async () => {
                const { data, success } = await mainGetServices(GET_AVAILABLE_MIUTES_URL, {});
                if (success) {
                        dispatch({ type: GET_AVAILABLE_MIUTES, payload: data })
                }
        }

        const getAllScheduleByDate = async () => {
                const req = {
                        date: moment(selectedScheduleDate).format("MM/D/YYYY"),
                        page: 1,
                        limit: 5
                }
                const { data, success } = await mainPostServices(GET_SCHEDULE_BYDATE_URL, req);
                if (success) {
                        dispatch({ type: GET_SCHEDULE_BYDATE, payload: data })
                }
        }


        const saveAppointMent = async (state) => {
                setbtnLoading(true)
                let date = moment(moment(state.date).format('YYYY-MM-DD') + " " + moment(state.startAt).format('HH:mm:ss')).format()

                const req = {
                        ...state,
                        date: moment.utc(date).format(),
                        startAt: state.startAt ? Object.assign({ hour: moment.utc(appointmentState?.startAt).hours(), min: moment.utc(appointmentState?.startAt).minutes(), sec: moment.utc(appointmentState?.startAt).seconds() }) : Object.assign({ hour: moment.utc(editAppoinment?.startAt).hours(), min: moment.utc(editAppoinment?.startAt).minutes(), sec: moment.utc(editAppoinment?.startAt).seconds() }),
                        callLength: Number(state.callLength)
                }

                if (state.note) Object.assign(req, { note: state.note })
                if (editAppoinment) {
                        Object.assign(req, { schedule_id: editAppoinment?._id });
                        delete req.patient_id;
                        delete req.caregivers
                }

                const { message, success } = await mainPostServices(editAppoinment ? UPDATE_APOPOINMENT_URL : CREATE_APOPOINMENT_URL, req);
                if (success) {
                        if(editAppoinment){
                                Sweetalert.fire({
                                        title: 'Changed Appointment Pending Patient\'s Confirmation',
                                        html:`Your changed appointment has been sent to patient for confirmation and will appear in <b>Pending</b> tab. Once patient accepts the new appointment date/time, it will appear in your <b>Confirmed</b> tab.`
                                })
                        }else{
                                Sweetalert.fire({
                                        title: 'Success',
                                        text: `Appointment ${editAppoinment ? "Update" : "Added"}  Successfully`
                                })
                                socketSendRequest("getCounts", {})
                        }

                        setbtnLoading(false)
                        resetForm()
                        await getScheduleStatus()
                        setAppointmentState({
                                ...appointmentState,
                                patient_id: '',
                                date: null,
                                startAt: null,
                                callLength: '',
                                note: '',
                                caregivers: []
                        })
                        await getAvailableMinutes()
                        //dispatch({ type: GET_AVAILABLE_MIUTES, payload: { callLength: appointmentState?.callLength } })
                        await getAllSchedule()
                        await getAllScheduleByDate()
                        
                } else {
                        Sweetalert.fire({ title: 'Info', text: message })
                        setbtnLoading(false)
                }
        }

        const getScheduleStatus = async () => {
                const { data, success } = await mainGetServices(GET_SCHEDULE_STATUS_URL, {});
                if (success) {
                        dispatch({ type: GET_SCHEDULE_STATUS, payload: data.dateList })
                }
        }
        const clearAppointmentForm = () => {
                // e.preventDefault()
                setSearchPatients('')
                setAppointmentState({
                        ...appointmentState,
                        patient_id: '',
                        date: null,
                        startAt: null,
                        callLength: '',
                        note: '',
                        caregivers: []
                })
                simpleValidator.current.hideMessages()
                getAllPatients()
        }

        const resetForm = () => {
                setSearchPatients('')
                handleAppoinmentModal(false)
                simpleValidator.current.hideMessages()
                // getAllPatients()
        }


        const onPaginate = async (pageNumber) => {
                const req = {
                        page: pageNumber,
                        limit: allPatients.limit
                }
                if (searchPatients) Object.assign(req, { name: searchPatients })
                const { data, success } = await mainPostServices(CREATE_SCHEDULE_PATIENTS_URL, req);
                if (success) {
                        dispatch({ type: APPOINMENT_SCHEDULE_PATIENTS, payload: data })
                }
        }
        const handleNext = async () => {
                const req = {
                        page: allPatients.page + 1,
                        limit: allPatients.limit
                }
                if (searchPatients) Object.assign(req, { name: searchPatients })
                const { data, success } = await mainPostServices(CREATE_SCHEDULE_PATIENTS_URL, req);
                if (success) {
                        dispatch({ type: APPOINMENT_SCHEDULE_PATIENTS, payload: data })
                }
        }
        const handlePrevious = async () => {
                const req = {
                        page: allPatients.page - 1,
                        limit: allPatients.limit
                }
                if (searchPatients) Object.assign(req, { name: searchPatients })
                const { data, success } = await mainPostServices(CREATE_SCHEDULE_PATIENTS_URL, req);
                if (success) {
                        dispatch({ type: APPOINMENT_SCHEDULE_PATIENTS, payload: data })
                }
        }

        const handleReqChangeModal = (state) => {
                setReqChangeModal(state)
        }

        const wantsToRequestMinutes = async () => {
                const { message, success, data } = await mainGetServices(REQUEST_SCHEDULE_MINUTE_URL, {});
                if (success) {
                        getAvailableMinutes();
                        setRequestModal(true);
                        setRequestMinutesTo(data)
                        // Sweetalert.fire({
                        //         title: 'Minutes Request Sent',
                        //         html: `Your request to add more conference minutes <br/>
                        // has been sent to your organization’s <br/> 
                        // primary contact <b>${data.first_name + " " + data.last_name}</b>`
                        // })
                } else {
                        Sweetalert.fire({
                                title: 'Info',
                                text: message
                        })
                }
        }

        const listInnerRef = useRef();
        const patientListScroll = async () => {
                if (listInnerRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
                if(scrollTop === 0){
                        setAppendPatients(false)
                        const req = {
                                page: allPatients.page,
                                limit: 10
                        }
                        const { data, success } = await mainPostServices(CREATE_SCHEDULE_PATIENTS_URL, req);
                        if (success) {
                                dispatch({ type: APPOINMENT_SCHEDULE_PATIENTS, payload: data })
                        }
                }
                if (scrollTop + clientHeight === scrollHeight && allPatients?.totalResults > allPatients?.list?.length) {
                        setAppendPatients(true)
                        const req = {
                                page: allPatients.page,
                                limit: allPatients.limit + 10
                        }
                        const { data, success } = await mainPostServices(CREATE_SCHEDULE_PATIENTS_URL, req);
                        if (success) {
                                dispatch({ type: APPOINMENT_SCHEDULE_PATIENTS, payload: data })
                        }
                }
                }
        }

        useEffect(() => {
                socket?.on("response", socketListener)
                return () => {
                        socket?.off("response", socketListener)
                };
        }, [])

        const socketListener = (res) => {
                switch (res.evt) {
                        case 'getCounts':
                                if (res.data) {
                                        dispatch({ type: PHYSICIAN_SCHEDULE_COUNT, payload: res.data })
                                }
                                break;
                        default:
                                break;
                }
        }

        return (
                <Fragment>
                        <Modal closeTimeoutMS={500} isOpen={appoinmentModal} className='main_comman_modal appoinment-modal_block' >
                                <div className='close_modal'>
                                        <img src={cross_icon} alt='cross' onClick={resetForm} />
                                </div>
                                <ModalBody>
                                        <div className="modal_dialog">
                                                <div className='main_addappoinment'>
                                                        <h4>{editAppoinment ? "Edit Appointment" : "Add Appointment"}</h4>
                                                        <div className='sub_appoinment_block'>
                                                                <div className='addappoinment_block'>
                                                                        {/* date and time */}
                                                                        <div className="form_row appoinment_time">
                                                                                <div className="form_group">
                                                                                        <label>Date</label>
                                                                                        <span className='date_block'>
                                                                                                <DatePicker
                                                                                                        className="form_control"
                                                                                                        selected={appointmentState.date}
                                                                                                        onChange={handleDateChange}
                                                                                                        dateFormat="MMM d, yyyy"
                                                                                                        minDate={new Date()}
                                                                                                        placeholderText="Select Date"
                                                                                                />
                                                                                        </span>
                                                                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                                                                                {simpleValidator.current.message('date', appointmentState.date, 'required')}
                                                                                        </div>
                                                                                </div>
                                                                                <div className="form_group">
                                                                                        <label>Starts At</label>
                                                                                        <span className='time_block'>
                                                                                                <DatePicker
                                                                                                        className="form_control"
                                                                                                        selected={appointmentState?.startAt}
                                                                                                        onChange={handleTimeChange}
                                                                                                        showTimeSelect
                                                                                                        showTimeSelectOnly
                                                                                                        timeCaption="Start At"
                                                                                                        dateFormat="h:mm aa"
                                                                                                        timeIntervals={1}
                                                                                                        minTime={moment(moment.utc(appointmentState.date).local()).isSame(moment(), 'day') ? new Date() : new Date(appointmentState.date)}
                                                                                                        maxTime={moment().endOf('days').toDate()}
                                                                                                />
                                                                                                <span className='pst'>{`${currentTime.format("z")}`}</span>
                                                                                        </span>


                                                                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                                                                                {simpleValidator.current.message('startAt', appointmentState.startAt, 'required')}
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                        {/* call length */}
                                                                        <div className='main_call_length'>
                                                                                <p>Call Length</p>
                                                                                <div className='request_min_block min_block'>
                                                                                        <div className='radio_buttons_block'>
                                                                                                <div className="min_block">
                                                                                                        <input type="radio" disabled={Number(availableMinutes) < 30 && !isUnlimited} name="callLength" value={30} checked={appointmentState.callLength === '30'} onChange={handleInputChange} />
                                                                                                        <span className="check" />
                                                                                                        <label>30 min</label>
                                                                                                </div>
                                                                                                <div className="min_block">
                                                                                                        <input type="radio" disabled={Number(availableMinutes) < 45 && !isUnlimited} name="callLength" onChange={handleInputChange} value={45} checked={availableMinutes < 45 && !isUnlimited ? false : appointmentState.callLength === '45'} />
                                                                                                        <span className="check" />
                                                                                                        <label>45 min</label>
                                                                                                </div>
                                                                                        </div>

                                                                                        {(Number(availableMinutes) > 0 || Number(availableMinutes) === 0) && !isUnlimited &&
                                                                                                <div className='available_min_block'>
                                                                                                        <span>(available minutes: {Math.floor(Number(availableMinutes))})</span>
                                                                                                </div>
                                                                                        }
                                                                                        {
                                                                                                isUnlimited &&
                                                                                                <div className='available_min_block'>
                                                                                                        <span>(available minutes: {'Unlimited'})</span>
                                                                                                </div>
                                                                                        }

                                                                                        {!isUnlimited && Number(availableMinutes) < 45 &&
                                                                                                <div className='req_min_btn'>
                                                                                                        <button className='btn btn_primary' onClick={() => wantsToRequestMinutes()}>Request Minutes</button>
                                                                                                </div>
                                                                                        }

                                                                                </div>
                                                                                <div className="invalid-feedback pl-4 d-block errorMsg">
                                                                                        {simpleValidator.current.message('callLength', appointmentState.callLength, 'required')}
                                                                                </div>
                                                                        </div>
                                                                        {/* note textarea */}
                                                                        <div className='main_note_block form_group'>
                                                                                <textarea className='form_control' name='note' placeholder='Note' value={appointmentState.note} onChange={handleInputChange} />
                                                                                <span>Optional</span>
                                                                        </div>
                                                                        {/* select Patient */}
                                                                        <div className='select_patient_block'>
                                                                                <h6>Select Patient</h6>
                                                                                {
                                                                                        editAppoinment ?
                                                                                                <>
                                                                                                        <div className={'current_patient_box'}>
                                                                                                                <div className={'main_cp_list'}>
                                                                                                                        <div className='main_cp_list_col' >
                                                                                                                                <div className='cp_profile_block'>
                                                                                                                                        <div className='profile'>
                                                                                                                                                <img src={(!editAppoinment?.patient_id?.profile || editAppoinment?.patient_id?.profile === null || editAppoinment?.patient_id?.profile === "NA" || editAppoinment?.patient_id?.profile === "") ? generateAvatar(editAppoinment?.patient_id?.first_name + " " + editAppoinment?.patient_id?.last_name) : editAppoinment?.patient_id?.profile} alt="" />
                                                                                                                                        </div>
                                                                                                                                        <span className='cp_name'>{editAppoinment?.patient_id?.first_name + " " + editAppoinment?.patient_id?.last_name}</span>
                                                                                                                                </div>
                                                                                                                                <div className='cp_checkbox'>
                                                                                                                                        <div className="form_group checkbox selectall_checkbox">
                                                                                                                                                <input
                                                                                                                                                        type="checkbox"
                                                                                                                                                        onChange={(e) => handlePatientsSelect(e, editAppoinment?.patient_id)}
                                                                                                                                                        checked={appointmentState.patient_id === editAppoinment?.patient_id._id}
                                                                                                                                                />
                                                                                                                                                <span className="checkbox_clone" />
                                                                                                                                        </div>
                                                                                                                                </div>
                                                                                                                        </div>
                                                                                                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                                                                                                                {simpleValidator.current.message('patients', appointmentState.patient_id, 'required')}
                                                                                                                        </div>
                                                                                                                </div>

                                                                                                        </div>
                                                                                                </>
                                                                                                :
                                                                                                <div className='current_patient_box'>
                                                                                                        {/* current patients header */}
                                                                                                        <div className='current_patient_header'>
                                                                                                                <div className="form_group search">
                                                                                                                        <input
                                                                                                                                type="text"
                                                                                                                                placeholder="Search"
                                                                                                                                className="form_control search_input"
                                                                                                                                name="search"
                                                                                                                                onChange={handleSearchPatients}
                                                                                                                                value={searchPatients}
                                                                                                                        />
                                                                                                                        <span className="search_icon">
                                                                                                                                <img src={search_icon} alt="Search_Icon" />
                                                                                                                        </span>
                                                                                                                </div>
                                                                                                                <h6>Current Patients</h6>
                                                                                                        </div>
                                                                                                        {/* current patients list */}
                                                                                                        <div className='main_cp_list' ref={listInnerRef} onScroll={patientListScroll}>
                                                                                                                {allPatients?.list?.length > 0 ?
                                                                                                                        allPatients?.list?.map((item, i) => {
                                                                                                                
                                                                                                                                return (
                                                                                                                                        <>
                                                                                                                                                <div className='main_cp_list_col' key={i} >
                                                                                                                                                        <div className='cp_profile_block'>
                                                                                                                                                                <div className='profile'>
                                                                                                                                                                        <img src={(!item?.profile || item?.profile === null || item?.profile === "NA" || item?.profile === "") ? generateAvatar(item.first_name + " " + item.last_name) : item?.profile} alt="" />
                                                                                                                                                                </div>
                                                                                                                                                                <span className='cp_name'>{item.first_name + " " + item.last_name}</span>
                                                                                                                                                        </div>
                                                                                                                                                        <div className='cp_checkbox'>
                                                                                                                                                                <div className="form_group checkbox selectall_checkbox">
                                                                                                                                                                        <input
                                                                                                                                                                                type="checkbox"
                                                                                                                                                                                onChange={(e) => handlePatientsSelect(e, item)}
                                                                                                                                                                                checked={appointmentState.patient_id === item._id}
                                                                                                                                                                        />
                                                                                                                                                                        <span className="checkbox_clone" />
                                                                                                                                                                </div>
                                                                                                                                                        </div>
                                                                                                                                                </div>


                                                                                                                                        </>
                                                                                                                                )
                                                                                                                        })
                                                                                                                        :
                                                                                                                        <div className='nodata'>No Data Found</div>
                                                                                                                }
                                                                                                                <div className="invalid-feedback pl-4 d-block errorMsg">
                                                                                                                        {simpleValidator.current.message('patients', appointmentState.patient_id, 'required')}
                                                                                                                </div>
                                                                                                        </div>
                                                                                                </div>
                                                                                }
                                                                        </div>
                                                                        <div className="form_group d_flex justify_content_center form_action appoinment_btn">
                                                                                <button className="btn btn_default" type="reset" onClick={clearAppointmentForm}>Clear</button>
                                                                                <button className="btn btn_primary" type="button" onClick={(e) => handleSubmit(e)} disabled={(!isUnlimited && Number(availableMinutes) === 0) || btnLoading}>
                                                                                        {btnLoading ?
                                                                                                <Loader color="primary" size="sm" />
                                                                                                :
                                                                                                "Submit"
                                                                                        }
                                                                                </button>
                                                                        </div>

                                                                </div>

                                                        </div>
                                                </div>

                                        </div>
                                </ModalBody>
                        </Modal>
                        <RequestChangeModal requestModal={requestModal} setRequestModal={setRequestModal} requestMinutesTo={requestMinutesTo} />
                </Fragment>
        )
}

export default AddAppointment
