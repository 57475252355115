import React, { Fragment, useState, useRef } from 'react';
import logo from '../../assets/images/header_logo.svg';
import icone_hide_pass from "../../assets/images/eye_hide.svg";
import icon_open_password from "../../assets/images/eye_show.svg";
import SimpleReactValidator from 'simple-react-validator';
import { mainPostServices } from '../../shared/service';
import { RESET_PASSWORD_URL } from '../../shared/constant';
import Sweetalert from "sweetalert2";
import { useHistory } from 'react-router-dom';
import Loader from '../Loader';

const ResetPasswordComponent = () => {
    const [passwordState, setPasswordState] = useState({
        password: '',
        confirmPassword: ''
    })
    const history = useHistory()
    const simpleValidator = useRef(new SimpleReactValidator({
        validators: {
            password: {
                message: "Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit.",
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val,/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
                },
                required: true
            },
            confirmPassword:{
                message: "Confirm Password & Password Does not Match!!",
                rule: (val, params, validator) => {
                    return passwordState?.password !== passwordState?.confirmPassword
                },
                required: true
            }
        }
    }))
    const [, updateState] = useState();
    const [btnLoading, setBtnLoading] = useState(false)
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const id = params.get('id')

    const [viewPassword, setviewPassword] = useState({
        passwordShow: false,
        verifyPasswordShow: false
    })



    const handleInputChange = (e) => {
        const { name, value } = e.target
        setPasswordState({
            ...passwordState, [name]: value
        })
    }

    const togglePassWordHideShow = (name, value) => {
        setviewPassword({
            ...viewPassword, [name]: value
        })
    }


    const doPasswordReset = async (req) => {
        // Object.assign(req, { token: token })
        // Object.assign(req, { id: id })
        // delete req.confirmPassword
        const reqData = {
            id: id,
            token: token,
            password:req?.password
        }
        const { message, success } = await mainPostServices(RESET_PASSWORD_URL, reqData);
        if (success) {
            simpleValidator.current.hideMessages();
            forceUpdate()
            setPasswordState({
                ...passwordState,
                password: '',
                confirmPassword: ''
            })
            setviewPassword({
                ...viewPassword,
                passwordShow: false,
                verifyPasswordShow: false
            })
            Sweetalert.fire({
                title: 'Success',
                text: message
            })
            setBtnLoading(false)
            history.push("/login")
        } else {
            Sweetalert.fire({
                title: 'Info',
                text: message
            })
            setBtnLoading(false)
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            doPasswordReset(passwordState)
            setBtnLoading(true)
        } else {
            simpleValidator.current.showMessages();
            forceUpdate()
        }
    }

    const clearForm = () => {
        setPasswordState({
            ...passwordState,
            password: '',
            confirmPassword: ''
        })
        setviewPassword({
            ...viewPassword,
            passwordShow: false,
            verifyPasswordShow: false
        })
        simpleValidator.current.hideMessages();
        forceUpdate()
    }

    return (
        <Fragment>
            <header/>
      
            <div className='content_wrapper resetpssword_main'>
            <div className='setting_form resetpsw_sub_block'>
                    <div className="header px_auto">
                        <div className="logo"><img src={logo} alt="logo_img" /></div>
                    </div>
                <div className='resetpsw_form_block'>
                      <h4>Create New Password</h4>
                    <form
                        onSubmit={handleSubmit}
                        onReset={clearForm}
                        className="setting_form resetpsw_form"
                    >
                        <div className="form_group">
                            <input
                                type={viewPassword.passwordShow ? "text" : "password"}
                                placeholder="New Password"
                                className="form_control"
                                name="password"
                                autoComplete="off"
                                onChange={handleInputChange}
                                value={passwordState.password}
                            />
                            <span className={`password_icon ${viewPassword.passwordShow ? 'icon_show' : 'icon_hide'}`}><img src={viewPassword.passwordShow ? icon_open_password : icone_hide_pass} alt="" onClick={() => togglePassWordHideShow('passwordShow', !viewPassword.passwordShow)} /></span>
                        </div>
                            <div className="invalid-feedback pl-4 d-block errorMsg">
                                {simpleValidator.current.message('password', passwordState.password, 'required|password')}
                            </div>
                        <div className="form_group">
                            <input
                                type={viewPassword.verifyPasswordShow ? "text" : "password"}
                                placeholder="Confirm Password"
                                className="form_control pr_4"
                                name="confirmPassword"
                                autoComplete="off"
                                onChange={handleInputChange}
                                value={passwordState.confirmPassword}
                            />
                            <span className={`password_icon ${viewPassword.verifyPasswordShow ? 'icon_show' : 'icon_hide'}`}><img src={viewPassword.verifyPasswordShow ? icon_open_password : icone_hide_pass} alt="" onClick={() => togglePassWordHideShow('verifyPasswordShow', !viewPassword.verifyPasswordShow)} /></span>
                        </div>
                            <div className="invalid-feedback pl-4 d-block errorMsg">
                                {simpleValidator.current.message('confirmPassword', passwordState.confirmPassword, `required|in:${passwordState.password}`, { messages: { in: 'Confirm Password & Password Does not Match!!' } })}
                            </div>
                        <div className="form_buttons">
                            <button type="reset" className="btn btn_default">Clear</button>
                            <button className="btn btn_primary ml_2" type="submit">
                                {btnLoading ?
                                    <Loader color="primary" size="sm" />
                                    :
                                    "Submit"
                                }
                            </button>
                        </div>
                    </form>
                </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ResetPasswordComponent;
