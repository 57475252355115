import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { authSocketRequest, socketConnect, socketSendRequest } from '../../shared/service'
import { SOCKET_CONNECTION } from '../../store/action'
import MessageHeader from './Header'
import MessageList from './MessageList'
import Cookies from 'js-cookie';

const MessagesComponent = () => {    
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.userDetailsReducer.currentUser)
    const physicianMessage = useSelector(state => state.chatReducer.physicianMessage)
    useEffect(() => {
        socketConnect(dispatch,Cookies.get('x-access-token'), (flag) => {
            if(flag){
              dispatch({type: SOCKET_CONNECTION, payload: flag})
              if(currentUser){
                authSocketRequest("join",{user_id:currentUser?._id})
                socketSendRequest("getPhysicianMsgList",{page:1,limit:10})
                socketSendRequest("getCounts", {})
              }
            }
        })
        const req = {
            page: physicianMessage.page,
            limit: physicianMessage.limit
        }
        socketSendRequest("getPhysicianMsgList", req)
    },[])
    return (
        <div className="clinics msg-list_block">
            <MessageHeader />
            <MessageList />
        </div>
    )
}

export default MessagesComponent
