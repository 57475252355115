import Cookies from "js-cookie";
export const isUserLoggedIn = () => {
    if(Cookies.get('x-access-token')){
        return true
    }else{
        localStorage.clear()
        return false
    }
}

let encKey = 'DFK8s58uWFCF4Vs8NCrgTxfMLwjL9WUy';
const CryptoJS = require('crypto-js'); //we use crypto-js

export const Encryption = (data) => {
    try {
        /* +-------------------------------------------------------------------+
        desc: this function encrypt the data.
        +-------------------------------------------------------------------+ */
        // Encrypt
        return CryptoJS.Rabbit.encrypt(JSON.stringify(data), encKey).toString();
    } catch (e) {
    }
}

export function Decryption(data) {
    try {
        /* +-------------------------------------------------------------------+
            desc: this function decrypt the data.
        +-------------------------------------------------------------------+ */
        // Decrypt
        let bytes = CryptoJS.Rabbit.decrypt(data, encKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (e) {
    }
}


export const formatPhoneNumber = (str) => {
    let value = str;
    if(value){
        value = value
            .replace(/\D+/g, "")
            .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        return value;
    }

};
