import { useEffect } from "react";
import { Link,useLocation } from "react-router-dom";
import { menu } from "./Menu";

const Sidebar = () => {
    const location = useLocation();
    const activeRoute = (routeName) => {
      return location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };

    useEffect(() => {
        var ul = document.querySelector(".list_unstyled");
        var items = ul?.getElementsByTagName("li");
        if(location.pathname === "/messages" || location.pathname === "/chat"){
            items[2].classList.add('active')
        }else{
            items[2].classList.remove('active')
        }
    }, [location.pathname])
    
    return (
        <div className="sidebar">
            <ul className="list_unstyled">
                {menu?.map((menuitem,i) => {
                    return(
                        <Link to={menuitem?.path} key={i}><li  className={activeRoute(menuitem?.path)} ><span>{menuitem?.title}</span></li></Link> 
                    )
                })}
            </ul>
        </div>
    )
};

export default Sidebar