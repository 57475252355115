import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import cross_icon from "../../assets/images/cross-icon.png";
import Sweetalert from 'sweetalert2';
import { DELETE_PATIENT_URL, GET_ALL_PATIENTS_URL, GET_PATIENTS_URL } from '../../shared/constant';
import { mainPostServices } from '../../shared/service';
import { useDispatch } from 'react-redux';
import { GET_PATIENTS, DELETE_PATIENT_DATA } from '../../store/action';

const DeletePatients = ({ deleteModal, handleDeletePatientsModal }) => {

  let url;
  const dispatch = useDispatch()
  const patient_id = useSelector(state => state.patientsReducer.patient_id)
  const allPatients = useSelector(state => state.patientsReducer.allPatients)
  const filters = useSelector(state => state.patientsReducer.filters)
  url = filters.status === 'all' ? GET_ALL_PATIENTS_URL : GET_PATIENTS_URL

  const getAllPatients = async () => {
    const req = {
      page: allPatients.page,
      limit: allPatients.limit
    }
    const { data, success } = await mainPostServices(url, req);
    if (success) {
      dispatch({ type: GET_PATIENTS, payload: data })
    }
  }

  const deletePatients = async () => {
    const { message, success } = await mainPostServices(DELETE_PATIENT_URL, { id: patient_id });
    if (success) {
      Sweetalert.fire({ title: 'Success', text: "Patient Deleted  Successfully" })
      getAllPatients()
      dispatch({ type: DELETE_PATIENT_DATA, payload: patient_id })
      handleDeletePatientsModal(false)
    } else {
      Sweetalert.fire({ title: 'Info', text: message })
    }
  }

  return (
    <Modal isOpen={deleteModal} toggle={() => handleDeletePatientsModal(false)} className='deleteModal main_comman_modal deletePop'>
      <div className='close_modal' onClick={() => handleDeletePatientsModal(false)}>
        <img src={cross_icon} alt='cross' />
      </div>
      <ModalBody>
        <div className="custom_modal text_center">
          <h3 className='mb_5 title'>Delete Patient</h3>
          <p className='mt_3 content'>You are about to delete an active patient from your list. Once you delete the patient, all calendar invites will be removed from your and the patient’s schedule. This change can not be reversed.</p>
          <p className='mt_2 sub_heading'><strong>Do you want to proceed?</strong></p>
          <div className="form_group d_flex justify_content_center form_action mt_4">
            <button className='btn btn_default' onClick={deletePatients}>Yes</button>
            <button className='btn btn_primary ml_2' onClick={() => handleDeletePatientsModal(false)}>No</button>
          </div>
        </div>
      </ModalBody>
    </Modal>

  )
}

export default DeletePatients;
