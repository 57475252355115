import { Modal, ModalBody,  } from "reactstrap";
import ReactCodeInput from 'react-verification-code-input';
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { RESEND_OTP, VERIFY_OTP } from "../../shared/constant";
import { mainPostServices, setToken } from "../../shared/service";
import { Encryption } from "../../shared/function";
import { SET_USER_PROFILE } from "../../store/action";
import Loader from "../Loader";


const AuthenticatePop = ({ authVerifyModal, setAuthVerifyModal, email,counterTime }) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const [valueOTP, setValueOTP] = useState([]);
    const [oTPError, setOTPError] = useState(false)
    const [error, setError] = useState('')
    const [counter, setCounter] = useState(0)
    const [btnLoading, setBtnLoading] = useState(false)
    const ref = useRef()

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer)
    }, [counter])
    
    useEffect(() => {
        if(counterTime > 0){
            setCounter(30)
        }
    }, [counterTime])
    
    const resetOtp = () => {
        ReSendApiHandler({ email: email })
    }

    const ReSendApiHandler = async (req) => {
        ref.current.state.values =Array(5).fill('')
        setValueOTP([])
        setAuthVerifyModal(true)
        setOTPError(false)
        const {  success } = await mainPostServices(RESEND_OTP, req);
        if (success) {
            setCounter(30)
        }
    }

    const clearValue = () => {
        ref.current.state.values =Array(5).fill('')
        setValueOTP([])
        setError('')
        setOTPError(false)
    }

    const AuthenticationVaried = (e) => {
        e.preventDefault()
        let payload = {
            email: email,
            otp: valueOTP
        }
        if (valueOTP.length !== 5) {
            setError('Please Enter OTP')
        } else {
            verifyOTPApiHandler(payload)
            setBtnLoading(true)
        }
    }

    const verifyOTPApiHandler = async (req) => {
        setAuthVerifyModal(true)
        const { data, success } = await mainPostServices(VERIFY_OTP, req);
        if (success) {
            history.push("/signUp-success-landing")
        } else {
            setOTPError(true)
            setBtnLoading(false)
        }
    }

    const onPinComplete = code => {
        setError('')
    };

    return (
        <Modal isOpen={authVerifyModal} toggle={() => setAuthVerifyModal(false)} className='main_comman_modal authenticate_modal'>
            <ModalBody>
                <div className="custom_modal text_center">
                    <h3 className='mb_5'>Authenticate your email</h3>
                    <div className="otpMsg">
                        <form onSubmit={(e) => AuthenticationVaried(e)}>
                            {
                                oTPError ?
                                    <p className='text_red otp_error-msg'>This 5-digit code has either expired or not found in our database. Please click below to resend code</p>
                                    :
                                    <p className='notice'>Please enter the 5-digit code sent to your email for account verification</p>
                            }

                            <div className="form_row mt_2">
                                <div className={'verification_otp'}>
                                <ReactCodeInput
                                        ref={ref}
                                        fields={5}
                                        passwordMask={false}
                                        placeholder={false}
                                        autoFocus={true}
                                        onChange={value => setValueOTP(value)}
                                        onComplete={onPinComplete}
                                        values={valueOTP}
                                />
                                </div>
                            </div>
                            <p onClick={() => resetOtp()} className={counter > !0 ? "disabled resend_color" : "resendcode_msg"}  >Resend Code</p>
                            {counter > 0 &&
                                <div className={'min-block'}>
                                    {`00:${counter}`}
                                </div>
                            }
                            <div className={'errorMsg'}>{
                                error
                            }</div>
                            <div className="form_group d_flex justify_content_center form_action resendcode_btn">
                                <button className='btn btn_default' type={'reset'} onClick={() => clearValue()}>Clear</button>
                                <button className="btn_primary ml_2" type="submit">
                                            {btnLoading ?
                                                    <Loader color="primary" size="sm" />
                                                    :
                                                    "Next"
                                            }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default AuthenticatePop