import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { DELETE_APOPOINMENT_URL, GET_SCHEDULE_BYDATE_URL, GET_SCHEDULE_STATUS_URL, GET_SCHEDULE_URL } from '../../shared/constant';
import { mainGetServices, mainPostServices } from '../../shared/service';
import { GET_SCHEDULE, GET_SCHEDULE_BYDATE, GET_SCHEDULE_STATUS } from '../../store/action';


const DeleteSchedulePop = ({ deleteAppoitMentModal, handleDeleteAppoinMentModal }) => {

    const dispatch = useDispatch()
    const schedule_id = useSelector(state => state.scheduleReducer.schedule_id)
    const filters = useSelector(state => state.scheduleReducer.filters)
    const selectedScheduleDate = useSelector(state => state.scheduleReducer.selectedScheduleDate)

    const getAllSchedule = async () => {
        const req = {
            status: filters.status,
            page: 1,
            limit: 5
        }
        Object.assign(req,{name:filters.search})
        const { data, success } = await mainPostServices(GET_SCHEDULE_URL, req);
        if (success) {
            dispatch({ type: GET_SCHEDULE, payload: data })
        }
    }


    const getAllScheduleByDate = async () => {
        const req = {
            date: moment(selectedScheduleDate).format("MM/D/YYYY"),
            page: 1,
            limit: 5
        }
        const { data, success } = await mainPostServices(GET_SCHEDULE_BYDATE_URL, req);
        if (success) {
            dispatch({ type: GET_SCHEDULE_BYDATE, payload: data })
        }
    }

    const getScheduleStatus = async () => {
        const { data, success } = await mainGetServices(GET_SCHEDULE_STATUS_URL, {});
        if (success) {
            dispatch({ type: GET_SCHEDULE_STATUS, payload: data.dateList })
        }
    }


    const deleteAppoinMent = async () => {
        const req = {
            id: schedule_id
        }
        const { success } = await mainPostServices(DELETE_APOPOINMENT_URL, req);
        if (success) {
            getAllSchedule()
            getScheduleStatus()
            getAllScheduleByDate()
            handleDeleteAppoinMentModal(false)
        }
    }
    return (
        <Modal isOpen={deleteAppoitMentModal} className='deleteModal main_comman_modal'>
            <ModalBody>
                <div className='custom_modal text_center'>
                    <h3 className='mb_5 title'>Delete a Appointment?</h3>
                    <p className='mt_3 content'>You are about to delete an active patient from your list. Pnce you delete the patient, all calendar invites will be removed from your and the patient’s schedule. This change can not be reversed.</p>
                    <p className='mt_2 sub_heading'><strong>Do you want to proceed?</strong></p>
                    <div className="form_group d_flex justify_content_center form_action delete_modal_btn">
                        <button className="btn btn_default mr_2" onClick={deleteAppoinMent}>Yes</button>
                        <button className="btn btn_primary" onClick={() => handleDeleteAppoinMentModal(false)}>No</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>

    );
}

export default DeleteSchedulePop;

