import React, { useEffect, useRef, useState } from 'react'
import logo from '../../../assets/images/new_hp_logo.svg';
import down_arrow from '../../../assets/images/Down_arrow.svg';
import notification from '../../../assets/images/notification_bell.svg';
import { mainGetServices, setToken, socket, socketSendRequest } from '../../../shared/service';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import {
    PHYSICIAN_MESSAGE_COUNT,
    PHYSICIAN_NOTIFICATION_LIST, PHYSICIAN_SCHEDULE_COUNT,
    SET_USER_PROFILE
} from "../../../store/action";
import useOutsideClick from '../../../shared/hooks/useOutsideClick';
import { Link } from 'react-router-dom';
import { LOGOUT_API_URL } from '../../../shared/constant';
import { Decryption } from "../../../shared/function";
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { generateAvatar } from "../../../utils";

const HeaderComponent = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const profileMenuRef = useRef();
    const [appendNotification, setAppendNotification] = useState(false)

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [limit, setLimit] = useState(5)
    const currentUser = useSelector(state => state.userDetailsReducer.currentUser)
    const phy_notification = useSelector(state => state?.chatReducer?.physicianNotification)
    const notificationCount = useSelector(state => state?.chatReducer?.notificationCount)
    const [notificationData, setNotificationData] = useState(false)
    const [isMenuOpenMenuData] = useState(false)

    useEffect(() => {
        dispatch({ type: SET_USER_PROFILE, payload: Decryption(localStorage.getItem("user")) })
    }, [history, dispatch, !isMenuOpenMenuData])

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isMenuOpen && profileMenuRef.current && !profileMenuRef.current.contains(e.target) && e.target.id !== 'profileIcon') {
                setIsMenuOpen(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => { document.removeEventListener("mousedown", checkIfClickedOutside) }
    }, [isMenuOpen])


    const doLogout = async () => {

        const { success } = await mainGetServices(LOGOUT_API_URL, {});
        if (success) {
            setToken(false)
            Cookies.remove('x-access-token_phy',{ path: "/", domain: '.haploscope.com' })
            Cookies.remove('x-access-token', { path: "/", domain: '.haploscope.com' })
            Cookies.remove('x-access-token', { path: "/" })
            Cookies.remove('x-access-token_phy',{ path: "/"})
            localStorage.clear();
            window.location.href = "/login"
        }
    };

    const openNotificationModal = () => {
        setNotificationData(!notificationData)
        localStorage.removeItem('notification')
        dispatch({ type: PHYSICIAN_MESSAGE_COUNT, payload: 0 })
        const req = {
            last_id: '',
            limit: phy_notification?.limit
        }
        socketSendRequest("getPhysicianNotifications", req)
        setAppendNotification(false)
    }

    const refNotification = useRef()
    useOutsideClick(refNotification, () => {
        if (notificationData) setNotificationData(false);
    });

    useEffect(() => {
        if (currentUser) {
            socketSendRequest("getCounts", {})
        }
        socket?.on("response", socketListener)
        return () => {
            socket?.off("response", socketListener)
        };
    }, [currentUser, appendNotification])

    const socketListener = (res) => {
        switch (res.evt) {
            case 'getPhysicianNotifications':
                if (res.data) {
                    dispatch({
                        type: PHYSICIAN_NOTIFICATION_LIST,
                        payload: { data: res.data, append: appendNotification }
                    });
                }
                break;
            case 'getCounts':
                if (res.data) {
                    dispatch({ type: PHYSICIAN_SCHEDULE_COUNT, payload: res.data })
                    if (res.data.notificationCount !== 0) {
                        localStorage.setItem('notification', res.data.notificationCount)
                        dispatch({ type: PHYSICIAN_MESSAGE_COUNT, payload: res.data.notificationCount })
                    }
                }
                break;
            default:
                break;
        }
    }

    const listInnerRef = useRef();
    const notificatonListScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop === 0) {
                setAppendNotification(false)
                const req = {
                    last_id: '',
                    limit: 10
                };
                socketSendRequest("getPhysicianNotifications", req)
            }
            if (scrollTop + clientHeight === scrollHeight && phy_notification?.totalResults !== 0) {
                setAppendNotification(true)
                const req = {
                    last_id: phy_notification?.notification[phy_notification?.notification?.length - 1]?._id,
                    limit: 10
                };
                socketSendRequest("getPhysicianNotifications", req)
            }
        }
    }

    return (
        <header>
            {
                <div className="container_fluid">
                    <div className="row main_herader_row">
                        <div className="header px_auto">
                            <div className="logo"><img src={logo} alt="logo_img" /></div>
                            <div className="header_profile">
                                <div className="profile" >
                                    {
                                        currentUser?.first_name === undefined ?
                                            ""
                                            :
                                            <span className="profile_name" onClick={() => setIsMenuOpen(!isMenuOpen)}>{currentUser?.first_name + " " + currentUser?.last_name}</span>
                                    }
                                    <span className="profile_pic profile-pic_block"
                                        onClick={() => setIsMenuOpen(!isMenuOpen)}>
                                        {currentUser?.first_name === undefined ?
                                            ""
                                            :
                                            <img
                                                id="profileIcon"
                                                src={currentUser?.profile ? currentUser?.profile : generateAvatar(`${currentUser?.first_name + ' ' + currentUser?.last_name}`)}
                                                alt="profile_pic"

                                            />}
                                    </span>
                                </div>
                                <div className="drop_down">
                                    <div className="cursor_pointer" ><img id="profileIcon" src={down_arrow} alt="down_arrow" onClick={() => setIsMenuOpen(!isMenuOpen)} /></div>
                                    {
                                        isMenuOpen &&
                                        <ul className="list_unstyled drop_down_list" ref={profileMenuRef}>
                                            <li><Link to={"/setting"} className={window.location.pathname === "/setting" ? "active" : ''} onClick={() => setIsMenuOpen(!isMenuOpen)}>Settings <span className="dot_blue d_none" /></Link></li>
                                            <li><a href="#!">Contact Support <span className="dot_blue d_none" /></a></li>
                                            <li><a href="#!" onClick={doLogout}>Log Out<span className="dot_blue d_none" /></a></li>
                                        </ul>
                                    }
                                </div>
                                <div className="notification">
                                    <img src={notification} alt="notification_icon" onClick={() => openNotificationModal()} />
                                    {notificationCount > 0 &&
                                        <span onClick={() => openNotificationModal()}> {notificationCount} </span>}
                                    {
                                        notificationData ?
                                            <div className='main_notification_modal' ref={refNotification} >
                                                <ul className="list_unstyled drop_down_list notification_modal" ref={listInnerRef} onScroll={notificatonListScroll}>
                                                    {
                                                        phy_notification?.notification.length > 0 ?
                                                            phy_notification?.notification?.map((phy, i) => {
                                                                return (
                                                                    <li className={'notification_modal_flex'} key={i}>
                                                                        <img src={phy?.users?.profile ? phy?.users?.profile : generateAvatar(phy.users.first_name + " " + phy.users.last_name)} alt="" />
                                                                        <div className={'notification_content_data'}>
                                                                            <h3>{phy.users.first_name + " " + phy.users.last_name}</h3>
                                                                            <p> {phy?.content}</p>
                                                                            <p>
                                                                                {phy?.createdAt
                                                                                    ? !moment(moment.utc(phy?.createdAt).local()).isSame(moment(), 'day') ? moment(phy?.createdAt).format(
                                                                                        "L"
                                                                                    ) : moment(phy?.createdAt).format(
                                                                                        "LT"
                                                                                    )
                                                                                    : ""}
                                                                            </p>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                            :
                                                            <li>
                                                                Currently, You have no notification
                                                            </li>
                                                    }
                                                </ul>
                                            </div>
                                            : ""
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </header>
    )
}

export default HeaderComponent
