import React, { useState, useEffect } from 'react'
import AddPatient from './AddPatient'
import EditPatient from './EditPatient'
import PatientsHeader from './Header'
import PatientsList from './PatientsList'
import DeletePatient from './DeletePatients';
import CaregiversList from './CaregiversList'

const PatientsComponent = () => {
    const [patientModal, setPatientModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [caregiversModal, setCaregiversModal] = useState(false)
    const [height, setHeight] = useState('74');
    const [caregiverData, setCaregiverData] = useState(null)

    const handlePatientsModal = (modal) => {
        setPatientModal(modal)
    }

    const handleDeletePatientsModal = (modal) => {
        setDeleteModal(modal)
    }
    const handleCaregiversListModal = (modal) => {
        setCaregiverData(modal)
        setCaregiversModal(true)
    }

    useEffect(() => {
        if (document.querySelector('.heading_content')) {
            var getHeight = document.querySelector('.heading_content').offsetHeight;
            setHeight(getHeight);
        }
    }, [document.querySelector('.heading_content')])

    return (
        <div className="clinics patients_list_main">
            <PatientsHeader handlePatientsModal={handlePatientsModal} />
            <PatientsList
                handleDeletePatientsModal={handleDeletePatientsModal}
                handlePatientsModal={handlePatientsModal}
                handleCaregiversListModal={handleCaregiversListModal}
                height={height}
                setEditModal={setEditModal}
            />
            <AddPatient
                patientModal={patientModal}
                handlePatientsModal={handlePatientsModal}
            />
            <EditPatient
                patientModal={editModal}
                setEditModal={setEditModal}
            />
            <DeletePatient
                deleteModal={deleteModal}
                handleDeletePatientsModal={handleDeletePatientsModal}
            />
            <CaregiversList
                caregiverData={caregiverData}
                caregiversModal={caregiversModal}
                setCaregiversModal={setCaregiversModal}
            />
        </div>
    )
}

export default PatientsComponent
