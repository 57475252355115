import React, { useEffect, useRef, useState } from 'react';
import filterIcon from "../../assets/images/filter.svg";
import filterColor from "../../assets/images/filterColor.svg";
import Edit from "../../assets/images/Edit_icon.svg";
import icon_lock from '../../assets/images/icon_lock.svg'
import Un_lock from "../../assets/images/Unlock_icon.svg";
import dustbin from "../../assets/images/dustbin_icon.svg";
import alert_icon from '../../assets/images/alert_icon.svg'
import cal_icon from '../../assets/images/cal-icon.png'
import { useDispatch, useSelector } from 'react-redux';
import { mainPostServices } from '../../shared/service';
import { DELETE_PATIENT_STATE, EDIT_PATIENT_STATE, GET_PATIENTS } from '../../store/action';
import { EDIT_PATIENT_URL, GET_ALL_PATIENTS_URL, GET_PATIENTS_URL, RESEND_INVITE_PATIENTS_URL } from '../../shared/constant';
import moment from 'moment';
import Pagination from '../common/Pagination';
import { useHistory } from 'react-router-dom';
import Sweetalert from 'sweetalert2';
import useOutsideClick from '../../shared/hooks/useOutsideClick';
import Loader from '../Loader';
import { formatPhoneNumber } from "../../utils";
import ActiveInactivePopup from './ActiveInactivePopup';
import { Link } from "react-router-dom";

const PatientsList = ({ handleDeletePatientsModal, handlePatientsModal, setEditModal, height, handleCaregiversListModal }) => {
    let url;
    const dispatch = useDispatch()
    const history = useHistory()
    const refStatus = useRef()
    const allPatients = useSelector(state => state.patientsReducer.allPatients)
    const filters = useSelector(state => state.patientsReducer.filters)
    url = filters.status === 'all' ? GET_ALL_PATIENTS_URL : GET_PATIENTS_URL
    const [statusDropdown, setStatusDropdown] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('')
    const statusArr = ["Active", "Pending",];

    const [activeInactiveModal, setActiveInactiveModal] = useState(false)
    const [statusId, setStatusId] = useState(null)


    const [waitingLoader, setWaitingLoader] = useState(true)
    useEffect(() => {
        getAllPatients()
    }, [])

    useOutsideClick(refStatus, () => {
        if (statusDropdown) setStatusDropdown(false);
    });

    const getAllPatients = async () => {
        const req = {
            page: allPatients.page,
            limit: allPatients.limit
        }
        const { data, success } = await mainPostServices(url, req);
        if (success) {
            dispatch({ type: GET_PATIENTS, payload: data })
            setWaitingLoader(false)
        } else {
            setWaitingLoader(false)
        }
    }

    const onPaginate = async (pageNumber) => {
        const req = {
            page: pageNumber,
            limit: allPatients.limit
        }
        if (selectedStatus === 'Pending') Object.assign(req, { status: false })
        if (selectedStatus === 'Active') Object.assign(req, { status: true })
        if (filters.search) Object.assign(req, { name: filters.search })
        const { data, success } = await mainPostServices(url, req);
        if (success) {
            dispatch({ type: GET_PATIENTS, payload: data })
        }
    }
    const handleNext = async () => {
        const req = {
            page: allPatients.page + 1,
            limit: allPatients.limit
        }
        if (filters.search) Object.assign(req, { name: filters.search })

        const { data, success } = await mainPostServices(url, req);
        if (success) {
            dispatch({ type: GET_PATIENTS, payload: data })
        }
    }
    const handlePrevious = async () => {
        const req = {
            page: allPatients.page - 1,
            limit: allPatients.limit
        }
        if (filters.search) Object.assign(req, { name: filters.search })
        // if(filters.status) Object.assign(req,{status:filters.status})
        const { data, success } = await mainPostServices(url, req);
        if (success) {
            dispatch({ type: GET_PATIENTS, payload: data })
        }
    }

    const getPatientsSchedule = (item) => {
        history.push(`/schedule?name=${item.first_name + " " + item.last_name}`)
    }


    const resendInvitation = async (patient_id) => {
        const req = {
            id: patient_id
        }
        const { success } = await mainPostServices(RESEND_INVITE_PATIENTS_URL, req);
        if (success) {
            Sweetalert.fire({ title: 'Success', text: "Resend Invite Successfully" })
        }
    }

    const wantsToDeletePatient = async (id) => {
        handleDeletePatientsModal(true)
        dispatch({ type: DELETE_PATIENT_STATE, payload: id })
    }

    const wantsToEditPatient = async (user_id) => {
        const { data, success } = await mainPostServices(EDIT_PATIENT_URL, { user_id });
        if (success) {
            setEditModal(true)
            dispatch({ type: EDIT_PATIENT_STATE, payload: data })
        }
    }

    const handleUpdateUserStatus = async (statusId) => {
        setActiveInactiveModal(true)
        setStatusId(statusId)
    }

    const statusFilters = async (e) => {
        const { checked, value } = e.target
        setSelectedStatus(e.target.value)
        if (checked && value === "Active") {
            const req = {
                status: true,
                page: 1,
                limit: allPatients.limit
            }
            const { data, success } = await mainPostServices(url, req);
            if (success) {
                dispatch({ type: GET_PATIENTS, payload: data })
            }
        } else if (checked && value === "Pending") {
            const req = {
                status: false,
                page: 1,
                limit: allPatients.limit
            }
            const { data, success } = await mainPostServices(url, req);
            if (success) {
                dispatch({ type: GET_PATIENTS, payload: data })
            }
        } else {
            getAllPatients()
            setSelectedStatus('')
        }
    }
    //handleCaregiversListModal
    const handleCaregiversList = (payload) => {
        return handleCaregiversListModal(payload)
    }

    return (
        <div className="table_wrapper" style={{ height: `calc(100% - ${height}px)` }}>
            <div className="table_responsive">
                <table className="table clinic_data_list pms_patients_table">
                    <thead>
                        <tr>
                            <td/>
                            <td>Patient Name</td>
                            <td>Email/Phone</td>
                            <td>Caregivers</td>
                            <td/>
                            <td className='status_head' onClick={() => setStatusDropdown(true)}>Status
                                <span className={`filter_dropdown Status filter icon_filter`}>
                                    <img src={statusDropdown ? filterColor : filterIcon} alt="Filter" />
                                </span>
                                {statusDropdown &&
                                    <div className="drop_down filter-drop-status" ref={refStatus}>
                                        <div className="list_unstyled drop_down_list" >
                                            <div className="heading_row">
                                                <h4 className="select">Select</h4>
                                                {/*<h4 className="all">All</h4>*/}
                                                {/*<h4 className="none">None</h4>*/}
                                            </div>
                                            {statusArr.map((item, i) => {
                                                return (
                                                    <div className='form_group checkbox status_checkbox' key={i}>
                                                        <input value={item} type="checkbox" checked={selectedStatus === item} onChange={statusFilters} />
                                                        <span className="checkbox_clone"/>
                                                        <label htmlFor="cohort_checkbox">{item}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                            </td>
                            <td>Added On</td>
                            <td>Last Logged</td>
                            <td/>
                            <td>Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                        {waitingLoader ?
                            <tr>
                                <td className='td_loader'>
                                    <div className='spin_loder'>
                                        <Loader className="data_load" />
                                    </div>
                                </td>
                            </tr>
                            :
                            allPatients.patientsList.length > 0 ?

                                allPatients.patientsList.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            {/*<td className="cursor_pointer clinic-name">*/}
                                            {/*    {!item.status ? <img src={alert_icon} alt="" /> : ""}*/}
                                            {/*</td>*/}
                                            <td className=" clinic-name clinic_hover_icon">
                                                {
                                                    !item.status &&
                                                    <div className='ps-code'>
                                                        {item.status ? '' : <img src={alert_icon} alt={'alert_icon'} className={'cursor_pointer'} />}
                                                        <div className='reg_code'>
                                                            <p>This patient is yet to accept his/her invitation</p>
                                                        </div>
                                                    </div>
                                                }

                                            </td>
                                            <td className="cursor_pointer clinic-name text_capitalize_md" onClick={() => getPatientsSchedule(item)}>{item?.first_name + " " + item?.last_name}</td>
                                            <td className="td_emailphone"><span>{item?.email}</span> <span>{formatPhoneNumber(item?.phone)}</span></td>
                                            <td className={'clinic-name'}><Link to={'#'} onClick={() => handleCaregiversList({ id: item._id, PatientName: item?.first_name + " " + item?.last_name })}>{item?.caregiverCount}</Link></td>
                                            <td>
                                                {item.status &&
                                                    <div className='main_datepicker_block' onClick={() => getPatientsSchedule(item)} >
                                                        <div className='cal_img_block '>
                                                            <img src={cal_icon} alt="calendar" />
                                                        </div>
                                                    </div>
                                                }
                                            </td>
                                            <td>
                                                <div className="td_status_main">
                                                    <span>{item?.user_type?.map((item) => item.type === 'patients' ? item.isActive ? "Active" : "Pending" : "")}</span>
                                                </div>
                                            </td>
                                            <td>{moment(item?.createdAt).format("MM/DD/YYYY")}</td>
                                            <td>{moment(item?.lastLogin).format("MM/DD/YYYY")}</td>
                                            <td>
                                                <div className="invite_div">
                                                    {!item.status &&
                                                        <button className="btn btn_primary invite" onClick={() => resendInvitation(item._id)}>Resend Invite</button>
                                                    }
                                                </div>
                                            </td>
                                            <td className="td_icons">
                                                <div className="d_flex icons">
                                                    <span className="actions_icon" onClick={() => wantsToEditPatient(item._id)}><img src={Edit} alt="alert_icon" /></span>
                                                    {
                                                        item.status &&
                                                        <span className="actions_icon" onClick={() => handleUpdateUserStatus({ id: item._id, active: item?.user_type[0]?.isActive })}>
                                                            <img src={item?.user_type[0]?.isActive ? Un_lock : icon_lock} alt="alert_icon" />
                                                        </span>
                                                    }

                                                    <span className="actions_icon" onClick={() => wantsToDeletePatient(item._id)}><img src={dustbin} alt="alert_icon" /></span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr><td colSpan={10}><div className="mt_5 pb_5 pt_5 fullWidth text_center" >No Data Found</div></td></tr>
                        }

                    </tbody>
                </table>
            </div>
            {
                allPatients.totalResults > 10 &&
                <Pagination
                    page={allPatients.page}
                    limit={allPatients.limit}
                    totalResults={allPatients.totalResults}
                    paginate={onPaginate}
                    next={handleNext}
                    prev={handlePrevious}
                />
            }

            <ActiveInactivePopup activeInactiveModal={activeInactiveModal} setActiveInactiveModal={setActiveInactiveModal} statusId={statusId} setWaitingLoader={setWaitingLoader} url={url} />
        </div>

    )
}

export default PatientsList;
