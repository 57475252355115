import React, {Fragment} from "react";
import logo from "../../assets/images/rs_password-logo.svg";
import {Helmet} from "react-helmet";

const ResetSuccessScreen = ()=>{
    return (
        <Fragment>
            <Helmet>
                <title>{"Haploscope"}</title>
            </Helmet>
            <div className='content_wrapper resetpssword_main'>
                <div className='setting_form resetpsw_sub_block'>
                    <div className="header px_auto">
                        <div className="logo"><img src={logo} alt="logo_img" /></div>
                    </div>
                    <div className='resetpsw_form_block'>
                         <p className='successfully_msg'>Your password has been reset successfully. You may now log into the mobile app using your new password.</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ResetSuccessScreen