import React, { useState } from 'react'

export default function PhysicianRegistrationPopup(prop) {



        return (
                <div className={`mob_pr_modal`}>
                        <div className='pr_modal_container'>
                                <h4>Physician Registration</h4>
                                <p>For new physician registration, please use the Physician Registration Code, and the email where the code was sent. If you are having difficulty in registering, please check to make sure that you are using the correct code and the email address where you received the invitation. </p>
                                <button className='ok_btn' onClick={prop.close} >OK</button>
                        </div>
                </div>
        )
}
