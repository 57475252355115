import {constant} from "./constant";
import socketIOClient from "socket.io-client";
import {  PHYSICIANS_MESSAGE_LIST , DATA_WAITING_LOADER} from "../store/action";
// import Cookies from 'js-cookie';
import axiosClient from "../api";



// const api = axios.create();
// api.defaults.baseURL = constant.API_URL;
// api.defaults.headers.post["Content-Type"] = "application/json";
// api.defaults.headers.post["Accept"] = "application/json";

const statusCheck = {
    validateStatus: (status) => {

      if (status === 401) {
        setToken(false)
        window.location.reload(true)
      } else {
        return true;
      }
    }
}

export const setToken = (token) => {
  if (token) {
      axiosClient.defaults.headers.common = {'x-access-token': `${token}`}
  }else {
    delete axiosClient.defaults.headers.common["x-access-token"]
    document.cookie = 'x-access-token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    localStorage.clear()
  }
}

// const { data, status, message } = await mainPostServices(AUTH_CHECK_SOCIALMEDIA_HANDLE, req);
export const mainPostServices = (url, data) => axiosClient.post(url, data,statusCheck).then(onSuccess, onError);
export const mainGetServices = (url, data) => axiosClient.get(url, data,statusCheck).then(onSuccess, onError);

function onError(response) {
    return response;
}

function onSuccess(response) {
    return response.data
}

// const mytoken = Cookies.get('x-access-token')

// Socket Setup
let socket;
export const socketConnect = (dispatch,token, cb) => {
  if(token){
    if (!socket) socket = socketIOClient.connect(`${constant.API_URL}?token=${token}`);
  }else{
    window.location.href = "/login"
  }
  
  socket?.on("connect", () => {
    cb(socket.connected);
  });

  socket?.on("response", (res) => {
    if (!res.error) {
     switch (res.evt) {
        case "join":
          break;

       case "getPhysicianMsgList":
         dispatch({ type: PHYSICIANS_MESSAGE_LIST, payload: res.data });
         dispatch({ type: DATA_WAITING_LOADER, payload: false });
        break;

       default:
         break;
      }
     }
   });
  // socket.on("disconnect", () => {
  // });
}


export const socketSendRequest = (e, payload) => {
        socket?.emit(e,{evt:e,data:payload});
 };


export const authSocketRequest = (e, payload) => {
   socket?.emit(e,payload);
 };

export {socket}