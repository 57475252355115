import React, { useState, useEffect } from 'react'
import ScheduleHeader from './Header';
import AppoimentBlockOne from "./AppoimentBlockOne";
import AppoinmentBlockTwo from "./AppoinmentBlockTwo";
import AddAppoinment from './AddAppoinment';
import DeleteSchedulePop from './DeleteSchedulePop';
import StartMeeting from "./StartMeetingPop";
import { useDispatch, useSelector } from "react-redux";
import { SET_USER_PROFILE, SOCKET_CONNECTION } from "../../store/action";
import {authSocketRequest, mainGetServices,setToken,socket, socketConnect, socketSendRequest} from "../../shared/service";
import Cookies from "js-cookie";
import { GET_PROFILE_DATA } from '../../shared/constant';
import { Encryption } from '../../shared/function';

const ScheduleComponent = () => {
    const dispatch = useDispatch() 
    const [appoinmentModal, setAppoinmentModal] = useState(false);
    const [deleteAppoitMentModal, setDeleteAppoitMentModal] = useState(false);
    const [videoStartModal, setVideoStartModal] = useState(false);
    const [height, setHeight] = useState('76');
    const [open, setOpen] = useState(false);
    const currentUser = useSelector(state => state.userDetailsReducer.currentUser)

    const handleAppoinmentModal = (modal) => {
        setAppoinmentModal(modal)
    }

    const handleDeleteAppoinMentModal = (modal) => {
        setDeleteAppoitMentModal(modal)
    }
    const openView = () => {
        setOpen(!open)
    }

    useEffect(() => {
        if (document.querySelector('.schedulepatient_nav')) {
            var getHeight = document.querySelector('.schedulepatient_nav').offsetHeight;
            setHeight(getHeight);
        }
    }, [document.querySelector('.schedulepatient_nav')])

    const startMeetingVideo = (modal) => {
        setVideoStartModal(modal)
    }


    useEffect(() => {
        socketConnect(dispatch,Cookies.get('x-access-token'), (flag) => {
          if(flag){
            dispatch({type: SOCKET_CONNECTION, payload: flag})
            if(currentUser){
              authSocketRequest("join",{user_id:currentUser?._id})
              socketSendRequest("getPhysicianMsgList",{page:1,limit:10})
              socketSendRequest("getCounts", {})
            }
          }
        })
        // unsubscribe from event for preventing memory leaks
        return () => {
          socket.off('response');
        };
      }, [currentUser])

    const getProfileDetails = async ()=>{
        const { data,success } = await mainGetServices(GET_PROFILE_DATA, {});
        if(success){
          localStorage.setItem("user", Encryption(data));
          localStorage.setItem("id", data._id)
          dispatch({type:SET_USER_PROFILE,payload:data})
        }
      }

     useEffect(()=>{
        if(Cookies.get('x-access-token_phy')){
            setToken(Cookies.get('x-access-token_phy'))
            Cookies.set('x-access-token',Cookies.get('x-access-token_phy'))
            localStorage.setItem("token",Cookies.get('x-access-token_phy'))
          }
         getProfileDetails()
    },[Cookies.get('x-access-token')])

    return (
        <>
            <div className="schedulepatient">
                <ScheduleHeader handleAppoinmentModal={handleAppoinmentModal} openView={openView} />
                <div className="schedule_appoinment" style={{ height: `calc(100% - ${height}px)` }}>
                    <AppoimentBlockOne
                        handleAppoinmentModal={handleAppoinmentModal}
                        handleDeleteAppoinMentModal={handleDeleteAppoinMentModal}
                        startMeetingVideo={startMeetingVideo}
                        className={open}
                    />
                    <AppoinmentBlockTwo
                        handleAppoinmentModal={handleAppoinmentModal}
                        handleDeleteAppoinMentModal={handleDeleteAppoinMentModal}
                        startMeetingVideo={startMeetingVideo}
                        className={open}
                    />
                </div>
            </div>
            <AddAppoinment
                appoinmentModal={appoinmentModal}
                handleAppoinmentModal={handleAppoinmentModal}
            />
            <DeleteSchedulePop
                deleteAppoitMentModal={deleteAppoitMentModal}
                handleDeleteAppoinMentModal={handleDeleteAppoinMentModal}
            />
            <StartMeeting
                videoStartModal={videoStartModal}
                startMeetingVideo={startMeetingVideo}
            />
        </>
    )
}

export default ScheduleComponent
