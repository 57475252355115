import React, { Fragment, useEffect, useRef, useState } from 'react';
import Footer from '../../components/common/Footer';
import logo from '../../assets/images/new_hp_logo.svg';
import icone_hide_pass from '../../assets/images/eye_hide.svg';
import icon_open_password from "../../assets/images/eye_show.svg";
import { Helmet } from "react-helmet";
import { authSocketRequest, mainPostServices, setToken, socketConnect, socketSendRequest } from '../../shared/service';
import { LOGIN_API_URL } from '../../shared/constant';
import SimpleReactValidator from 'simple-react-validator';
import { useHistory } from 'react-router-dom';
import { Encryption, isUserLoggedIn } from '../../shared/function';
import { useDispatch } from 'react-redux';
import { SET_USER_PROFILE, SOCKET_CONNECTION } from '../../store/action';
import Sweetalert from "sweetalert2";
import ForgetPasswordComponent from "./ForgetPassword";
import Loader from '../Loader';
import InvalidaAccountComponent from './InvalidAccount';
import Cookies from 'js-cookie';

const SignInComponent = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const simpleValidator = useRef(new SimpleReactValidator())
    const [, updateState] = useState();
    const [btnLoading, setBtnLoading] = useState(false)
    const [invalidModal, setInvalidModal] = useState(false)
    const forceUpdate = React.useCallback(() => updateState({}), []);


    const [loginState, setLoginState] = useState({
        email: '',
        password: ''
    })

    const [viewPassWord, setViewPassWord] = useState(false)
    const [isForget, setIsForget] = useState(false);

    const getCookie = (cname) => {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }


    useEffect(() => {
        let token = getCookie("x-access-token");
        if (token) {
            document.cookie = `x-access-token=${token}`
            localStorage.setItem("token", token)
            history.push("/schedule")
        }
    }, [])

    useEffect(() => {
        if (isUserLoggedIn()) {
            localStorage.setItem("token", localStorage.getItem("token"));
            var now = new Date();
            now.setTime(now.getTime() + (24 * 60 * 60 * 1000));
            document.cookie = `x-access-token=${localStorage.getItem("token")}; expires=${now.toUTCString()}; path=/`;
            history.push("/schedule")
        }
    }, [history])

    const handleLoginInputChange = (e) => {
        const { name, value } = e.target
        setLoginState({
            ...loginState, [name]: value.trim()
        })
    }

    const doLogin = async (req) => {
        const { data, message, success, status } = await mainPostServices(LOGIN_API_URL, req);
        if (success) {
            setBtnLoading(false)
            setToken(data.token)
            localStorage.setItem("user", Encryption(data));
            localStorage.setItem("token", data.token);
            localStorage.setItem("id", data._id)
            var now = new Date();
            now.setTime(now.getTime() + (24 * 60 * 60 * 1000));
            document.cookie = `x-access-token=${data.token}; expires=${now.toUTCString()}; path=/`;
            dispatch({ type: SET_USER_PROFILE, payload: data })

            socketConnect(dispatch,Cookies.get('x-access-token'), (flag) => {
                if (flag) {
                    dispatch({ type: SOCKET_CONNECTION, payload: flag })
                    authSocketRequest("join", { user_id: data?._id })
                    socketSendRequest("getPhysicianMsgList", { page: 1, limit: 10 })
                    // socketSendRequest("getCounts", {})
                }
            })

            history.push("/schedule")

        } else if (status === 400 && message !== "Incorrect Email or Password !") {
            Sweetalert.fire({
                title: 'Info',
                text: message
            })
            setBtnLoading(false)
        } else {
            setInvalidModal(true)
            setBtnLoading(false)
        }
    }


    const handleSubmit = e => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            doLogin(loginState)
            setBtnLoading(true)
        } else {
            simpleValidator.current.showMessages();
            forceUpdate()
        }
    }

    const clearForm = () => {
        setLoginState({
            email: '',
            password: ''
        })
        simpleValidator.current.hideMessages();
        forceUpdate()
    }

    const togglePassWordHideShow = (pwdShow) => {
        setViewPassWord(pwdShow)
    }

    return (
        <Fragment>
            <Helmet>
                <title>{"Haploscope MD"}</title>
            </Helmet>
            <div className="signin">
                <div className="container">
                    <div className="row header_logo">
                        <div className="logo"><img src={logo} alt="logo_img" /></div>
                    </div>
                </div>
                <section className="sigin_banner">
                    <div className="container">
                        <div className="sign_box">
                            <div className="account_system">
                                <h4>patient Management System</h4>
                            </div>
                            <div className="admin_sigin">
                                <div className='sub_admin-signin'>
                                    <h2>Sign In</h2>
                                    <form onSubmit={handleSubmit} onReset={clearForm}>
                                        <div className="form_group">
                                            <div><label>Email</label></div>
                                            <input
                                                type="text"
                                                placeholder="Email"
                                                className="form_control"
                                                name="email"
                                                autoComplete="off"
                                                value={loginState.email}
                                                onChange={handleLoginInputChange}
                                            />
                                            <div className="invalid-feedback pl-4 d-block errorMsg">
                                                {simpleValidator.current.message('email', loginState.email, 'required|email')}
                                            </div>
                                        </div>
                                        <div className="form_group">
                                            <div><label>Password</label></div>
                                            <input
                                                type={viewPassWord ? "text" : "password"}
                                                placeholder="password"
                                                className="form_control pr_4"
                                                name="password"
                                                autoComplete="off"
                                                value={loginState.password}
                                                onChange={handleLoginInputChange}
                                            />
                                            <span className={`password_icon ${viewPassWord ? 'icon_show' : 'icon_hide'}`}><img src={viewPassWord ? icon_open_password : icone_hide_pass} alt="icone_hide_password" onClick={() => togglePassWordHideShow(!viewPassWord)} /></span>
                                            <div className="invalid-feedback pl-4 d-block errorMsg">
                                                {simpleValidator.current.message('password', loginState.password, 'required')}
                                            </div>
                                            <div className={''}>
                                                <button type={'button'} className='forgot_password' onClick={() => setIsForget(!isForget)} >Forgot Password</button>
                                            </div>
                                        </div>

                                        <div className="d_flex_center mt_4 mb_4 form_buttons">
                                            <button type="reset" className="btn_default">Clear</button>
                                            <button className="btn_primary ml_2" type="submit">
                                                {btnLoading ?
                                                    <Loader color="primary" size="sm" />
                                                    :
                                                    "Submit"
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className={'terms-condition-sec'}>
                                    <h5>By joining, you are agreeing to our {' '}
                                        <a href={'https://haploscope.com/tos.html'} target={'_blank'}>Terms of Service</a> and <a href={'https://haploscope.com/privacy.html'} target={'_blank'}>Privacy Policy</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ForgetPasswordComponent isForget={isForget} setIsForget={setIsForget} />
                </section>
            </div>
            <Footer />

            <InvalidaAccountComponent invalidModal={invalidModal} setInvalidModal={setInvalidModal} setIsForget={setIsForget} />
        </Fragment>
    )
}

export default SignInComponent
