import React, { useState } from 'react';
import { useTrack } from 'use-twilio-video';
import AudioTrack from './AudioTrack';
import VideoTrack from './VideoTrack';
import mute_icon from '../../assets/images/bi_mic-mute-fill.png';

const ParticipentsComponent = ({ participant, hostUser }) => {
  const { videoOn, audioOn, videoTrack, audioTrack } = useTrack({ participant })

  const membersIdentity = JSON.parse(participant?.identity)

  return (
    <>
      {/* {membersIdentity ? membersIdentity?.name : ""} */}
      {videoOn ? <VideoTrack track={videoTrack} /> : <p className='pc_name'>This participant
        has disabled video</p>}
      <br />
      {audioOn ? <AudioTrack track={audioTrack} /> : ''}
      {!audioOn ?
        <>
          <p className='participant_name'>
            {membersIdentity ? membersIdentity?.identity + " " + membersIdentity?.name : ""}
          </p>
          <div className='mute_icon-block'>
            {!hostUser && <img src={mute_icon} className='mute-icon' alt='mute icon' />}
          </div>

        </>
        :
        <p className='participant_name'>{membersIdentity ? membersIdentity?.identity + " " + membersIdentity?.name : ""}</p>
      }
    </>
  )
}

export default ParticipentsComponent