import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import cross_icon from "../../assets/images/cross-icon.png";

const DeleteMessageComponent = ({ deleteModal, setDeleteModal, deleteMessage }) => {
  return (
    <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)} className='deleteModal deleteModal_main  main_comman_modal'>
      <div className='close_modal' onClick={() => setDeleteModal(false)}>
        <img src={cross_icon} alt='cross' />
      </div>
      <ModalBody>
        <div className="custom_modal text_center">
          <h3 className='mb_5 title'>Delete Message</h3>
          <p className='mt_2 sub_heading'><strong>Do you want to proceed?</strong></p>
          <div className="form_group d_flex justify_content_center form_action mt_4">
            <button className='btn btn_default' onClick={deleteMessage}>Yes</button>
            <button className='btn btn_primary ml_2' onClick={() => setDeleteModal(false)}>No</button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DeleteMessageComponent