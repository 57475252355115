export const menu = [
    {
        title: "Schedule", path: `${process.env.PUBLIC_URL}/schedule`
    },
    {
        title: "Patients", path: `${process.env.PUBLIC_URL}/patients`
    },
    {
        title: "Messages", path: `${process.env.PUBLIC_URL}/messages`
    }
]