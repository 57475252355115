import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import cross_icon from "../../assets/images/cross-icon.png";

const RequestChangeModal = ({ requestModal, setRequestModal,requestMinutesTo }) => {
  return (
    <Modal isOpen={requestModal} className='deleteModal main_comman_modal change-min_modal'>
      <div className='close_modal' onClick={() => setRequestModal(false)}>
        <img src={cross_icon} alt='cross' />
      </div>
      <ModalBody>
        <div className="custom_modal text_center">
          <h3 className='mb_5 title'>Minutes Request Sent</h3>
          <p className='mt_3 '>
            Your request to add more conference minutes
            has been sent to your organization’s
            primary contact <b>{requestMinutesTo?.first_name + " " + requestMinutesTo?.last_name}</b>
          </p>
          {/* <p className='mt_2 sub_heading'><strong>Do you want to proceed?</strong></p> */}
          <div className="form_group d_flex justify_content_center form_action mt_4">
            <button className='btn btn_default ' onClick={() => setRequestModal(false)}>ok</button>
          </div>
        </div>
      </ModalBody>
    </Modal>

  )
}

export default RequestChangeModal;
