import React, { useEffect, useState } from 'react';
import ChatHeader from './chatHeader';
import { useDispatch, useSelector } from 'react-redux';
import loader_Icon from '../../assets/images/loader.gif'
import { socket,socketSendRequest } from '../../shared/service';
import { CHAT_MESSAGE_LIST, SEND_MESSAGE } from '../../store/action';
import moment from 'moment';
import {generateAvatar} from "../../utils";
let date = "1970";

const ChatAreaComponent = (props) => {
    const dispatch = useDispatch()
    const chatMessageList = useSelector(state => state.chatReducer.chatMessageList)
    const totalResults = useSelector(state => state.chatReducer.chatMessageList.totalResults)
    const receiver = useSelector(state => state.chatReducer.receiverDetails)
    const sender = useSelector(state => state.userDetailsReducer.currentUser)
    const lastMessageId = useSelector(state => state.chatReducer.lastMessageId)
    const [loader,setLoader] = useState(false)
    const [disabledSendButton, setDisabledSendButton] = useState(true);
    const [messageContent, setMessageContent] = useState("");
    const [messageList, setMessageList] = useState([]);
    const [appendChat, setAppendChat] = useState(false)
    const [chatScrollHeight, setChatScrollHeight] = useState(0)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        const data = chatMessageList?.messages?.sort(
            (a, b) =>{
               return  new Date(a?.createdAt ? a?.createdAt : date) - new Date(b?.createdAt ? b?.createdAt : date)
            }

        );

        setMessageList(data);
    }, [chatMessageList?.messages]);

    const getMessages = () => {
        if(sender && receiver){
            const req = {
                chId:receiver?.chId,
                from_id: sender?._id,
                to_id: receiver?.to_id,
                last_id: '',
                limit:limit
            };
            socketSendRequest("getMsg", req);
            socketSendRequest("readMsg", {chId:receiver?.chId});
        }
    }

    const scrollToBottom = () => {
        if(messageList.length){
            var objDiv = document.querySelector(".chat_msg_container");
            objDiv.scrollTop = objDiv.scrollHeight
        }
    }

    useEffect(() => {
        if(!appendChat){
            scrollToBottom();
        }else{
            var objDiv = document.querySelector(".chat_msg_container");
            objDiv.scrollTop = objDiv.scrollHeight - chatScrollHeight;
        }

        getMessages()
        socket?.on("response", socketListener)
        return () => {
            socket?.off("response", socketListener)
        };
    }, [sender,receiver,messageList.length])

    const socketListener = (res) => {

        switch (res.evt) {
            case 'getMsg':
                setLoader(true)
                if(res.data){
                    setLoader(false)
                    dispatch({
                        type: CHAT_MESSAGE_LIST,
                        payload: res.data
                    });
                    if(!appendChat) setAppendChat(true)
                }
              
                break;

            case 'sendMsg':
                if(res.data){
                    dispatch({
                        type: SEND_MESSAGE,
                        payload: res.data
                    });
                }

                break;

            default:
                break;
        }
    }

    const handleTextMessageChange = (e) => {
        const { value } = e.target;
        if (value && value.trim().length) {
            setDisabledSendButton(false);
        } else {
            setDisabledSendButton(true);
        }
        setMessageContent(value);
    };

    const sendMessage = (e) => {
        e.preventDefault()
        const sendMsg = {
            from_id: sender?._id,
            to_id: receiver?.users?._id,
            from_type:"physician",
            to_type:"patients",
            message: messageContent
        };
        socketSendRequest("sendMsg", sendMsg);
        setMessageContent("");
        setDisabledSendButton(true);
    };

    const  chatListScroll = event => {

        if(messageList.length !== totalResults){
            if (appendChat === true && event.target.scrollTop === 0) {
                setChatScrollHeight(event.target.scrollHeight)
                setLimit(limit + 10)
                //   getMessages()
                const req = {
                    chId:receiver?.chId,
                    from_id: sender?._id,
                    to_id: receiver?.to_id,
                    last_id: messageList[0]?._id,
                    limit:limit + 10
                };
                setLoader(true)
                socketSendRequest("getMsg", req);
                //   this.getMessage();
            }
        }
    };

    return (
        <div className="clinics chat_area">
            <ChatHeader />
            <div className="chat_area_wrap">
                <div className="chat_msg_list_wrap">
                    <div className="chat_msg_container" onScroll={chatListScroll}>
                        <div className="chat_msg_list">
                            {
                                loader?
                                    <div className={'loader_chat'}>
                                        <img src={loader_Icon} alt={'loader'} />
                                        <p>Loading</p>
                                    </div>
                                    : ""

                            }
                            {messageList?.length > 0 &&
                            messageList?.map((item,i) => {
                                return(
                                    <>
                                        {
                                            item.from_type === "physician" && item.to_type === "patients" ?
                                                <div className="message_section message_left" >
                                                    <div className="msg_avtar">
                                                        <div className="avtar_img">
                                                            <img src={(sender?.profile === "NA" || sender?.profile === "" || sender?.profile === null) ? generateAvatar(`${sender?.first_name + " " + sender?.last_name}`) : sender?.profile} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='messages'>
                                                        <div className='user_message'>
                                                            <p className='user_title'>You</p>
                                                            <div className='user_time'><p>{moment(item?.createdAt).format("LT")}</p></div>
                                                        </div>
                                                        <div className='message_content'>
                                                            <div className='message_inner'>
                                                                <p>{item?.message}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="message_section message_left" >
                                                    <div className="msg_avtar">
                                                        <div className="avtar_img">
                                                            <img src={(receiver?.users?.profile === "NA" || receiver?.users?.profile === "" || receiver?.users?.profile === null) ? generateAvatar(`${receiver?.users?.first_name + " " + receiver?.users?.last_name}`) : receiver?.users?.profile} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='messages'>
                                                        <div className='user_message'>
                                                            <p className='user_title'>{receiver?.users?.first_name + " " + receiver?.users?.last_name}</p>
                                                            <div className='user_time'><p>{moment(item?.createdAt).format("LT")}</p></div>
                                                        </div>
                                                        <div className='message_content'>
                                                            <div className='message_inner'>
                                                                <p>{item?.message}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div className='chat_input_wrap'>
                        <div className='chat_input_block'>
                            <div className="chat_editor">
                                <div id="editor" className="container">
                                    <div className="msg_text">
                                        <input
                                            type="text"
                                            placeholder="Add a message..."
                                            value={messageContent}
                                            onChange={(e) => handleTextMessageChange(e)}
                                            onKeyPress={(event) => {
                                                if (
                                                    //   (event.key === "Enter" && messageContent.length !== 0) ||
                                                    (event.which === 13 || event.keyCode === 13) && !disabledSendButton
                                                ) {
                                                    sendMessage(event);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='message_action'>
                                <button className="message-icon send-message btn btn_primary"  onClick={sendMessage} disabled={disabledSendButton}>
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* } */}
        </div>
    )
}

export default ChatAreaComponent;
