import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const InvalidaAccountComponent = ({invalidModal,setInvalidModal,setIsForget}) =>  {

  const nextButtonClick = () => {
      setIsForget(true)
      setInvalidModal(false)
  }
  
  return (
    <Modal className='main_comman_modal authenticate_modal' isOpen={invalidModal} toggle={() => setInvalidModal(false)}>
        <ModalBody>
                <div className='main_delete_modal'>
                        <h4>Invalid Account credentials</h4>
                        <div className='delete_content'>
                            <p>We are unable to locate an account with the credentials provided. Please try again or click on Forgot Password to reset password. </p>
                        </div>
                        <div className="form_group d_flex justify_content_center form_action delete_modal_btn">
                            <button className="btn btn_default" onClick={() => setInvalidModal(false)}>Clear</button>
                            <button className="btn btn_primary" onClick={nextButtonClick}>Next</button>
                        </div>
                </div>
        </ModalBody>
    </Modal>
  )
}

export default InvalidaAccountComponent