import moment from 'moment';
import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {CREATE_ROOM_TOKEN, GET_AVAILABLE_MIUTES_URL, GET_SCHEDULE_URL} from '../../shared/constant';
import {mainGetServices, mainPostServices, socket, socketSendRequest} from '../../shared/service';
import {
    DELETE_APPOINMENT,
    EDIT_APPOINMENT,
    GET_AVAILABLE_MIUTES,
    GET_SCHEDULE,
    PHYSICIAN_SCHEDULE_COUNT,
    SELECT_RECEIVER_DETAILS,
    SET_FILTER_SCHEDULE
} from '../../store/action'
import Pagination from '../common/Pagination';
import Loader from '../Loader';
import Sweetalert from 'sweetalert2';
import {currentTime, formatPhoneNumber} from '../../utils';


const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}


const AppoimentBlockOne = ({ handleAppoinmentModal, handleDeleteAppoinMentModal, startMeetingVideo, className }) => {

    const currentUser = useSelector(state => state.userDetailsReducer.currentUser)
    const { availableMinutes, isUnlimited, billing_period_meeting_minutes } = useSelector(state => state.scheduleReducer.reqMinutes)

    const dispatch = useDispatch()
    const history = useHistory()
    const allSchedule = useSelector(state => state.scheduleReducer.allSchedule)
    const filters = useSelector(state => state.scheduleReducer.filters)
    const [waitingLoader, setWaitingLoader] = useState(true)


    const query = useQuery()
    const search = query.get("name")

    useEffect(() => {
        if (search) {
            getScheduleFromPatients()
        } else {
            getAllSchedule()

        }
        getAvailableMinutes()
    }, [dispatch,filters.status])

    const getAvailableMinutes = async () => {
        const { data, success } = await mainGetServices(GET_AVAILABLE_MIUTES_URL, {});
        if (success) {
            dispatch({ type: GET_AVAILABLE_MIUTES, payload: data })
        }
    }

    const getScheduleFromPatients = async () => {
        if (search) {
            dispatch({
                type: SET_FILTER_SCHEDULE, payload: {
                    search: search,
                    status: filters.status
                }
            })
            const req = {
                name: search,
                status: filters.status,
                page: 1,
                limit: 5
            }
            socketSendRequest("getCounts", {})
            const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_URL, req);
            if (success) {
                dispatch({ type: GET_SCHEDULE, payload: data })
                setWaitingLoader(false)
            } else {
                setWaitingLoader(false)
            }
        }
    }

    const getAllSchedule = async () => {
        const req = {
            status: filters.status,
            page: 1,
            limit: 5
        }

        const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_URL, req);
        if (success) {
            dispatch({ type: GET_SCHEDULE, payload: data })
            setWaitingLoader(false)
        } else {
            setWaitingLoader(false)
        }
    }

    const onPaginate = async (pageNumber) => {
        if (search) {
            dispatch({
                type: SET_FILTER_SCHEDULE, payload: {
                    search: search,
                    status: filters.status
                }
            })
            const req = {
                name: search,
                status: filters.status,
                page: pageNumber,
                limit: allSchedule.limit
            }
            const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_URL, req);
            if (success) {
                dispatch({ type: GET_SCHEDULE, payload: data })
                setWaitingLoader(false)
            }
        }
        else {
            const req = {
                status: filters.status,
                page: pageNumber,
                limit: allSchedule.limit
            }
            const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_URL, req);
            if (success) {
                dispatch({ type: GET_SCHEDULE, payload: data })
            }
        }
    }
    const handleNext = async () => {

        if (search) {
            dispatch({
                type: SET_FILTER_SCHEDULE, payload: {
                    search: search,
                    status: filters.status
                }
            })
            const req = {
                name: search,
                status: filters.status,
                page: allSchedule.page + 1,
                limit: allSchedule.limit
            }
            const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_URL, req);
            if (success) {
                dispatch({ type: GET_SCHEDULE, payload: data })
                setWaitingLoader(false)
            }
        } else {
            const req = {
                status: filters.status,
                page: allSchedule.page + 1,
                limit: allSchedule.limit
            }
            const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_URL, req);
            if (success) {
                dispatch({ type: GET_SCHEDULE, payload: data })
            }
        }

    }
    const handlePrevious = async () => {

        if (search) {
            dispatch({
                type: SET_FILTER_SCHEDULE, payload: {
                    search: search,
                    status: filters.status
                }
            })
            const req = {
                name: search,
                status: filters.status,
                page: allSchedule.page - 1,
                limit: allSchedule.limit
            }
            const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_URL, req);
            if (success) {
                dispatch({ type: GET_SCHEDULE, payload: data })
                setWaitingLoader(false)
            }
        } else {
            const req = {
                status: filters.status,
                page: allSchedule.page - 1,
                limit: allSchedule.limit
            }
            const { data, status, message, success } = await mainPostServices(GET_SCHEDULE_URL, req);
            if (success) {
                dispatch({ type: GET_SCHEDULE, payload: data })
            }
        }

    }

    const wantsToChangeAppoinMent = (data) => {
        handleAppoinmentModal(true)
        dispatch({ type: EDIT_APPOINMENT, payload: data })
    }

    const wantsToCancelAppoinMent = (id) => {
        handleDeleteAppoinMentModal(true)
        dispatch({ type: DELETE_APPOINMENT, payload: id })
    }

    const clickToChatWithSelectedPatient = (data) => {

        Object.assign(data, { to_id: data.users._id })
        dispatch({ type: SELECT_RECEIVER_DETAILS, payload: data });

        localStorage.setItem("receiver", JSON.stringify(data))
        history.push('/chat');
    };

    const makeVideoCall = async (schedule_id) => {
        const { data, success, message } = await mainPostServices(CREATE_ROOM_TOKEN, { schedule_id });
        if (success) {
            localStorage.setItem('video_token', data?.token)
            localStorage.setItem("room", JSON.stringify(data?.roomData))
            history.push('/video-call')
        } else {
            Sweetalert.fire({ text: message })
        }
    }

    const TimeDate = (date) => {
            let now = new Date();
            let end = new Date(date);
            var seconds = Math.round(now - end) / 1000
            console.log("seconds",Math.abs(Math.round(seconds)));
            return Math.abs(Math.round(seconds)) <= 300
    }

    const pastTime = (date) => {
        let now = new Date();
        let end = new Date(date);
        return now.getTime() > end.getTime()
    }

    useEffect(() => {
        socketSendRequest("getCounts", {})
        socket?.on("response", socketListener)
        return () => {
            socket?.off("response", socketListener)
        };
    }, [currentUser,filters])

    const socketListener = (res) => {
        switch (res.evt) {
            case 'getCounts':
                if (res.data) {
                    dispatch({ type: PHYSICIAN_SCHEDULE_COUNT, payload: res.data })
                }
                break;
            default:
                break;
        }
    }

    return (
        <div className={`schedule_appoinment--block1 ${className}`}>
            <div className="prev_appoinment">
                <table className="patient_prev_appoinment">
                    <thead className="schedule_appoinment_thead">
                        <tr className="patient_prev_appoinment_head">
                            <th className="date_head">Date/Time {`(${currentTime.format("z")})`}</th>
                            <th className="patient_head">Patient</th>
                            <th className="email_head">Email/Phone</th>
                            <th className='minutes_head'> Min Total/Available: {isUnlimited ? 'Unlimited' : ` ${billing_period_meeting_minutes !== undefined ? billing_period_meeting_minutes : 0}/${Math.floor(Number(availableMinutes))} `}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {waitingLoader ?
                            <tr>
                                <td className='td_loader'>
                                    <div className='spin_loder'>
                                        <Loader className="data_load" />
                                    </div>
                                </td>
                            </tr>
                            :
                            allSchedule.scheduleList.length > 0 ?
                                allSchedule.scheduleList.map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <tr className="tr_sp_details">
                                                <td className="date_time td_sp"> <h5 className="date">{moment(item?.date).local().format("MM/DD/YYYY")}</h5> <p className="time">{moment.utc(item?.date).local().format("hh:mm A")} ({item?.callLength + "m"})</p> </td>
                                                <td className="patient_name_div td_sp text_capitalize"> <h5 className="patient_name">{item?.patient_id?.first_name + " " + item?.patient_id?.last_name}</h5>
                                                    <p className="send_messege" onClick={() => clickToChatWithSelectedPatient({ users: item.patient_id })}>send message</p>
                                                </td>
                                                <td className="patient_email_div td_sp">  <h5 className="patient_email">{item?.patient_id?.email} <br /> {formatPhoneNumber(item?.patient_id?.phone)}</h5>  </td>
                                                {!item?.isCompleted &&
                                                    <td className="cancel_changes_div td_sp" rowSpan={2}>
                                                        <div className="await_schedule_wrap">
                                                            <span className="await_wrap">{item?.status === 'pending' && !item.changeRequest ? "AWAITING CONFIRMATION" : ""}</span>
                                                            <h3 className={`schedulePatients_status ${item?.status === 'pending' && item?.changeRequest === 'pending' ? "request_chng" : item?.status === 'pending' && item?.changeRequest === 'rejected' ? "request_chng" : item?.status === 'pending' ? "pending" : ""}`}>
                                                                {item?.status === 'pending' && item?.changeRequest === 'pending' ? "Requested Change" : item?.status === 'pending' && item?.changeRequest === 'rejected' ? "Decline" : item?.status === 'pending' ? "Pending" : ""}
                                                            </h3>
                                                        </div>

                                                        <div className='main_cancle_chngs_block'>
                                                            <p className="cancel_changes" onClick={() => wantsToCancelAppoinMent(item?._id)}> CANCEL</p>
                                                            {item?.status === 'pending' && item?.changeRequest === 'pending' && <p className="cancel_changes" onClick={() => wantsToChangeAppoinMent(item)}> CHANGE</p>}
                                                            {item?.status === 'accepted' && <p className="cancel_changes" onClick={() => wantsToChangeAppoinMent(item)}> CHANGE</p>}
                                                        </div>
                                                        {   (moment.utc(item.date).local()).isSame(moment(), 'day') && (pastTime(item?.date) || TimeDate(item?.date))  ?
                                                            <div className=" startbtn_div">
                                                                {(item?.status === 'accepted') && <button className="btn btn_primary start" onClick={() => makeVideoCall(item?._id)}>Start</button>}
                                                            </div>
                                                            :
                                                            moment().isBefore(moment.utc(item.date).local()) &&
                                                            <div className=" startbtn_div">
                                                                {(item?.status === 'accepted') && <button className="btn btn_default start" onClick={() => startMeetingVideo(true)}>Start</button>}
                                                            </div>
                                                        }
                                                        {/*<div className=" startbtn_div">*/}
                                                        {/*    {(item?.status === 'accepted') && <button className="btn btn_primary start" onClick={() => makeVideoCall(item?._id)}>Start</button>}*/}
                                                        {/*</div>*/}
                                                    </td>
                                                }
                                            </tr>
                                            <tr className="patient_text_div" >
                                                <td colSpan={3}>
                                                    <div className={`patient_text_wrap ${item?.note === undefined || item?.note === '' ? 'blank_div' : ''}`}>
                                                        <div className='content_block'>
                                                            {item?.status === 'pending' && item?.changeRequest === 'pending' && <p className='date_'>{`change to ${moment.utc(item?.requestDate).local().format("MMMM DD")} , ${item.changeTime}`}</p>}
                                                            <p className="patient_text">{item?.note}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })
                                :
                                <tr><td colSpan={9}><div className="fullWidth text_center no_data_block">No Data Found</div></td></tr>
                        }
                    </tbody>
                </table>

            </div>

            {
                allSchedule?.totalResults > 5 &&
                <Pagination
                    page={allSchedule.page}
                    limit={allSchedule.limit}
                    totalResults={allSchedule.totalResults}
                    paginate={onPaginate}
                    next={handleNext}
                    prev={handlePrevious}
                />
            }

        </div>
    )
}

export default AppoimentBlockOne
