import React, { useState } from 'react'
import { UPDATE_PATIENT_STATUS_URL } from '../../shared/constant';
import { mainPostServices } from '../../shared/service';
import { GET_PATIENTS, UPDATE_PATIENT_STATUS } from '../../store/action';
import Sweetalert from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import cross_icon from "../../assets/images/cross-icon.png";
import Loader from '../Loader';

const ActiveInactivePopup = ({ activeInactiveModal, setActiveInactiveModal, statusId, setWaitingLoader, url }) => {
    const dispatch = useDispatch()
    const [btnLoading, setbtnLoading] = useState(false)
    const allPatients = useSelector(state => state.patientsReducer.allPatients)

    const doChangeStatus = async (e) => {
        e.preventDefault()
        setbtnLoading(true)
        const { success } = await mainPostServices(UPDATE_PATIENT_STATUS_URL, { id: statusId?.id, status: !statusId?.active });
        if (success) {
            dispatch({
                type: UPDATE_PATIENT_STATUS,
                payload: { id: statusId.id, status: !statusId.active }
            })
            Sweetalert.fire({ title: 'Success', text: "Status Updated Successfully" })
            getAllPatients()
            setActiveInactiveModal(false)
            setbtnLoading(false)
        } else {
            setbtnLoading(false)
        }
    }

    const getAllPatients = async () => {
        const req = {
            page: allPatients.page,
            limit: allPatients.limit
        }
        const { data, success } = await mainPostServices(url, req);
        if (success) {
            dispatch({ type: GET_PATIENTS, payload: data })
            setWaitingLoader(false)
        } else {
            setWaitingLoader(false)
        }
    }

    return (
        activeInactiveModal &&
        <div className='modal'>
            <div className='main_delete_modal main_comman_modal update-patient_modal'>
                <div className='close_modal'>
                    <img src={cross_icon} alt='cross' onClick={() => setActiveInactiveModal(false)} />
                </div>
                <h4>Update A Patient Status?</h4>
                <div className='delete_content'>
                    <p>You are about to Update A Patient Status. </p>
                </div>
                <form onSubmit={doChangeStatus} className="form_group d_flex justify_content_center form_action delete_modal_btn">
                    <button id="saveBtn" className='btn btn_default' type="submit">
                        {btnLoading ?
                            <Loader color="primary" className="btn_loader_red" size="sm" />
                            :
                            "Yes"
                        }
                    </button>
                    <button className="btn btn_primary" type={'reset'} onClick={() => setActiveInactiveModal(false)}>No</button>
                </form>
            </div>
        </div>
    )
}

export default ActiveInactivePopup
