import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { isUserLoggedIn } from './shared/function';

export const PrivateRoute = ({ component: Component,title ,...rest }) => (
    <Route {...rest} render={props => {
        if (!isUserLoggedIn()) {
            return <Redirect to={{ pathname: '/login' }} />
        }
        return (
            <>
            <Helmet>
                <title>{'Haploscope MD'}</title>
            </Helmet>
            <Component {...props} />
            </>
        )
        
    }} />
)