import React, { useEffect, useRef, useState } from 'react';
import avatar from "../../assets/images/avatar.png";
import icon_plus from "../../assets/images/icon_plus.svg";
import Loader from '../Loader';
import { Modal, ModalBody } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import cross_icon from "../../assets/images/cross-icon.png";
import { formatPhoneNumber } from '../../shared/function';
import { mainPostServices } from '../../shared/service';
import { GET_ALL_PATIENTS_URL, GET_PATIENTS_URL, UPDATE_PATIENT_URL } from '../../shared/constant';
import Sweetalert from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { GET_PATIENTS, UPDATE_PATIENTS_DATA } from '../../store/action';

const EditPatient = ({ patientModal, setEditModal }) => {
    let url;
    const dispatch = useDispatch()
    const [, forceUpdate] = useState();
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))
    const allPatients = useSelector(state => state.patientsReducer.allPatients)
    const filters = useSelector(state => state.patientsReducer.filters)
    const editPatientState = useSelector(state => state.patientsReducer.editPatientState)
    const [phoneTypeError,setPhoneTypeError]=useState(false)
    url = filters.status === 'all' ? GET_ALL_PATIENTS_URL : GET_PATIENTS_URL

    const [patientState, setPatientState] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        phone_type: ''
    })
    const [image, setImage] = useState('')
    const [formDataImage, setFormDataImage] = useState('')
    const [btnLoading, setbtnLoading] = useState(false)


    useEffect(() => {
        if (editPatientState) {
            setPatientState({
                ...patientState,
                first_name: editPatientState.first_name,
                last_name: editPatientState.last_name,
                email: editPatientState.email,
                phone: formatPhoneNumber(editPatientState.phone) ,
                phone_type: editPatientState.phone_type
            })
            setImage(editPatientState.profile)
        }
    }, [editPatientState])

    const getAllPatients = async () => {
        const req = {
            page: allPatients.page,
            limit: allPatients.limit
        }
        const { data, success } = await mainPostServices(url, req);
        if (success) {
            dispatch({ type: GET_PATIENTS, payload: data })
        }
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
                setImage(fileReader.result)
            };
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    };

    const hnaldeProfileChange = (e) => {
        setFormDataImage(e.target.files[0])
        convertBase64(e.target.files[0])
    }

    const handleInputChnage = (e) => {
        const { name, value } = e.target
        if (name === 'phone') {
            setPatientState({
                ...patientState,
                [name]: formatPhoneNumber(value)
            })
        } else {
            setPatientState({
                ...patientState,
                [name]: name === 'email' ? value.trim() : value
            })
        }
    }
    useEffect(()=>{
        if(patientState?.phone_type){
            setPhoneTypeError(false)
        }

    },[patientState])

    const handleSubmit = (e) => {
        e.preventDefault();
        if(patientState.phone && !patientState?.phone_type){
            setPhoneTypeError(true)
        }else{
            if (simpleValidator.current.allValid()) {
                savePatient(patientState)
            } else {
                simpleValidator.current.showMessages();
                forceUpdate(1)
            }
        }
    }

    const savePatient = async (state) => {
        setbtnLoading(true)
        // document.getElementById("saveBtn").classList.add('disabled')
        if (state.phone) Object.assign(state, { phone: state?.phone?.replace(/[ ()-]/g, "") })
        if (state.phone_type) Object.assign(state, { phone_type: state?.phone_type })
        let formData = new FormData();
        Object.keys(state).forEach(key => {
            formData.append(`patient[${key}]`, state[key])
        })
        if (formDataImage) {
            formData.append("image", formDataImage)
        }
        if (editPatientState) formData.append("patient[user_id]", editPatientState._id)
        const { data, message, success } = await mainPostServices(UPDATE_PATIENT_URL, formData);
        if (success) {
            setbtnLoading(false)
            document.getElementById("saveBtn").classList.remove('disabled')
            resetForm()
            dispatch({
                type: UPDATE_PATIENTS_DATA,
                payload: { id: editPatientState._id, result: data.patient }
            })
            getAllPatients()
            Sweetalert.fire({ title: 'Success', text: message })
        } else {
            setbtnLoading(false)
            Sweetalert.fire({title: 'error', text: message })
        }
    }

    const resetForm = () => {
        setEditModal(false)
    }

    const clearFormState = () => {
        setPatientState({
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            phone_type: ''
        })
        setImage('')
        simpleValidator.current.hideMessages()
    }

    return (
        <Modal isOpen={patientModal} toggle={() => setEditModal(false)} className='main_comman_modal addPatientModal'>
            <div className='close_modal' onClick={resetForm}>
                <img src={cross_icon} alt='cross' />
            </div>
            <ModalBody>
                <div className="custom_modal">
                    <h3 className="form_heading text_center">{"Edit a Patient"}</h3>
                    <form>
                        <div className="form authorized_form">
                            <div className="form_group authorized_profile">
                                <div className="user_profile">
                                    <div className="user_profile_pic">
                                        <img src={image ? image : avatar} alt="" />
                                        <span className='addnew'>
                                            <img src={icon_plus} alt="" />
                                            <input type="file" name="image" onChange={hnaldeProfileChange} />
                                        </span>
                                    </div>
                                    <label htmlFor="">Profile Photo</label>
                                </div>
                            </div>
                            <div className='authorized_form_model'>
                                <div className="form_row">
                                    <div className="form_group col-6">
                                        <label htmlFor="">First Name</label>
                                        <span className={`fill ${simpleValidator.current.check(patientState.first_name, 'required|alpha') ? "valid" : "invalid"}`}>
                                            <input
                                                type="text"
                                                name="first_name"
                                                className="form_control"
                                                onChange={handleInputChnage}
                                                value={patientState.first_name}
                                            />
                                        </span>
                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                            {simpleValidator.current.message('first_name', patientState.first_name, 'required')}
                                        </div>
                                    </div>
                                    <div className="form_group col-6">
                                        <label htmlFor="">Last Name</label>
                                        <span className={`fill ${simpleValidator.current.check(patientState.last_name, 'required|alpha') ? "valid" : "invalid"}`}>
                                            <input
                                                type="text"
                                                className="form_control"
                                                name="last_name"
                                                onChange={handleInputChnage}
                                                value={patientState.last_name}
                                            />
                                        </span>
                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                            {simpleValidator.current.message('last_name', patientState.last_name, 'required',)}
                                        </div>
                                    </div>
                                </div>
                                <div className="form_group">
                                    <label htmlFor="">Email</label>
                                    <span className={`fill ${simpleValidator.current.check(patientState.email, 'required|email') ? "valid" : "invalid"}`}>
                                        <input
                                            type="text"
                                            name="email" id=""
                                            className="form_control"
                                            onChange={handleInputChnage}
                                            value={patientState.email}
                                        />
                                    </span>
                                    <div className="invalid-feedback pl-4 d-block errorMsg">
                                        {simpleValidator.current.message('email', patientState.email, 'required|email')}
                                    </div>
                                </div>
                                <div className="form_row">
                                    <div className="form_group col-6">
                                        <label htmlFor="">Contact Phone</label>
                                        <input
                                            type="tel"
                                            inputMode="numeric"
                                            maxLength={10}
                                            className={`form_control`}
                                            placeholder='(222) 222-2222'
                                            name="phone"
                                            onChange={handleInputChnage}
                                            value={formatPhoneNumber(patientState?.phone)}
                                        />
                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                            {simpleValidator.current.message('phone', patientState.phone, 'min:10',)}
                                        </div>
                                    </div>

                                    <div className="form_group col-6">
                                        <label htmlFor="">Phone Type</label>
                                        <select
                                            name="phone_type"
                                            className={`form_control`}
                                            onChange={handleInputChnage}
                                            value={patientState.phone_type}
                                        >
                                            <option value={''}  >Select from below</option>
                                            <option value={'Mobile'}  >Mobile</option>
                                            <option value={'Work'}  >Work</option>
                                        </select>

                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                            {
                                                phoneTypeError &&
                                                <p>Phone Type is Required</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="form_group d_flex justify_content_center form_action mt_5">
                                    <button type={'reset'} className='btn btn_default' onClick={clearFormState}>Clear</button>
                                    <button type={'button'} id="saveBtn" className='btn btn_primary ml_2' onClick={(e) => handleSubmit(e)}>
                                        {btnLoading ?
                                            <Loader color="primary" size="sm" />
                                            :
                                            "Submit"
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default EditPatient;
