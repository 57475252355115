import axios from "axios";
import Cookies from 'js-cookie';

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axiosClient.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        let res = error.response;
        if (res.status === 401) {
            delete axiosClient.defaults.headers.common["x-access-token"]
            localStorage.clear()
            document.cookie = 'x-access-token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            if(window.location.hostname === 'stagemd.haploscope.com'){
                window.location.href = "https://stagemd.haploscope.com/login";
            }else if (window.location.hostname === 'devmd.haploscope.com'){
                window.location.href = "https://devmd.haploscope.com/login";
            }else{
                window.location.href = "https://md.haploscope.com/login";
            }
        }
        console.error("Looks like there was a problem. Status Code:" + res.status);
        return Promise.reject(error);
    }
);

axiosClient.interceptors.request.use(function (config) {
    const token = Cookies.get('x-access-token');
    config.headers['x-access-token'] = token || '';
    return config;
});

export default axiosClient;