import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SELECT_RECEIVER_DETAILS } from '../../store/action';
import {generateAvatar} from "../../utils";

const ChatHeaderComponent = () => {
  const receiverDetails = useSelector(state => state.chatReducer.receiverDetails)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({ type: SELECT_RECEIVER_DETAILS, payload:JSON.parse(localStorage.getItem('receiver'))});
  },[])
  return (
    <div className="heading_content">
        <div className='sub_header_row'>
            <div className="chat_profile">
                <span className="profile_pic">
                    <img id="profileIcon" src={ (!receiverDetails?.users?.profile || receiverDetails?.users?.profile === "NA" || receiverDetails?.users?.profile === "" || receiverDetails?.users?.profile === null) ? generateAvatar(`${receiverDetails?.users?.first_name + " " + receiverDetails?.users?.last_name}`) : receiverDetails?.users?.profile} alt="profile_pic" />
                </span>
                <span className="profile_name">{receiverDetails?.users?.first_name + " " + receiverDetails?.users?.last_name}</span>
            </div>
        </div>
    </div>
  );
}

export default ChatHeaderComponent;
