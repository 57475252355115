import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { socketSendRequest } from '../../shared/service';
import { PHYSICIANS_MESSAGE_LIST, SEARCH_MESSAGE } from '../../store/action';

const MessageHeader = () => {

  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const physicianMessage = useSelector(state => state.chatReducer.physicianMessage)
  const page = useSelector(state => state.chatReducer.physicianMessage.page)
  const limit = useSelector(state => state.chatReducer.physicianMessage.limit)

  const handleMessageSearch = async (keyword) => {
    if(keyword){
      setSearch(keyword)
      const filteredPhysician = physicianMessage?.patients?.filter(item => {
        const name = item?.users?.first_name + " " + item?.users?.last_name
        return name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
      });
      if(filteredPhysician.length > 0){
        const data = {
          docs:filteredPhysician,
          perPage:limit,
          page:page,
          totalPages:Math.ceil(filteredPhysician.length / limit),
          totalResults:filteredPhysician?.length
        }
        dispatch({ type: PHYSICIANS_MESSAGE_LIST, payload: data });
        dispatch({ type: SEARCH_MESSAGE, payload: {search:keyword,filter:true}})
      }else{
        dispatch({ type: SEARCH_MESSAGE, payload: {search:keyword,filter:false}})
      }
    }else{
        setSearch('')
        dispatch({ type: SEARCH_MESSAGE, payload: {search:'',filter:false}})
        const req = {
          page: page,
          limit: limit
      }
        socketSendRequest("getPhysicianMsgList", req)
    }
  }

  return (
    <div className="heading_content">
        <div className='sub_header_row'>
            <form className="form_group">
                <input type="text" placeholder="Search" className="form_control search_input" name="search" onChange={(e) => handleMessageSearch(e.target.value)} value={search}/>
                <span className="search_icon">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHRSURBVHgBrVTLbcJAEJ01H3EkHTgd2BckxCGhhFQQOggdEDrAHUAFlAAXBDc7FYQSOCK+eW/ZtRbH4GDlSZbX6503b2ZnRkkO4jhu7na7vud5L+fzOcBWE89GKZXgnRwOh6jdbq/lBlSWbL/ff2L5IQWAs1G9Xh+GYbjJ/vPsYrlc+iCMHcIxntdarfbUarUU3/w2+wLVfUQT0y5XqVFIQh8K1gjvrdPpJDdEagGVSmVmz0Nx6CrWSk3I+sDpdOreIySYz+Px2MVyDcU+FA+ulBqv3/zAwed7F5DFYrEIkBZGKEyPVetVq9U0h48QEiaiMddIWXq5DD+wpFICiG7CN1L3mpKaOqT8LymBRqNh8x+4SlnYkldvf4Fj13RJ9SbLSkrAsbsqKS1/u90GUgK4IGuXlqGHOptzgbJ6lxLAnWg7MxcupCipyKx7rDt5AKZFe3JRHKWkTDS8jPgBB9O8Xr5FaFpVDxe3xj1DNhTTchh3syJiRmR736icuP9VjmffbI3hZAKHCaPhLfNSTA57LomdGVbtr3kKwwEO9aUADJkK0TRTcYYRiVWeAVVzJphuSyc/ngR7c4y6yBZ9dgySWMk/wCUGaWGUDxGvVis9qX4ArLAOUu34bGcAAAAASUVORK5CYII=" alt="Search_Icon"/>
                </span>
            </form>
        </div>
    </div>
  )
}

export default MessageHeader;
