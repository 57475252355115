import React, { Fragment, useRef, useState } from "react";
import mobile_md_logo from "../../../../assets/images/mobile_md_logo.svg";
import mobile_vector from "../../../../assets/images/mobile_vector.png";
import mobile_eye_open from "../../../../assets/images/mob_eye_open.svg";
import mob_eye_close from "../../../../assets/images/mob_eye_close.svg";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { mainPostServices } from "../../../../shared/service";
import { SIGNUP_API_URL } from "../../../../shared/constant";
import Sweetalert from "sweetalert2";
import AuthenticatePop from "./AuthenticatePoup";
import Loader from "../../../Loader";
import PhysicianRegistrationPopup from "./PhysicianRegistrationPopup";
let CryptoJS = require("crypto-js");

const MobileViewSignup = () => {
    const { search } = useLocation();
    const history = useHistory()
    const dispatch = useDispatch()
    const [, updateState] = useState();
    const [btnLoading, setBtnLoading] = useState(false)
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [viewPassWord, setViewPassWord] = useState(false)
    const [ViewConPassWord, setViewConPassWord] = useState(false)
    const [authVerifyModal, setAuthVerifyModal] = useState(false);
    const [counterTime, setCounterTime] = useState(0);
    const [prModal, setPrModal] = useState(false);
    const [registrationCode,setRegistrationCode]=useState(false)
    //We were unable to locate this registration code. Please reach out to your care center support for a valid registration code OR try again.
    const simpleValidator = useRef(new SimpleReactValidator({

        validators: {
            password: {
                message: "Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit.",
                // eslint-disable-next-line
                rule: (val, params, validator) => validator.helpers.testRegex(val.trim(), /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/), required: true, // optional
            }
        },
    }))


    let decryptedData;
    let decrypted = CryptoJS.AES.decrypt(search?.substring(1)?.toString(), process.env.REACT_APP_SECRET_KEY);
    if (search) {
        decryptedData = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    } else {
        history.push("/")
    }
    const [registrationState, setRegistrationState] = useState({
        institution_registration_code: '',
        email: decryptedData?.email,
        password: '',
        confirmPassword: ""
    })

    const togglePassWordHideShow = (pwdShow) => {
        setViewPassWord(pwdShow)
    }
    const handleLoginInputChange = (e) => {
        setRegistrationCode(false)
        const { name, value } = e.target
        setRegistrationState({
            ...registrationState,
            [name]: value
        })
    }

    const toggleConPassWordHideShow = (pwdShow) => {
        setViewConPassWord(pwdShow)
    }
    const signUpHandler = (e) => {
        e.preventDefault()
        if (simpleValidator.current.allValid()) {
            SignUpApiHandler(registrationState)
            setBtnLoading(true)
        } else {
            simpleValidator.current.showMessages();
            forceUpdate()
        }
    }
console.log()
    const SignUpApiHandler = async (req) => {
        debugger
        const { message, success, data } = await mainPostServices(SIGNUP_API_URL, req);
        if (success) {
            debugger
            setCounterTime(30)
            setAuthVerifyModal(true)
            setPrModal(false);
            setBtnLoading(false)
        } else {
            setBtnLoading(false)
            setRegistrationCode(true)
            // Sweetalert.fire({
            //     title: 'Error',
            //     text: message
            // })

        }
    }

    const clearFormData = () => {
        setRegistrationState({
            institution_registration_code: '',
            email: decryptedData?.email,
            password: '',
            confirmPassword: ""
        })
        simpleValidator.current.hideMessages();
        forceUpdate()
    }
    const OpenPopupVactor = ()=>{
        setPrModal(true);
        setBtnLoading(false)
    }
    console.log(simpleValidator.current)
    return (
        <>
            <section className="hp_registration-sec">
                <div className="container">
                    {/* logo */}
                    <div className="hp_reg-logo">
                        <a href="#">
                            <img src={mobile_md_logo} alt='logo' />
                        </a>
                    </div>
                    {/* form block */}
                    <div className="hp_reg_form-Block">
                        <form onSubmit={signUpHandler} onReset={clearFormData}>
                            <div className="hp_reg_signup-title">
                                <h2>Physician Sign Up</h2>
                                <img src={mobile_vector} alt={'mobile_vector'} onClick={()=>OpenPopupVactor()}/>
                            </div>

                            {/* input fields */}
                            <div className={simpleValidator.current.errorMessages.institution_registration_code ? "hp_reg_inputField" : "hp_reg_inputField"}>
                                <input type='text'
                                    placeholder='Physician Registration Code'
                                    name="institution_registration_code"
                                    value={registrationState?.institution_registration_code}
                                    autoComplete="off"
                                    onChange={handleLoginInputChange} />
                                <span className="hp_reg-error">
                                    {
                                        registrationCode ?
                                            <span className={'srv-validation-message'}> We were unable to locate this registration code. Please reach out to your care center support for a valid registration code OR try again.</span>
                                            :
                                            ""
                                    }
                                    {simpleValidator.current.message('institution_registration_code', registrationState.institution_registration_code, 'required')}
                                </span>
                            </div>

                            <div className="hp_reg_inputField">
                                <input type='text'
                                    placeholder='Email'
                                    autoComplete="off"
                                    value={registrationState?.email}
                                    disabled={true} />
                            </div>
                            <div className={simpleValidator.current.errorMessages.password ? "hp_reg_inputField" : "hp_reg_inputField "}>
                                <div className="inner_inputField_block">
                                    <input
                                        type={viewPassWord ? "text" : "password"}
                                        className='Password'
                                        placeholder="Password"
                                        name="password"
                                        autoComplete="off"
                                        value={registrationState?.password}
                                        onChange={handleLoginInputChange}
                                    />
                                    <span className="eye_icon"> <img src={viewPassWord ? mobile_eye_open : mob_eye_close} alt="icone_hide_password" onClick={() => togglePassWordHideShow(!viewPassWord)} /></span>
                                </div>
                                <span className={`hp_reg-error password_error`}>{simpleValidator.current.message('password', registrationState.password, `required|password`)}</span>
                            </div>

                            <div className={simpleValidator.current.errorMessages.confirmPassword ? "hp_reg_inputField" : "hp_reg_inputField "}>
                                <div className="inner_inputField_block">
                                    <input className='Password' type={ViewConPassWord ? "text" : "password"} name="confirmPassword" placeholder='Confirm Password' value={registrationState?.confirmPassword}
                                        onChange={handleLoginInputChange} />
                                    <span className="eye_icon">
                                        <img src={ViewConPassWord ? mobile_eye_open : mob_eye_close} alt="icone_hide_password" onClick={() => toggleConPassWordHideShow(!ViewConPassWord)} />
                                    </span>
                                </div>
                                <span className="hp_reg-error"> {simpleValidator.current.message('confirmPassword', registrationState.confirmPassword, `required|in:${registrationState.password}`, { messages: { in: 'Confirm Password & Password Does not Match!!' } })}</span>
                                {/* here add error msg */}
                            </div>

                            {/* next and clear buttons */}

                            <div className="mob_hp_reg-buttons">
                                <button className="hp_reg-btn clear_btn" type="reset">clear</button>

                                <button type={'submit'} className="hp_reg-btn next_btn">  {btnLoading ?
                                    <Loader color="primary" size="sm" />
                                    :
                                    "Next"
                                }</button>
                            </div>
                            {/* <div className="add_signin_link">Already a member? <a href="#" onClick={() => history.goBack()}>Sign In</a></div> */}
                        </form>
                    </div>
                </div>
            </section>

            {/* footer */}
            <footer className="mobile_reg-footer">
                <p>By joining, you are agreeing to our {" "}
                    <a href="https://haploscope.com/tos.html">Terms of Service</a> and <a href="https://haploscope.com/privacy.html"> Privacy Policy</a></p>
            </footer>

            {
                authVerifyModal &&
                <AuthenticatePop authVerifyModal={authVerifyModal} setAuthVerifyModal={setAuthVerifyModal} email={registrationState?.email} counterTime={counterTime} />
            }
            {
                prModal && <PhysicianRegistrationPopup className={prModal} close={() => setPrModal(!prModal)} />
            }
        </>
    )
}

export default MobileViewSignup