import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/sass/app.scss';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from "react-redux";
import { PrivateRoute } from './PrivateRouter';
import store from './store';

import SignIn from './components/authentication/SignIn';
import PatientsComponent from './components/Patients';
import ScheduleComponent from './components/Schedule';
import MessagesComponent from './components/Messages';

import { ToastContainer } from 'react-toastify';
import { setToken } from './shared/service';
import ChatAreaComponent from './components/Messages/ChatArea';
import Signup from "./components/authentication/Signup"
import SettingComponent from './components/authentication/Setting';
import ResetPassword from './components/authentication/ResetPassword';
import PhysicianDetail from './components/PhysicianDetail'
import CallScreenComponent from './components/VideoCall/callScreen'
import ResetSuccessScreen from "./components/HealthRestPassword/ResetSuccessScreen";
import HealthResetPassword from "./components/HealthRestPassword/ResetPassword";
import SignupSuccessScreen from "./components/authentication/SignupComponet/MobileViweSignup/SignupSuccessScreen";

if (localStorage.getItem("token")) {
  setToken(localStorage.getItem("token"))
} else {
  setToken(false)
}

const Root = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/signup" component={Signup} />
          <Route path="/login" component={SignIn} />
          <Route path="/details" component={PhysicianDetail} render={() => {
            !localStorage.getItem("token") ? <PhysicianDetail /> : <Redirect to={{ pathname: "/schedule" }} />
          }} />
          <Route path="/reset-password" component={ResetPassword} render={() => {
            !localStorage.getItem("token") ? <ResetPassword /> : <Redirect to={{ pathname: "/schedule" }} />
          }} />
          <Route path="/reset-password-landing" component={ResetSuccessScreen} render={() => {
            !localStorage.getItem("token") ? <ResetSuccessScreen /> : <Redirect to={{ pathname: "/schedule" }} />
          }} />
          <Route path="/health-reset-password" component={HealthResetPassword} render={() => {
            !localStorage.getItem("token") ? <HealthResetPassword /> : <Redirect to={{ pathname: "/schedule" }} />
          }} />
          <Route path="/signUp-success-landing" component={SignupSuccessScreen} render={() => {
            !localStorage.getItem("token") ? <SignupSuccessScreen /> : <Redirect to={{ pathname: "/schedule" }} />
          }} />
          <Route path="/video-call" component={CallScreenComponent} title={"Video Call"} />
          <App>
            <Route
              exact
              path={"/"}
              render={() => {
                return <Redirect to={{ pathname: "/schedule" }} />;
              }}
            />
            <Switch>
              <PrivateRoute path="/schedule" exact component={ScheduleComponent} title={"Schedule"} />
              <PrivateRoute path="/patients" component={PatientsComponent} title={"Patients"} />
              <PrivateRoute path="/messages" component={MessagesComponent} title={"Messages"} />
              <PrivateRoute path="/chat" component={ChatAreaComponent} title={"Chat"} />
              <PrivateRoute path="/setting" component={SettingComponent} title={"Setting"} />

            </Switch>
          </App>
        </Switch>
      </Router>
      <ToastContainer />
    </Provider>
  )

}
ReactDOM.render(
  <Root />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
