import {
  CHAT_MESSAGE_LIST,
  PHYSICIANS_MESSAGE_LIST,
    SCROLL_TOP,
    SELECT_RECEIVER_DETAILS,
    SEND_MESSAGE,
    SOCKET_CONNECTION,
  PHYSICIAN_NOTIFICATION_LIST,
  PHYSICIAN_MESSAGE_COUNT,
  SEARCH_MESSAGE,
  DATA_WAITING_LOADER
  } from "../action";
//   import Cookies from "universal-cookie";
//   const cookies = new Cookies();
  
  const initialState = {
    socketConnect: false,
    receiverDetails: null,
    physicianMessage:{
      patients:[],
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0 
    },
    chatMessageList:{
      messages:[],
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0 
    },
    physicianNotification:{
      notification:[],
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0
    },
    lastMessageId:null,
    scrollTop:0,

    notificationCount:0,

    searchMessage:'',
    messageFilterData:false,

    waitingLoader:true

  };
  
  export default function chatReducer(state = initialState, action) {
    switch (action.type) {

      case SOCKET_CONNECTION:
        return {
          ...state,
          socketConnect: action.payload,
        };

      case SELECT_RECEIVER_DETAILS:
        return {
          ...state,
          receiverDetails: action.payload,
          lastMessageId:action.payload?.lastMessage?._id
        };

      case PHYSICIANS_MESSAGE_LIST:

        return {
          ...state,
          physicianMessage:{
            patients:action.payload.docs,
            limit:action.payload.perPage,
            page:action.payload.currentPage,
            totalPages:action.payload.totalPages,
            totalResults:action.payload.total
        }
      }

      case CHAT_MESSAGE_LIST:
        return {
          ...state,
          chatMessageList:{
            messages:action.payload.docs,
            limit:action.payload.perPage,
            page:action.payload.currentPage,
            totalPages:action.payload.totalPages,
            totalResults:action.payload.total
        }
      };

      case SEND_MESSAGE:
        return {
          ...state,
          chatMessageList:{
            // messages:action.payload.msglist,
            messages: [...state.chatMessageList.messages,action.payload.msglist]
        },
        lastMessageId:action?.payload?.msglist?._id
      };

      case PHYSICIAN_NOTIFICATION_LIST:{
        return {
          ...state,
          physicianNotification:{
            notification:action.payload.append ? state.physicianNotification.notification.concat(action.payload.data.docs) : action.payload.data.docs,
            limit:action.payload.data.perPage,
            page:action.payload.data.currentPage,
            totalPages:action.payload.data.totalPages,
            totalResults:action.payload.data.total
          }
        };
      }

      case PHYSICIAN_MESSAGE_COUNT:
        return {
          ...state,
          notificationCount:action.payload
        };

      case SEARCH_MESSAGE:
        return {
          ...state,
          searchMessage:action.payload.search,
          messageFilterData:action.payload.filter,
        };


      case DATA_WAITING_LOADER:
        return {
          ...state,
          waitingLoader:action.payload
        };
  
      // case SCROLL_TOP:
      //   return {
      //     ...state,
      //     scrollTop:action.payload
      // };
  
      default:
        return state;
    }
  }
