import { SET_USER_PROFILE } from "../action";

const initState = {
    currentUser:null
}

export default function userDetailsReducer(state=initState,action){
    switch (action.type) {
        case SET_USER_PROFILE:

            return {
                ...state,currentUser:action.payload
            };
        default:
          return state;
      }
}