import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dustbin from "../../assets/images/dustbin_icon.svg";
import { socket, socketSendRequest } from '../../shared/service';
import { SELECT_RECEIVER_DETAILS } from '../../store/action';
import Pagination from '../common/Pagination';
import Loader from '../Loader';
import DeleteMessageComponent from './DeleteMessage';
import Sweetalert from 'sweetalert2';

let date = "1970";
const MessageList = () => {
    const history = useHistory();
    const dispatch = useDispatch()
    const physicianMessage = useSelector(state => state.chatReducer.physicianMessage)
    const waitingLoader = useSelector(state => state.chatReducer.waitingLoader)
    const searchMessage = useSelector(state => state.chatReducer.searchMessage)
    const messageFilterData = useSelector(state => state.chatReducer.messageFilterData)
    const [physicianMessageList, setPhysicianMessageList] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false)
    const [chatId, setChatId] = useState(null)

    useEffect(() => {
        const req = {
            page: physicianMessage.page,
            limit: physicianMessage.limit
        }
        socketSendRequest("getPhysicianMsgList", req)
    }, [])

    useEffect(() => {
        const data = physicianMessage?.patients?.sort(
            (a, b) =>
                new Date(b?.lastMessage?.createdAt ? b?.lastMessage?.createdAt : date) -
                new Date(a?.lastMessage?.createdAt ? a?.lastMessage?.createdAt : date)
        );
        setPhysicianMessageList(data);
    }, [physicianMessage?.patients]);

    useEffect(() => {
        socket?.on("response", socketListener)
        return () => {
            socket?.off("response", socketListener)
        };
    }, [physicianMessage?.patients])

    const socketListener = (res) => {

        switch (res.evt) {
            case 'deleteChat':
                setDeleteModal(false)
                const req = {
                    page: physicianMessage.page,
                    limit: physicianMessage.limit
                }
                socketSendRequest("getPhysicianMsgList", req)
                Sweetalert.fire({ title: 'Success', text: res.data.message })
                break;
            case 'sendMsg':
                const reqMsg = {
                    page: physicianMessage.page,
                    limit: physicianMessage.limit
                }
                socketSendRequest("getPhysicianMsgList", reqMsg)
                break;
            default:
                break;
        }
    }
    const clickToChatWithSelectedPatient = (data) => {
        dispatch({ type: SELECT_RECEIVER_DETAILS, payload: data });
        localStorage.setItem("receiver", JSON.stringify(data))
        history.push('/chat');
    };

    const onPaginate = async (pageNumber) => {
        const req = {
            page: pageNumber,
            limit: physicianMessage.limit
        }
        socketSendRequest("getPhysicianMsgList", req)
    }

    const handleNext = async () => {
        const req = {
            page: physicianMessage.page + 1,
            limit: physicianMessage.limit
        }
        socketSendRequest("getPhysicianMsgList", req)
    }

    const handlePrevious = async () => {
        const req = {
            page: physicianMessage.page - 1,
            limit: physicianMessage.limit
        }
        socketSendRequest("getPhysicianMsgList", req)
    }

    const handleDeleteMessageModal = (modal, chId) => {
        setDeleteModal(modal)
        setChatId(chId)
    }

    const deleteMessage = () => {
        socketSendRequest("deleteChat", { chId: chatId })
    }

    return (
        <div className="table_wrapper">
            <div className="table_responsive">
                <table className="table clinic_data_list msg_table">
                    <thead>
                        <tr>
                            <td/>
                            <td>Patient Name</td>
                            <td>Message</td>
                            <td>Date/Time</td>
                            <td>Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (searchMessage !== '' && !messageFilterData) ?
                                <tr><td colSpan={5}><div className="mt_5 pb_5 pt_5 fullWidth text_center" >No Data Found</div></td></tr>
                                :
                                waitingLoader ?
                                    <tr>
                                        <td className='td_loader'>
                                            <div className='spin_loder'>
                                                <Loader className="data_load" />
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    physicianMessageList.length > 0 ?
                                        physicianMessageList.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className={item?.unRead === Number(0) ? ' ' : 'fill active_fill'}/>
                                                    <td className="cursor_pointer clinic-name text_capitalize_md" onClick={() => clickToChatWithSelectedPatient(item)}>{item?.users?.first_name + " " + item?.users?.last_name}</td>
                                                    <td className="cursor_pointer clinic-name message_text text_capitalize_md"><span onClick={() => clickToChatWithSelectedPatient(item)}>{item?.lastMessage?.message}</span></td>
                                                    <td>
                                                        {item?.createdAt
                                                            ? !moment(moment.utc(item?.lastMessage?.createdAt).local()).isSame(moment(), 'day') ? moment(item?.lastMessage?.createdAt).format(
                                                                "L"
                                                            ) :  moment(item?.lastMessage?.createdAt).format(
                                                                "LT"
                                                            )
                                                        : ""}
                                                    </td>
                                                    <td className="td_icons">
                                                        <div className="d_flex icons">
                                                            <span className="actions_icon" ><img src={dustbin} alt="alert_icon" onClick={() => handleDeleteMessageModal(!deleteModal, item.chId)} /></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr><td colSpan={5}><div className="mt_5 pb_5 pt_5 fullWidth text_center" >No Data Found</div></td></tr>
                        }
                    </tbody>
                </table>
            </div>
            {physicianMessage.totalResults > 10 &&
            <Pagination
                page={physicianMessage.page}
                limit={physicianMessage.limit}
                totalResults={physicianMessage.totalResults}
                paginate={onPaginate}
                next={handleNext}
                prev={handlePrevious}
            />
            }
            <DeleteMessageComponent deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteMessage={deleteMessage} />
        </div>
    );
}

export default MessageList;
