import React, { Fragment, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/new_hp_logo.svg";
import icone_hide_pass from "../../assets/images/eye_hide.svg";
import icon_open_password from "../../assets/images/eye_show.svg";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "../common/Footer";
import SimpleReactValidator from "simple-react-validator";
import { mainPostServices } from "../../shared/service";
import { SIGNUP_API_URL } from "../../shared/constant";
import Sweetalert from "sweetalert2";
import AuthenticatePop from "./AuthenticatePop";
import Loader from "../Loader";
import { useDispatch } from "react-redux";
import MobileViewSignup from "./SignupComponet/MobileViweSignup/inex";
import WebSignup from "./SignupComponet/webSignup";
let CryptoJS = require("crypto-js");

const Signup = () => {
    const { search } = useLocation();
    const history = useHistory()
    const dispatch = useDispatch()
    const simpleValidator = useRef(new SimpleReactValidator({
        validators: {
            password: {
                message: "Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit.",
                // eslint-disable-next-line
                rule: (val, params, validator) => validator.helpers.testRegex(val.trim(), /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/), required: true, // optional
            }
        },
    }))
    const [, updateState] = useState();
    const [btnLoading, setBtnLoading] = useState(false)
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [viewPassWord, setViewPassWord] = useState(false)
    const [ViewConPassWord, setViewConPassWord] = useState(false)
    const [authVerifyModal, setAuthVerifyModal] = useState(false);
    const [counterTime, setCounterTime] = useState(0)

    let decryptedData;
    let decrypted = CryptoJS.AES.decrypt(search?.substring(1)?.toString(), process.env.REACT_APP_SECRET_KEY);
    if (search) {
        decryptedData = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    } else {
        history.push("/")
    }
    const [registrationState, setRegistrationState] = useState({
        institution_registration_code: '',
        email: decryptedData?.email,
        password: '',
        confirmPassword: ""
    })

    const togglePassWordHideShow = (pwdShow) => {
        setViewPassWord(pwdShow)
    }
    const handleLoginInputChange = (e) => {
        const { name, value } = e.target
        setRegistrationState({
            ...registrationState,
            [name]: value
        })
    }

    const toggleConPassWordHideShow = (pwdShow) => {
        setViewConPassWord(pwdShow)
    }
    const signUpHandler = (e) => {
        e.preventDefault()
        if (simpleValidator.current.allValid()) {
            SignUpApiHandler(registrationState)
            setBtnLoading(true)
        } else {
            simpleValidator.current.showMessages();
            forceUpdate()
        }
    }

    const SignUpApiHandler = async (req) => {
        const { message, success, data } = await mainPostServices(SIGNUP_API_URL, req);

        if (success) {
            setCounterTime(30)
            setAuthVerifyModal(true)
            setBtnLoading(false)
        } else {
            Sweetalert.fire({
                title: 'Error',
                text: message
            })
            setBtnLoading(false)
        }
    }

    const clearFormData = () => {
        setRegistrationState({
            institution_registration_code: '',
            email: decryptedData?.email,
            password: '',
            confirmPassword: ""
        })
        simpleValidator.current.hideMessages();
        forceUpdate()
    }

    return (
        <Fragment>
            <Helmet>
                <title>{"Haploscope MD"}</title>
            </Helmet>
            {
                window.innerWidth > 768 ?
                    <WebSignup />
                    :
                    <MobileViewSignup />
            }

        </Fragment>
    )
}
export default Signup