import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_ALL_PATIENTS_URL, GET_PATIENTS_URL, UPLOAD_PATIENTS_CSV_URL } from '../../shared/constant';
import { mainPostServices } from '../../shared/service';
import { GET_PATIENTS, SET_FILTER_PATIENTS } from '../../store/action';
import fileUpload from '../../assets/images/file-upload-icon.svg';
import icon_download from '../../assets/images/icon_download.svg';
import Sweetalert from 'sweetalert2';
import Tooltip from "react-simple-tooltip"
import { Modal,ModalBody } from 'reactstrap';
import cross_icon from "../../assets/images/cross-icon.png";
const PatientsHeader = ({ handlePatientsModal }) => {

    let url;
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.userDetailsReducer.currentUser)
    const filters = useSelector(state => state.patientsReducer.filters)
    url = filters.status === 'all' ? GET_ALL_PATIENTS_URL : GET_PATIENTS_URL

    const [loading, setLoading] = useState(false)
    const [csvInfo, setCsvInfo] = useState({
        modal:false,
        message:'',
        note:''
    })

    const handleSearchName = async (e) => {
        dispatch({
            type: SET_FILTER_PATIENTS, payload: {
                search: e.target.value,
                status: filters.status
            }
        })
        if (filters.search) {
            const req = {
                name: e.target.value,
                page: 1,
                limit: 10
            }
            const { data, success } = await mainPostServices(url, req);
            if (success) {
                dispatch({ type: GET_PATIENTS, payload: data })
            }
        } else {
            const req = {
                name: filters.search,
                page: 1,
                limit: 10
            }
            const { data, success } = await mainPostServices(url, req);
            if (success) {
                dispatch({ type: GET_PATIENTS, payload: data })
            }
        }
    }

    const handleFilterStatus = async (e) => {
        dispatch({
            type: SET_FILTER_PATIENTS, payload: {
                search: '',
                status: e.target.checked ? "my" : "all"
            }
        })
        url = e.target.checked ? GET_PATIENTS_URL : GET_ALL_PATIENTS_URL
        const req = {
            name: '',
            page: 1,
            limit: 10
        }
        const { data, success } = await mainPostServices(url, req);
        if (success) {
            dispatch({ type: GET_PATIENTS, payload: data })
        }
    }

    const bulkFileUploader = async (e) => {
        const formData = new FormData()
        formData.append("file", e.target.files[0])
        setLoading(true)
        const { success, message, note } = await mainPostServices(UPLOAD_PATIENTS_CSV_URL, formData);
        if (success) {
            const req = {
                name: filters.search,
                page: 1,
                limit: 10
            }
            const { data, success } = await mainPostServices(url, req);
            if (success) {
                dispatch({ type: GET_PATIENTS, payload: data })
            }
            setLoading(false)
            Sweetalert.fire({ title: 'Success', text: "Upload Successfully" })
        } else {
            setLoading(false)
            setCsvInfo({
                ...csvInfo,
                modal:true,
                message:message,
                note:note
            })
        }
    }

    var data = '"first_name","last_name","email","phone","phone_type"';
    const downloadSampleCsv = () => {
        var hiddenElement = document.createElement("a");
        hiddenElement.href =
            "data:text/csv;charset=utf-8," + encodeURI(data);
        hiddenElement.target = "_blank";
        hiddenElement.download = 'sample.csv';
        hiddenElement.click();
    }

    return (
        <div className="heading_content">
            <div className='sub_header_row'>
                <form className="form_group">
                    <input type="text" placeholder="Search" className="form_control search_input" name="search" value={filters.search} onChange={(e) => handleSearchName(e)} />
                    <span className="search_icon">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHRSURBVHgBrVTLbcJAEJ01H3EkHTgd2BckxCGhhFQQOggdEDrAHUAFlAAXBDc7FYQSOCK+eW/ZtRbH4GDlSZbX6503b2ZnRkkO4jhu7na7vud5L+fzOcBWE89GKZXgnRwOh6jdbq/lBlSWbL/ff2L5IQWAs1G9Xh+GYbjJ/vPsYrlc+iCMHcIxntdarfbUarUU3/w2+wLVfUQT0y5XqVFIQh8K1gjvrdPpJDdEagGVSmVmz0Nx6CrWSk3I+sDpdOreIySYz+Px2MVyDcU+FA+ulBqv3/zAwed7F5DFYrEIkBZGKEyPVetVq9U0h48QEiaiMddIWXq5DD+wpFICiG7CN1L3mpKaOqT8LymBRqNh8x+4SlnYkldvf4Fj13RJ9SbLSkrAsbsqKS1/u90GUgK4IGuXlqGHOptzgbJ6lxLAnWg7MxcupCipyKx7rDt5AKZFe3JRHKWkTDS8jPgBB9O8Xr5FaFpVDxe3xj1DNhTTchh3syJiRmR736icuP9VjmffbI3hZAKHCaPhLfNSTA57LomdGVbtr3kKwwEO9aUADJkK0TRTcYYRiVWeAVVzJphuSyc/ngR7c4y6yBZ9dgySWMk/wCUGaWGUDxGvVis9qX4ArLAOUu34bGcAAAAASUVORK5CYII=" alt="Search_Icon" />
                    </span>

                </form>
                <div className={`nav_text switch_box sp_nav_comman ${filters.status === 'my' ? 'pending' : 'confirmed'}`}>
                    <p className="off">All Patients</p>
                    <label className="switch">
                        <input type="checkbox" defaultChecked={filters.status === 'my'} onChange={handleFilterStatus} />
                        <span className="slider" />
                    </label>
                    <p className="on">My Patients</p>
                </div>
            </div>
            <div className={`link_block psd_link_block psd_link_block-data ${currentUser?.clinic_name === undefined ? 'blank_block' : ''}`}>
                <p className="text_right" >{currentUser?.clinic_name}</p>
            </div>
            <div className="add_patient_block">
                <div className={`main_file_uploade ${loading ? 'file_loader' : ''}`}>
                    {
                        <img src={fileUpload} alt="" />
                    }

                    <input
                        type="file"
                        onChange={(e) => bulkFileUploader(e)}
                        onClick={(e) => {
                            e.target.value = "";
                        }}
                        accept={'.csv'} />
                </div>
                <button className="btn" onClick={() => handlePatientsModal(true)}>Add A Patient</button>
                <div className={'ml_05 patients_download'}>
                    <Tooltip content="csv file" placement="bottom">
                        <img src={icon_download} alt={'icon_download'} onClick={() => downloadSampleCsv()}/>
                    </Tooltip>
                </div>
                {/* </div> */}
            </div>

            <Modal isOpen={csvInfo?.modal} toggle={() => setCsvInfo({...csvInfo,modal:false})} className='deleteModal deleteModal_main  main_comman_modal'>
                <div className='close_modal' onClick={() => setCsvInfo({...csvInfo,modal:false})}>
                    <img src={cross_icon} alt='cross' />
                </div>
                <ModalBody>
                <div className="delete-pop">
                    <div className="title">
                        {"Info"}
                    </div>
                    <div className="desc">
                        {csvInfo?.message} <br/> {csvInfo?.note}
                    </div>
                    <div>
                        <button className="btn btn_primary  mt_2" onClick={() => setCsvInfo({...csvInfo,modal:false})}> Ok</button>       
                    </div>
                </div>
                </ModalBody>
            </Modal>
         
        </div>
    )
}

export default PatientsHeader;
