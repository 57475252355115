import { DELETE_PATIENT_STATE, EDIT_PATIENT_STATE, GET_PATIENTS, SET_FILTER_PATIENTS,UPDATE_PATIENT_STATUS,DELETE_PATIENT_DATA,UPDATE_PATIENTS_DATA } from "../action";
import {UPDATE_PATIENT_STATUS_URL} from "../../shared/constant";
import index from "./index";
const initState = {
    allPatients:{
        patientsList:[],
        limit: 10,
        page: 1,
        totalPages: 0,
        totalResults: 0 
    },
    filters:{
        search:'',
        status:'all'
    },
    editPatientState:null,
    patient_id:null
}

export default function patientsReducer(state=initState,action){
    switch (action.type) {
        case GET_PATIENTS:
            return {
                ...state,
                allPatients:{
                    patientsList:action.payload.docs,
                    limit:action.payload.perPage,
                    page:action.payload.currentPage,
                    totalPages:action.payload.totalPages,
                    totalResults:action.payload.total
                } 
        };

        case SET_FILTER_PATIENTS:
            return{
                ...state,
                filters:{
                    search:action.payload.search,
                    status:action.payload.status
                }
        }

        case EDIT_PATIENT_STATE:
            return{
                ...state,
                editPatientState:action.payload
        }

        case DELETE_PATIENT_STATE:
            return{
                ...state,
                patient_id:action.payload
        }
        case DELETE_PATIENT_DATA:{
            let result = state.allPatients.patientsList.filter(data => data._id !== action.payload.id)
            return {
                ...state,
                allPatients: {
                    patientsList: [...result]
                }
            }
        }

        case UPDATE_PATIENT_STATUS:{
            let temp = { ...state }
            let index = state.allPatients.patientsList.findIndex(data => data._id === action.payload.id)
            if (index > -1) {
                temp.allPatients.patientsList[index].user_type[0].isActive = action.payload.status
            }
            return temp
        }
        case UPDATE_PATIENTS_DATA:{
            let temp = { ...state }
            let index = state.allPatients.patientsList.findIndex(data => data._id === action.payload.id)
            if (index > -1) {
                temp.allPatients.patientsList[index] = { ...temp.allPatients.patientsList[index], ...action.payload.result }
            }
            return temp
        }
        default:
          return state;
      }
}