let url = process.env.REACT_APP_API_URL;

// let prodEnv;
// let domain = window.location.hostname;
// const liveApi = process.env.REACT_APP_PROD_URL;
// const devApi = process.env.REACT_APP_API_URL;
// if (domain === 'devmd.haploscope.com') prodEnv = "prod";
// const baseUrl = prodEnv === "prod" ? liveApi : devApi;
export const constant = {
    API_URL: url
}

export const LOGIN_API_URL = `${url}/physician/login`;
export const SIGNUP_API_URL = `${url}/physician/verify-registration-code`;
export const RESEND_OTP = `${url}/physician/resend-otp`;
export const VERIFY_OTP = `${url}/physician/verify-otp`;
export const LOGOUT_API_URL = `${url}/physician/logout`;

// Schedule
export const GET_SCHEDULE_URL = `${url}/physician/get-schedule`
export const GET_SCHEDULE_BYDATE_URL = `${url}/physician/get-schedule-by-date`;
export const GET_SCHEDULE_STATUS_URL = `${url}/physician/getCalenderSchedule`;
export const CREATE_APOPOINMENT_URL = `${url}/physician/create-schedule`;
export const UPDATE_APOPOINMENT_URL = `${url}/physician/update-schedule`;
export const DELETE_APOPOINMENT_URL = `${url}/physician/delete-schedule`;
export const CREATE_SCHEDULE_PATIENTS_URL = `${url}/physician/getAllPatients`;
export const GET_AVAILABLE_MIUTES_URL = `${url}/physician/getAvailableMinute`;
export const REQUEST_SCHEDULE_MINUTE_URL = `${url}/physician/requestScheduleMinute`;

// Patients

export const GET_PATIENTS_URL = `${url}/physician/getPatients`;
export const GET_ALL_PATIENTS_URL = `${url}/physician/getAllCmsPatients`;
export const RESEND_INVITE_PATIENTS_URL = `${url}/physician/resendInvitationPatient`;
export const ADD_PATIENT_URL = `${url}/physician/addPatient`;
export const UPLOAD_PATIENT_PROFILE_URL = `${url}/physician/profile-upload`;
export const EDIT_PATIENT_URL = `${url}/physician/editPatient`;
export const UPDATE_PATIENT_URL = `${url}/physician/updatePatient`;
export const DELETE_PATIENT_URL = `${url}/physician/deletePatient`;
export const UPDATE_PATIENT_STATUS_URL = `${url}/physician/updatePatientStatus`;
export const UPLOAD_PATIENTS_CSV_URL = `${url}/physician/uploadPatient`;
export const GET_PROFILE_DATA = `${url}/physician/get-profile-by-token`;
export const GET_CAREGIVERS_DATA = `${url}/physician/getCaregivers`;

// passowrd 
export const FORGET_PASSWORD_URL = `${url}/physician/request-password-reset`;
export const CHANGE_PASSWORD_URL = `${url}/physician/change-password`;
export const RESET_PASSWORD_URL = `${url}/physician/reset-password`;

//physician details
export const GET_PHYSICIAN_DETAILS = `${url}/physician/getPhysicianDetails`;

//physician details
export const CREATE_ROOM_TOKEN = `${url}/physician/createVideoToken`;
export const DISCONNECT_ROOM_URL = `${url}/physician/disconnectVideo`;




